import axios from "axios"
import { Autocomplete, Button, Grid, MenuItem, TextField } from "@mui/material"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { ProspectStudents, ViewFinderStudent } from "./FindersStudentsAssociation"
import { Add } from "@mui/icons-material"
import { toast } from "react-toastify"
import { toastError, toastSuccess } from "../assets/customToasts"
import { useParams } from "react-router-dom"
import FindersStudentsTable from "./FindersStudentsTable"
import { LookupTablesContext } from "../../context/LookupTablesContext"
import { AutocompleteOption } from "../../utilities/generalPurposeTypes"

type FinderStudentsPanelProps = {
  blockedIds: number[]
  refreshTable: () => Promise<void>
  onClose: () => void
}

type Filters = {
  admissionYear: 'all' | number
  affiliation: AutocompleteOption | null
  grade: 'all' | number
  name: string
  program: 'all' | number
  status: 'all' | number
}

export default function FinderStudentsPanel({ blockedIds, refreshTable, onClose }: FinderStudentsPanelProps) {
  const { id } = useParams()
  const { lookupTables } = useContext(LookupTablesContext)
  const [name, setName] = useState<string>('')
  const [filters, setFilters] = useState<Filters>({
    admissionYear: 'all',
    affiliation: { id: 1, label: 'WED - WorldEd School' },
    grade: 'all',
    name: '',
    program: 'all',
    status: 9
  })
  const [students, setStudents] = useState<ViewFinderStudent[]>([])
  const [selectedStudents, setSelectedStudents] = useState<number[]>([])
  const [disabledButton, setDisabledButton] = useState<boolean>(false)

  const affiliationOptions = useMemo<AutocompleteOption[]>(() => lookupTables.affiliation.map(aff => ({ label: `${aff.tag} - ${aff.option}`, id: aff.id })), [lookupTables])

  const nameRegExp = useMemo(() => new RegExp(name, 'i'), [name])

  const filterByName = (row: ProspectStudents) => {
    return nameRegExp.test(row.student_full_name)
  }

  const getStudents = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/students?affiliation=${!filters.affiliation ? '' : filters.affiliation.id}&grade=${filters.grade === 'all' ? '' : filters.grade}&name=${filters.name}&program=${filters.program === 'all' ? '' : filters.program}&status=${filters.status === 'all' ? '' : filters.status}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setStudents(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not load students.')
    }
  }, [filters])

  useEffect(() => {
    // Debounce for the fetch
    const timeout = setTimeout(() => getStudents(), 500)

    return () => clearTimeout(timeout)
  }, [getStudents])

  const associateStudents = useCallback(async () => {
    setDisabledButton(true)
    const _toast = toast('Saving associations', { toastId: 'toast-add-students', isLoading: true })

    try {
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/finder-student`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: {
          fk_id_affiliation: id,
          students: selectedStudents
        }
      })

      onClose()
      refreshTable()
      toastSuccess(_toast, 'Students associated with finder.')
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not associate students')
    }

    setDisabledButton(false)
  }, [selectedStudents])

  return <Grid container spacing={1}>
    <Grid item xs={12}>
      <TextField
        label='Student Name'
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </Grid>
    {/* Filters */}
    <Grid item xs={6}>
      <Autocomplete
        id='search-filter-affiliation'
        renderInput={props => <TextField {...props} label='Affiliation' />}
        options={affiliationOptions}
        value={filters.affiliation}
        onChange={(e, newValue) => setFilters(_filters => ({ ..._filters, affiliation: newValue }))}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        id='search-filter-grade'
        label='Grade'
        value={filters.grade}
        onChange={(e) => setFilters((_filters) => ({ ..._filters, grade: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        select
      >
        <MenuItem id='grade-option-all' value='all'>All</MenuItem>
        {
          lookupTables.grade.map((grade, index) => <MenuItem key={index} id={`grade-option-${grade.id}`} value={grade.id}>{grade.option}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={3}>
      <TextField
        id='search-filter-status'
        label='Status'
        value={filters.status}
        onChange={(e) => setFilters((_filters) => ({ ..._filters, status: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        select
      >
        <MenuItem id='status-option-all' value='all'>All</MenuItem>
        {
          lookupTables.studentStatusType.map((status, index) => <MenuItem key={index} id={`status-option-${status.id}`} value={status.id}>{status.option}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={12}>
      <FindersStudentsTable
        blockedIds={blockedIds}
        hasCheckbox={true}
        selectedStudents={selectedStudents}
        setSelectedStudents={setSelectedStudents}
        students={students.filter(filterByName)}
      />
    </Grid>
    <Grid item xs={9}></Grid>
    <Grid item xs={3}>
      <Button
        id='button-associate'
        startIcon={<Add />}
        disabled={!selectedStudents.length || disabledButton}
        onClick={() => associateStudents()}
      >
        Associate
      </Button>
    </Grid>


  </Grid>
}
