import { Checkbox, IconButton, Tooltip } from "@mui/material"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"
import { DeleteForever, Info } from "@mui/icons-material"
import { ViewFinderStudent } from "./FindersStudentsAssociation"
import { useMemo } from "react"

type Props = {
  blockedIds?: number[]
  disableButton?: boolean
  hasCheckbox?: boolean
  onDelete?: (id: number) => Promise<void>
  selectedStudents: number[]
  setSelectedStudents: React.Dispatch<React.SetStateAction<number[]>>
  students: ViewFinderStudent[]
}

export default function FindersStudentsTable({ blockedIds, disableButton, hasCheckbox, onDelete, selectedStudents, setSelectedStudents, students }: Props) {
  const columnWidth = [0.4, 0.5, 4, 1, 1.5]

  const availableStudents = useMemo<number>(() => students.length - (blockedIds?.length || 0), [blockedIds, students])

  return <Table>
    <HeaderRow>
      {
        hasCheckbox ?
          <Cell width={columnWidth[0]}>
            <Checkbox
              id='checkbox-select-all'
              value={selectedStudents.length === availableStudents && availableStudents > 0}
              onChange={(e) => e.target.checked ? setSelectedStudents(students.filter((_) => !blockedIds?.includes(_.id)).map((_) => _.id)) : setSelectedStudents([])}
              disabled={availableStudents === 0}
            />
          </Cell>
          :
          null
      }
      <Cell width={columnWidth[1]}>
        <p>
          ID
        </p>
      </Cell>
      <Cell flexGrow>
        <p>
          Name
        </p>
      </Cell>
      <Cell width={columnWidth[3]}>
        <p>
          Grade
        </p>
      </Cell>
      <Cell width={columnWidth[4]}>
        <p>
          Affiliation
        </p>
      </Cell>
      {
        onDelete ?
          <Cell width={.6}>
            <p></p>
          </Cell>
          :
          null
      }
    </HeaderRow>
    <BodyContainer>
      {
        students.length ?
          students.map((row, index) => <BodyRow key={index}>
            {
              hasCheckbox ?
                <Cell width={columnWidth[0]}>
                  {
                    blockedIds?.includes(row.id) ?
                      <Tooltip title='Student is already associated with a Finder'>
                        <Info />
                      </Tooltip>
                      :
                      <Checkbox
                        id={`checkbox-student-${row.id}`}
                        checked={selectedStudents.includes(row.id)}
                        onChange={(e) => setSelectedStudents(selected => e.target.checked ? selected.concat(row.id) : selected.filter(_ => _ !== row.id))}
                      />
                  }
                </Cell>
                :
                null
            }
            <Cell width={columnWidth[1]}>
              <p>
                {row.id}
              </p>
            </Cell>
            <Cell flexGrow>
              <p>
                {row.student_full_name}
              </p>
            </Cell>
            <Cell width={columnWidth[3]}>
              <p>
                {row.grade_name}
              </p>
            </Cell>
            <Cell width={columnWidth[4]}>
              <p>
                {row.affiliation_name}
              </p>
            </Cell>
            {
              onDelete ?
                <Cell width={.6}>
                  <IconButton
                    id={`button-delete-${row.id}`}
                    onClick={() => onDelete(row.id)}
                    disabled={disableButton}
                  >
                    <DeleteForever color='error' />
                  </IconButton>
                </Cell>
                :
                null
            }
          </BodyRow>
          )
          :
          <BodyRow>
            <Cell flexGrow>
              <p>There is no prospect students available to associate.</p>
            </Cell>
          </BodyRow>
      }
    </BodyContainer>
  </Table>
}