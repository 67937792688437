import { useIsAuthenticated, useMsal } from "@azure/msal-react"
import { loginRequest } from "../auth/authConfigMSAL"
import { IPublicClientApplication } from "@azure/msal-browser"

import { Button, Container, Grid, Typography } from "@mui/material"
import { useState, useEffect, useMemo } from 'react'

import './Home.css'
import MissingAssignmentsOverview from "./dashboard/MissingAssignmentsOverview"
import { RequestOverview } from "./dashboard/RequestOverview"
import { RightSidePanel } from "wed-components"
import EndingSectionsEnrollments from "./dashboard/EndingSectionsEnrollments"
import DashboardForm from "./dashboard/DashboardForm"
import Header from "./header/Header"

export type DashboardTables = {
  dash1: number,
  dash2: number,
  dash3: number
}

export default function Home() {
  // General
  const releaseDate = '14/11/2024'
  const [settings, setSettings] = useState<boolean>(false)
  // Hooks
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  // Data
  const [dashboardTables, setDashboardTables] = useState<DashboardTables>(() => {
    const _ = localStorage.getItem('_dashboardTables')

    if (_) return JSON.parse(_)

    return {
      dash1: 0,
      dash2: 0,
      dash3: 0
    }
  })

  const gridWidth = useMemo<number>(() => {
    const numberOfTables = Object.values(dashboardTables).reduce((prev, curr) => {
      if (curr !== 0) prev += 1

      return prev
    }, 0)

    if (numberOfTables <= 2)
      return 6

    return 4
  }, [dashboardTables])

  const handleLogin = (instance: IPublicClientApplication) => {
    instance.loginPopup(loginRequest).then((_) => console.log(_)).catch(err => console.error(err))
  }

  const handleLogout = (instance: IPublicClientApplication) => {
    instance.logoutPopup().catch(err => console.error(err))
  }

  useEffect(() => {
    localStorage.setItem('_dashboardTables', JSON.stringify(dashboardTables))
  }, [dashboardTables])

  const renderDashs = (item: any) => {
    switch (item) {
      case 1:
        return <Grid item xs={gridWidth} maxHeight={'80vh'}>
          <MissingAssignmentsOverview />
        </Grid>
      case 2:
        return <Grid item xs={gridWidth} maxHeight={'80vh'}>
          <RequestOverview />
        </Grid>
      case 3:
        return <Grid item xs={gridWidth} maxHeight={'80vh'}>
          <EndingSectionsEnrollments />
        </Grid>
      default:
        return <></>
    }
  }

  return (
    <Container sx={{ margin: '0px' }}>
      {
        settings ?
          <RightSidePanel state={settings} close={() => setSettings(false)} title={'Setting your dashboard overview'} theme={'dark'} maxWidth={'lg'}>
            <DashboardForm
              data={dashboardTables}
              setData={setDashboardTables}
            />
          </RightSidePanel>
          :
          <></>
      }
      <br />
      {
        isAuthenticated ?
          <Grid container maxWidth='xl' spacing={1}>
            <Header title={'Dashboard'} setSettings={setSettings} handleLogout={handleLogout} />
            <Grid item xs={11}>
              <Typography fontSize={24}>Hello, {accounts[0]?.name}</Typography>
              <Typography fontSize={12} color={'#ccc'}>{accounts[0]?.username} | Release date: {releaseDate}</Typography>
            </Grid>

            {renderDashs(dashboardTables.dash1)}
            {renderDashs(dashboardTables.dash2)}
            {renderDashs(dashboardTables.dash3)}

          </Grid>
          :
          <Grid container maxWidth='xl' spacing={1}>
            <Grid item xs={1}>
              <Button id='button-login' variant='contained' onClick={() => handleLogin(instance)}>Login</Button>
              <br />
              <br />
            </Grid>
            <Grid item xs={11} />
            <Grid item xs={4}>
              <Typography color={'#ccc'}>Release date: {releaseDate}</Typography>
            </Grid>
          </Grid>
      }
    </Container>
  )
}