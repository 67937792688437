import { Autocomplete, Button, Grid, InputAdornment, MenuItem, Paper, TextField, Tooltip, Typography } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { LookupTablesContext } from "../../../context/LookupTablesContext"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { DeleteForever, Info, NoteAdd } from "@mui/icons-material"
import { allowFloat, allowMoneyFormat, allowNumbers, formatNumberToMoney, roundTwoDecimals } from "../../Utils"
import { Billing, BillingsWithCashFlow, CashFlowOptions, Discount } from "./CashFlow"
import axios from "axios"
import { toast } from "react-toastify"
import { ViewDefaultBilling } from "../_billings/DefaultBillings"
import { DateTime } from "luxon"
import { ViewCustomer } from "./types"

type Props = {
  currencySign: string
  customerIds: number[]
  hasPresetBundle: boolean
  hasPresetCustomers: boolean
  selectedForm: BillingsWithCashFlow
  clearCashFlow: () => void
  setCashFlow: (netValue: number, selectedDefaultBilling: ViewDefaultBilling | null) => void
  updateBilling: (newBilling: Billing) => void
  updateCashFlowOptions: (newCashFlowOptions: CashFlowOptions) => void
  updateDiscount: (newDiscount: Discount) => void
}

export default function CashflowForm({ currencySign, customerIds, hasPresetBundle, hasPresetCustomers, selectedForm, clearCashFlow, setCashFlow, updateBilling, updateCashFlowOptions, updateDiscount }: Props) {
  console.log(selectedForm)
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // Data
  const [defaultBillings, setDefaultBillings] = useState<ViewDefaultBilling[]>([])
  const [customers, setCustomers] = useState<ViewCustomer[]>([])
  // useMemos
  // Options
  const affiliationOptions = useMemo(() => lookupTables.affiliation.map((_) => ({ id: _.id, label: `${_.tag} - ${_.option}` })), [lookupTables])
  const defaultBillingOptions = useMemo(() => defaultBillings.filter((_) => _.fk_id_affiliation === selectedForm.billing.affiliation?.id).map((_) => ({ id: _.id, label: _.default_billing_name })), [defaultBillings, selectedForm.billing])
  const beneficiaryOptions = useMemo(() => customers.filter((_) => _.fk_id_customer_type === selectedForm.billing.beneficiaryType).map((_) => ({ id: _.id, label: _.customer_name, fk_id_customer_dependence: _.fk_id_customer_dependence })), [customers, selectedForm.billing])
  const payerOptions = useMemo(() => customers.filter((_) => _.fk_id_customer_type === selectedForm.billing.payerType).map((_) => ({ id: _.id, label: _.customer_name, fk_id_customer_dependence: _.fk_id_customer_dependence })), [customers, selectedForm.billing])
  const serviceOptions = useMemo(() => lookupTables.service.map((_) => ({ id: _.id, label: _.option })), [lookupTables])
  // Errors
  const totalBillingError = useMemo(() => isNaN(selectedForm.billing.totalBilling.value), [selectedForm.billing])
  const discountError = useMemo(() => selectedForm.discount.type === 'percentage' ? isNaN(selectedForm.discount.percentage.value) : isNaN(selectedForm.discount.setValue.value), [selectedForm.discount])
  const cashFlowError = useMemo(() => selectedForm.cashFlowOptions.mode === 'numberOfBills' ? (isNaN(selectedForm.cashFlowOptions.numberOfBills.value) || selectedForm.cashFlowOptions.numberOfBills.value > 48) : (isNaN(selectedForm.cashFlowOptions.setValue.value) && selectedForm.cashFlowOptions.dateOfFirstCashflow !== null), [selectedForm.cashFlowOptions])
  const disableSetCashFlowButton = useMemo(() => totalBillingError || discountError || cashFlowError, [totalBillingError, discountError, cashFlowError])
  // Others
  const selectedDefaultBilling = useMemo(() => defaultBillings.find((_) => _.id === selectedForm.billing.defaultBilling?.id) || null, [defaultBillings, selectedForm.billing])
  const netValue = useMemo(() => {
    // If discount is set to percentage, and there's an input for percentage
    if (selectedForm.discount.type === 'percentage' && !isNaN(selectedForm.discount.percentage.value) && selectedForm.billing.totalBilling.value && selectedForm.discount.percentage.value >= 0 && selectedForm.discount.percentage.value <= 100) {
      const _newTotalBilling = selectedForm.billing.totalBilling.value * (1 - selectedForm.discount.percentage.value / 100)

      return roundTwoDecimals(_newTotalBilling) // Truncating to two decimal places
    }
    // If discount is set to setValue, and there's a set value
    if (selectedForm.discount.type === 'setValue' && !isNaN(selectedForm.discount.setValue.value) && selectedForm.billing.totalBilling.value && selectedForm.discount.setValue.value > 0 && selectedForm.discount.setValue.value <= selectedForm.billing.totalBilling.value) {
      const _newTotalBilling = selectedForm.billing.totalBilling.value - selectedForm.discount.setValue.value

      return roundTwoDecimals(_newTotalBilling) // Truncating to two decimal places
    }

    return NaN
  }, [selectedForm.billing, selectedForm.discount])

  const getCustomers = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/customers?fk_id_customer_type[]=1&fk_id_customer_type[]=2`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setCustomers(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch customers.')
    }
  }, [])

  useEffect(() => {
    getCustomers()
  }, [getCustomers])

  const getDefaultBillings = useCallback(async (fk_id_affiliation: number | undefined) => {
    if (!fk_id_affiliation) return

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/default-billings?fk_id_affiliation=${fk_id_affiliation}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setDefaultBillings(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch customers.')
    }
  }, [setDefaultBillings])

  useEffect(() => {
    getDefaultBillings(selectedForm.billing.affiliation?.id)
  }, [getDefaultBillings, selectedForm.billing])

  // Default Billing
  useEffect(() => {
    if (selectedDefaultBilling) updateCashFlowOptions({
      ...selectedForm.cashFlowOptions,
      numberOfBills: {
        value: selectedDefaultBilling.max_installments,
        label: selectedDefaultBilling.max_installments.toString()
      }
    })
  }, [selectedDefaultBilling, updateCashFlowOptions])

  // Bundle defaults
  useEffect(() => {
    if (!hasPresetBundle || !customers.length) return

    const _customer = customers.find((_) => _.fk_id_customer_dependence === selectedForm.billing.affiliation?.id && _.fk_id_customer_type === 1)

    if (!_customer) return

    if (selectedForm.billing.beneficiaryType === 1 && selectedForm.billing.beneficiary === null) {
      updateBilling({
        ...selectedForm.billing,
        beneficiary: {
          id: _customer.id,
          label: _customer.customer_name
        }
      })
    }

    if (selectedForm.billing.payerType === 1 && selectedForm.billing.beneficiary === null) {
      updateBilling({
        ...selectedForm.billing,
        payer: {
          id: _customer.id,
          label: _customer.customer_name
        }
      })
    }
  }, [customers, updateBilling, hasPresetBundle, selectedForm])

  return <Paper sx={{ backgroundColor: '#0006' }}>
    <Grid container spacing={.75}>
      <Grid item xs={12}>
        <Typography>
          Billing
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          id='form-field-affiliation'
          options={affiliationOptions}
          value={selectedForm.billing.affiliation}
          onChange={(e, newValue) => {
            const _newBilling = {
              ...selectedForm.billing,
              affiliation: newValue
            }

            if (!newValue && !Boolean(selectedForm.billing.id)) _newBilling.beneficiary = null

            updateBilling(_newBilling)
            getDefaultBillings(newValue?.id)
          }}
          renderInput={params => <TextField {...params} label='Affiliation' />}
          disabled={Boolean(selectedForm.billing.id)}
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          id='form-field-default-billing'
          options={defaultBillingOptions}
          value={selectedForm.billing.defaultBilling}
          onChange={(e, newValue) => {
            const _newBilling = {
              ...selectedForm.billing,
              defaultBilling: newValue
            }

            // Filling other information of this billing
            const _defaultBilling = defaultBillings.find((_) => _.id === newValue?.id)
            const _payer = _defaultBilling?.fk_id_customer_type === 1 ? customers.find((_) => _.fk_id_customer_dependence === _defaultBilling?.fk_id_affiliation && _.fk_id_customer_type === 1) : null
            const _service = _defaultBilling ? serviceOptions.find((_) => _.id === _defaultBilling?.fk_id_service) : null

            console.log(newValue, defaultBillings, _defaultBilling, _payer, _service)

            if (_defaultBilling) {
              _newBilling.totalBilling.label = _defaultBilling.default_value.toFixed(2)
              _newBilling.totalBilling.value = _defaultBilling.default_value
              _newBilling.service = _service || null
              _newBilling.currency = _defaultBilling.fk_id_currency
              _newBilling.payerType = _defaultBilling.fk_id_customer_type
              _newBilling.payer = _payer ? { id: _payer.id, label: _payer.customer_name } : null
              _newBilling.payee = _defaultBilling.fk_id_payee
            }

            updateBilling(_newBilling)
          }}
          renderInput={params => <TextField {...params} label='Default Billing' />}
          getOptionKey={(option) => option.id}
          disabled={Boolean(selectedForm.billing.id)}
        />
      </Grid>
      <Grid item xs={5}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <DatePicker
            format="dd-MMM-yy"
            label='Issue Date'
            value={selectedForm.billing.issueDate}
            onChange={(newValue) => updateBilling({ ...selectedForm.billing, issueDate: newValue })}
            disabled={Boolean(selectedForm.billing.id)}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={7}>
        <Autocomplete
          id='form-field-service'
          options={serviceOptions}
          value={selectedForm.billing.service}
          onChange={(e, newValue) => updateBilling({ ...selectedForm.billing, service: newValue })}
          renderInput={params => <TextField {...params} label='Service' />}
          disabled={Boolean(selectedForm.billing.id)}
        />
      </Grid>
      {/* Beneficiary */}
      <Grid item xs={5}>
        <TextField
          id='form-field-beneficiary-type'
          label='Beneficiary type'
          value={selectedForm.billing.beneficiaryType || ''}
          onChange={(e) => {
            const _newBilling = {
              ...selectedForm.billing,
              beneficiaryType: Number(e.target.value)
            }

            const _beneficiary = _newBilling.beneficiaryType === 1 ? customers.find((_) => _.fk_id_customer_dependence === selectedForm.billing.affiliation?.id && _.fk_id_customer_type === 1) : null

            _newBilling.beneficiary = _beneficiary ? { id: _beneficiary.id, label: _beneficiary.customer_name } : null

            updateBilling(_newBilling)
          }}
          disabled={Boolean(selectedForm.billing.id)}
          select
        >
          {
            lookupTables.customerType
              .filter((type) => [1, 2].includes(type.id)) // Affiliation and Student
              .map((type, index) => <MenuItem key={index} id={`beneficiary-type-option-${type.id}`} value={type.id}>{type.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={7}>
        {
          hasPresetCustomers && selectedForm.billing.beneficiaryType === 2 ?
            <Paper sx={{ padding: '0 10px', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography>
                From selected list.
              </Typography>
              <Tooltip title={customers.filter((_) => customerIds.includes(_.id)).map((_) => <p>{_.customer_name}</p>)}>
                <Info />
              </Tooltip>
            </Paper>
            :
            <Autocomplete
              id='form-field-beneficiary'
              options={beneficiaryOptions}
              value={selectedForm.billing.beneficiary}
              onChange={(e, newValue) => updateBilling({ ...selectedForm.billing, beneficiary: newValue })}
              renderInput={params => <TextField {...params} label='Beneficiary' />}
              disabled={!selectedForm.billing.affiliation || Boolean(selectedForm.billing.id)}
            />
        }
      </Grid>
      {/* Payer */}
      <Grid item xs={5}>
        <TextField
          id='form-field-payer-type'
          label='Payer type'
          value={selectedForm.billing.payerType || ''}
          onChange={(e) => {
            const _newBilling = {
              ...selectedForm.billing,
              payerType: Number(e.target.value)
            }

            // Selecting the payer in case it's the affiliation
            const _payer = Number(e.target.value) === 1 ? customers.find((_) => _.fk_id_customer_dependence === _newBilling.affiliation?.id && _.fk_id_customer_type === 1) : null

            _newBilling.payer = _payer ? { id: _payer.id, label: _payer.customer_affiliation_name } : null

            updateBilling(_newBilling)
          }}
          disabled={Boolean(selectedForm.billing.id)}
          select
        >
          {
            lookupTables.customerType
              .filter((type) => [1, 2].includes(type.id)) // Affiliation and Student
              .map((type, index) => <MenuItem key={index} id={`payer-type-option-${type.id}`} value={type.id}>{type.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={7}>
        {
          hasPresetCustomers && selectedForm.billing.payerType === 2 ?
            <Paper sx={{ padding: '0 10px', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography>
                From selected list.
              </Typography>
              <Tooltip title={customers.filter((_) => customerIds.includes(_.id)).map((_) => <p>{_.customer_name}</p>)}>
                <Info />
              </Tooltip>
            </Paper>
            :
            <Autocomplete
              id='form-field-payer'
              options={payerOptions}
              value={selectedForm.billing.payer}
              onChange={(e, newValue) => updateBilling({ ...selectedForm.billing, payer: newValue })}
              renderInput={params => <TextField {...params} label='Payer' />}
              disabled={!selectedForm.billing.affiliation || Boolean(selectedForm.billing.id)}
            />
        }
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='form-field-payee'
          label='Payee'
          value={selectedForm.billing.payee || ''}
          onChange={(e) => updateBilling({ ...selectedForm.billing, payee: Number(e.target.value) })}
          disabled={Boolean(selectedForm.billing.id)}
          select
        >
          {
            lookupTables.company.map((_, index) => <MenuItem key={index} id={`payee-option-${_.id}`} value={_.id}>{_.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='form-field-reference-year'
          label='Reference year'
          value={selectedForm.billing.referenceYear || ''}
          onChange={(e) => updateBilling({ ...selectedForm.billing, referenceYear: Number(e.target.value) })}
          disabled={Boolean(selectedForm.billing.id)}
          select
        >
          {
            [-1, 0, 1].map((_, index) => <MenuItem key={index} value={DateTime.utc().get('year') + _}>{DateTime.utc().get('year') + _}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={3}>
        <TextField
          id='form-field-currency'
          label='Currency'
          value={selectedForm.billing.currency || ''}
          onChange={(e) => updateBilling({ ...selectedForm.billing, currency: Number(e.target.value) })}
          disabled={Boolean(selectedForm.billing.id)}
          select
        >
          {
            lookupTables.currency.map((_, index) => <MenuItem key={index} id={`currency-option-${_.id}`} value={_.id}>{_.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      <Grid item xs={9}>
        <TextField
          id='form-field-total-billing'
          label='Total Billing'
          value={selectedForm.billing.totalBilling.label}
          onChange={(e) => updateBilling({
            ...selectedForm.billing,
            totalBilling: {
              value: allowMoneyFormat(e.target.value.trim()),
              label: e.target.value.trim()
            }
          })}
          error={totalBillingError}
          onBlur={() => updateBilling({
            ...selectedForm.billing,
            totalBilling: {
              ...selectedForm.billing.totalBilling,
              label: formatNumberToMoney(selectedForm.billing.totalBilling.value)
            }
          })}
          onFocus={() => updateBilling({
            ...selectedForm.billing,
            totalBilling: {
              ...selectedForm.billing.totalBilling,
              label: selectedForm.billing.totalBilling.value.toFixed(2)
            }
          })}
          InputProps={{
            startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment>,
            sx: netValue !== selectedForm.billing.totalBilling.value && !isNaN(netValue) ? { textDecoration: 'line-through', color: '#888' } : {}
          }}
          disabled={Boolean(selectedForm.billing.defaultBilling) || Boolean(selectedForm.billing.id)}
        />
      </Grid>
      {/* Discount */}
      <Grid item xs={12}>
        <Typography>
          Discount
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='form-field-discount-type'
          label='Discount type'
          value={selectedForm.discount.type}
          onChange={(e) => updateDiscount({ ...selectedForm.discount, type: e.target.value as 'percentage' | 'setValue' })}
          select
        >
          <MenuItem id={'discount-type-percentage'} value='percentage'>% (Percentage)</MenuItem>
          <MenuItem id={'discount-type-setValue'} value='setValue'>{currencySign} (Set value)</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id='form-field-discount'
          label='Discount'
          value={selectedForm.discount[selectedForm.discount.type].label}
          onChange={(e) => updateDiscount({
            ...selectedForm.discount,
            percentage: {
              value: selectedForm.discount.type === 'percentage' ? allowFloat(e.target.value.trim()) : allowMoneyFormat(e.target.value.trim()),
              label: e.target.value.trim()
            }
          })}
          error={discountError}
          onBlur={() => {
            if (selectedForm.discount.type === 'setValue' && !isNaN(selectedForm.discount.setValue.value)) {
              updateDiscount({
                ...selectedForm.discount,
                setValue: {
                  ...selectedForm.discount.setValue,
                  label: formatNumberToMoney(selectedForm.discount.setValue.value)
                }
              })
            }
          }}
          onFocus={() => {
            if (selectedForm.discount.type === 'setValue' && selectedForm.discount.setValue.value) {
              updateDiscount({
                ...selectedForm.discount,
                setValue: {
                  ...selectedForm.discount.setValue,
                  label: selectedForm.discount.setValue.value.toFixed(2)
                }
              })
            }
          }}
          InputProps={
            selectedForm.discount.type === 'percentage' ?
              { endAdornment: <InputAdornment position='start'>%</InputAdornment> } // position="start" looks better
              :
              { startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment> }
          }
          disabled={Boolean(selectedForm.billing.id)}
        />
      </Grid>
      <Grid item xs={12}>
        {
          netValue !== selectedForm.billing.totalBilling.value && !isNaN(netValue) ?
            <Typography textAlign={'right'}>
              Net value: <b>{currencySign} {netValue}</b> (w/ discount)
            </Typography>
            :
            <></>
        }
      </Grid>
      {/* Cashflow */}
      {
        Boolean(selectedForm.billing.id) ?
          null
          :
          <>
            <Grid item xs={12}>
              <Typography>
                Cash Flow
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='form-field-cash-flow-mode'

                label='Mode'
                value={selectedForm.cashFlowOptions.mode}
                onChange={(e) => updateCashFlowOptions({
                  ...selectedForm.cashFlowOptions,
                  mode: e.target.value as 'numberOfBills' | 'setValue'
                })}
                select
              >
                <MenuItem id='cashflow-mode-number-of-bills' value='numberOfBills'>Number of bills</MenuItem>
                <MenuItem id='cashflow-mode-set-value' value='setValue'>Set value per bill</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                id='form-field-cash-flow-nob-value'

                label={selectedForm.cashFlowOptions.mode === 'numberOfBills' ? 'Number of Bills' : 'Value'}
                value={selectedForm.cashFlowOptions[selectedForm.cashFlowOptions.mode].label}
                onChange={(e) => updateCashFlowOptions({
                  ...selectedForm.cashFlowOptions,
                  numberOfBills: {
                    value: selectedForm.cashFlowOptions.mode === 'numberOfBills' ? allowNumbers(e.target.value.trim()) : allowMoneyFormat(e.target.value.trim()),
                    label: e.target.value.trim()
                  }
                })}
                error={cashFlowError}
                onBlur={() => {
                  if (selectedForm.cashFlowOptions.mode === 'setValue' && !isNaN(selectedForm.cashFlowOptions.setValue.value)) {
                    updateCashFlowOptions({
                      ...selectedForm.cashFlowOptions,
                      setValue: {
                        ...selectedForm.cashFlowOptions.setValue,
                        label: formatNumberToMoney(selectedForm.cashFlowOptions.setValue.value)
                      }
                    })
                  }
                }}
                onFocus={() => {
                  if (selectedForm.cashFlowOptions.mode === 'setValue' && selectedForm.cashFlowOptions.setValue.value) {
                    updateCashFlowOptions({
                      ...selectedForm.cashFlowOptions,
                      setValue: {
                        ...selectedForm.cashFlowOptions.setValue,
                        label: selectedForm.cashFlowOptions.setValue.value.toFixed(2)
                      }
                    })
                  }
                }}
                InputProps={
                  selectedForm.cashFlowOptions.mode === 'numberOfBills' ? {} : { startAdornment: <InputAdornment position='start'>{currencySign}</InputAdornment> }
                }
              />
            </Grid>
            {
              selectedDefaultBilling ?
                <Grid item xs={12}>
                  <Typography textAlign={'right'}>
                    {
                      selectedForm.cashFlowOptions.mode === 'numberOfBills' ?
                        <>
                          <b>Per bill:</b> {currencySign} {roundTwoDecimals(netValue / selectedForm.cashFlowOptions.numberOfBills.value)}
                        </>
                        :
                        <>
                          <b>Total:</b> {currencySign} {selectedForm.cashFlowOptions.setValue.value * selectedDefaultBilling?.max_installments}, <b>Installments:</b> {selectedDefaultBilling?.max_installments} (default)
                        </>
                    }
                  </Typography>
                </Grid>
                :
                <></>
            }
            <Grid item xs={5}>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  format="dd-MMM-yy"
                  label="First bill's due date"
                  value={selectedForm.cashFlowOptions.dateOfFirstCashflow}
                  onChange={(newValue) => updateCashFlowOptions({
                    ...selectedForm.cashFlowOptions,
                    dateOfFirstCashflow: newValue
                  })}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={7}>
              <TextField
                id='form-field-cash-flow-remainder-options'

                label='Remainder options'
                value={selectedForm.cashFlowOptions.remainder}
                onChange={(e) => updateCashFlowOptions({
                  ...selectedForm.cashFlowOptions,
                  remainder: e.target.value as 'first-bill' | 'last-bill' | 'equal'
                })}
                select
              >
                <MenuItem id='remainder-option-first-bill' value={'first-bill'}>Add to first bill</MenuItem>
                <MenuItem id='remainder-option-last-bill' value={'last-bill'}>Add to last bill</MenuItem>
                <MenuItem id='remainder-option-equal' value={'equal'}>Distribute equally</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                id='button-set-cash-flow'
                color={selectedForm.cashFlowInstallments.length ? 'error' : 'primary'}
                onClick={selectedForm.cashFlowInstallments.length ? clearCashFlow : () => setCashFlow(netValue, selectedDefaultBilling!)}
                startIcon={selectedForm.cashFlowInstallments.length ? <DeleteForever /> : <NoteAdd />}
                disabled={disableSetCashFlowButton}
              >
                {selectedForm.cashFlowInstallments.length ? 'Clear Cash Flow' : 'Set Cash Flow'}
              </Button>
            </Grid>
          </>
      }
    </Grid>
  </Paper >
}
