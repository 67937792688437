import axios from "axios"
import { Button, Grid, TextField } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Add, RemoveCircle } from '@mui/icons-material'
import { toast } from "react-toastify"
import { toastError, toastSuccess } from "../assets/customToasts"
import { useParams } from "react-router-dom"
import { RightSidePanel } from "wed-components"
import FinderStudentsPanel from "./FindersStudentsPanel"
import PreLoader from "../PreLoader"
import FindersStudentsTable from "./FindersStudentsTable"

export type ViewFinderStudent = {
  id: number
  fk_id_student: number
  student_full_name: string
  fk_id_affiliation: number
  affiliation_name: string
  fk_id_grade: number
  grade_name: string
  fk_id_student_status_type: number
  student_status_type_name: string
}

export type ProspectStudents = {
  id: number
  student_full_name: string
}

export default function FindersStudentsAssociation() {
  const { id } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [panel, setPanel] = useState<boolean>(false)
  const [associatedStudents, setAssociatedStudents] = useState<ViewFinderStudent[]>([])
  const [blockedIds, setBlockedIds] = useState<number[]>([])
  const [selectedStudents, setSelectedStudents] = useState<number[]>([])
  const [disabledButton, setDisabledButton] = useState<boolean>(false)

  const nameRegExp = useMemo(() => new RegExp(name, 'i'), [name])

  const filterByName = (row: ViewFinderStudent) => {
    return nameRegExp.test(row.student_full_name)
  }

  const refreshTable = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/finder-student?fk_id_affiliation=${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setAssociatedStudents(data.associatedStudents)
      setBlockedIds(data.blockedIds)
      setLoading(false)
    } catch (error) {
      console.log(error)
      toast.error('Could not load students.')
    }
  }, [id])

  const onDelete = useCallback(async (_id: number) => {
    setDisabledButton(true)
    const _toast = toast('Deleting associations', { toastId: 'toast-on-delete', isLoading: true })

    try {
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/finder-student/${_id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      toastSuccess(_toast, 'Association deleted.')

      refreshTable()
      setSelectedStudents([])
      setLoading(false)
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not delete associations.')
    }

    setDisabledButton(false)
  }, [])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  return <>
    <RightSidePanel
      title="Associate students to finders"
      state={panel}
      close={() => setPanel(false)}
      maxWidth="lg"
    >
      {
        panel ?
          <FinderStudentsPanel
            blockedIds={blockedIds}
            refreshTable={refreshTable}
            onClose={() => setPanel(false)}
          />
          :
          <></>
      }
    </RightSidePanel>
    <Grid item xs={1.5}>
      <Button
        id='button-new-association'
        onClick={() => setPanel(true)}
        startIcon={<Add />}
      >
        Associate
      </Button>
    </Grid>
    {/* Spacer */}
    <Grid item xs={6.5} />
    <Grid item xs={4}>
      <TextField id='student-name-filter' label='Student Name' value={name} onChange={(e) => setName(e.target.value)} />
    </Grid>
    <Grid item xs={12}>
      {
        !loading ?
          <FindersStudentsTable
            disableButton={disabledButton}
            hasCheckbox={false}
            onDelete={onDelete}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            students={associatedStudents.filter(filterByName)}
          />
          :
          <PreLoader />
      }
    </Grid>
  </>
}
