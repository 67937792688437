import axios from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import EmailScheduleTable from './EmailScheduleTable'
import { Button, Dialog, DialogActions, DialogContent, Grid, MenuItem, TextField } from '@mui/material'
import { toast } from 'react-toastify'
import { toastError, toastSuccess } from '../assets/customToasts'
import { EmailSchedule as EmailScheduleType, Panel } from './types'
import { useMsal } from '@azure/msal-react'
import PreLoader from '../PreLoader'
import EmailScheduleDetails from './EmailScheduleDetails'
import { RightSidePanel } from 'wed-components'
import { DateTime } from 'luxon'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

type Filters = {
  startDate: DateTime | null
  endDate: DateTime | null
  status: boolean | 'all'
}

export default function EmailSchedule() {
  // Hooks
  const { accounts } = useMsal()
  // General
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [panel, setPanel] = useState<Panel>({ show: false })
  // Data
  const [schedule, setSchedule] = useState<EmailScheduleType[]>([])
  const [selectedRow, setSelectedRow] = useState<{ button?: HTMLElement, id: number } | null>(null)
  // Filters
  const [filters, setFilters] = useState<Filters>({
    startDate: null,
    endDate: null,
    status: 'all'
  })
  // useMemos
  const selectedEmail = useMemo<EmailScheduleType | null>(() => schedule.find((_) => _.id === selectedRow?.id) || null, [schedule, selectedRow])

  const filterStartDate = useCallback((row: EmailScheduleType) => {
    if (!filters.startDate) return true
    return DateTime.fromISO(row.send_date) >= filters.startDate
  }, [filters.startDate])

  const filterEndDate = useCallback((row: EmailScheduleType) => {
    if (!filters.endDate) return true
    return DateTime.fromISO(row.send_date) <= filters.endDate
  }, [filters.endDate])

  const filterStatus = useCallback((row: EmailScheduleType) => {
    switch (filters.status) {
      case 'all':
        return true
      default:
        return filters.status === row.status
    }
  }, [filters.status])

  const getSchedule = useCallback(async () => {
    setLoading(true)
    const _toast = toast(`Fetching schedule`, { toastId: 'sending-email', isLoading: true })

    try {
      const { data }: { data: EmailScheduleType[] } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_NOTIFIER_URL}/schedules?username=${accounts[0]?.username || 'Cypress testing'}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        }
      })

      console.log(data)

      setSchedule(data)
      setLoading(false)

      toastSuccess(_toast, 'Schedule loaded.')
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not load schedule.')
    }
  }, [accounts])

  const deteleSchedule = useCallback(async (id: number) => {
    const _toast = toast('Deleting scheduled email', { toastId: 'toast-delete-schedule', isLoading: true })

    try {
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_NOTIFIER_URL}/schedules/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_NOTIFIER_TOKEN}`
        },
      })

      setOpenModal(false)

      toastSuccess(_toast, 'Scheduled e-mail deleted.')
      getSchedule()
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not delete scheduled e-mail.')
    }
  }, [getSchedule])

  useEffect(() => {
    getSchedule()
  }, [getSchedule])

  return <>
    <RightSidePanel
      state={panel.show}
      title={'Scheduled e-mail details'}
      close={() => setPanel({ show: false })}
    >
      {
        panel && panel.id ?
          <EmailScheduleDetails
            id={panel.id}
          />
          :
          <></>
      }
    </RightSidePanel>
    <Dialog
      id='delete-scheduled-email-modal'
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogContent>
        You're about to delete this scheduled e-mail.
        <p><b>{selectedEmail?.email_template?.subject}</b></p>
        <p><b>Scheduled at: {DateTime.fromISO(selectedEmail?.send_date || '').toFormat('dd MMM yyyy \'at\' HH:mm')}</b></p>
        Are you sure?
      </DialogContent>
      <DialogActions sx={{ padding: "1rem" }}>
        <Button id='modal-button-cancel' onClick={() => setOpenModal(false)}>Cancel</Button>
        <Button id='modal-button-delete' onClick={() => deteleSchedule(selectedRow?.id || 0)}>Delete</Button>
      </DialogActions>
    </Dialog>
    {/* Spacer */}
    <Grid item xs={7.5} />
    <Grid item xs={1}>
      <TextField label='Status' select value={filters.status} onChange={(e) => setFilters({ ...filters, status: e.target.value === 'all' ? 'all' : JSON.parse(e.target.value) })}>
        <MenuItem value='all'>All</MenuItem>
        <MenuItem value='false'>Not sent</MenuItem>
        <MenuItem value='true'>Sent</MenuItem>
      </TextField>
    </Grid>
    <Grid item xs={1.5}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          slotProps={{ textField: { id: 'form-field-start-date' } }}
          label='From'
          format="dd-MMM-yy"
          value={filters.startDate}
          onChange={(newValue) => setFilters({ ...filters, startDate: newValue })}
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={1.5}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          slotProps={{ textField: { id: 'form-field-end-date' } }}
          label='To'
          format="dd-MMM-yy"
          value={filters.endDate}
          onChange={(newValue) => setFilters({ ...filters, endDate: newValue })}
        />
      </LocalizationProvider>
    </Grid>

    <Grid item xs={0.5}>
      <Button
        onClick={() => setFilters({ startDate: null, endDate: null, status: 'all' })}
        disabled={!filters.startDate && !filters.endDate && filters.status === 'all'}

      >
        <FilterAltOffIcon />
      </Button>
    </Grid>
    <Grid item xs={12} maxHeight={'73vh'}>
      {
        !loading ?
          <EmailScheduleTable
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            emailSchedule={schedule.filter(filterStartDate).filter(filterEndDate).filter(filterStatus)}
            setOpenModal={setOpenModal}
            setPanel={setPanel}
          />
          :
          <PreLoader />
      }
    </Grid>
  </>
}
