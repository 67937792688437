import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Autocomplete, Button, Dialog, Grid, MenuItem, Paper, TextField, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import DownloadIcon from '@mui/icons-material/Download'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import axios from 'axios'
import { DateTime } from 'luxon'
import { utils, writeFile } from 'xlsx'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import PreLoader from '../../PreLoader'
import { formatNumberToMoney, getOrdinalFrom } from '../../Utils'
import { toast } from 'react-toastify'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { AutocompleteOption } from '../../../utilities/generalPurposeTypes'
import InvoiceTable from './InvoiceTable'
import InvoicePDFModal from './InvoicePDFModal'
import { Cancel } from '@mui/icons-material'
import { ViewCustomer } from './types'

export type ExportToExcel = {
  'Bundle / Service / Student': string,
  'Grade': string | null,
  'Installment': string | null,
  'Quantity': number | null,
  'Gross Value': string,
  'Due Value': string,
}

export type PdfInvoiceRow = {
  name: string,
  grade: string | null,
  installment: string | null,
  quantity: number | null,
  grossValue: string,
  dueValue: string
  rowMargin?: number,
}

export type Filters = {
  affiliation: AutocompleteOption | null
  currency: number
  endDate: DateTime | null
  payerType: number
  startDate: DateTime | null
}

export type ViewInvoice = {
  billings: {
    customers: {
      beneficiary_customer_name: string
      beneficiary_fk_id_grade: number
      beneficiary_grade_name: string
      bundle_name: string | null
      cashflow: {
        due_value: number
        fk_id_billing: number
        gross_value: number
        installment_number: number
      }[]
      default_billing_name: string | null
      fk_id_bundle: number | null
      fk_id_customer_beneficiary: number
      fk_id_default_billing: number | null
      fk_id_service: number
      id: number
      total_number_of_installments: number
      service_name: string
    }[]
    default_billing_name?: string
    fk_id_default_billing?: number
    fk_id_grade: number
    grade_name: string
    number_of_installments: number
    total_due_value: number
    total_gross_value: number
  }[]
  bundle_name?: string
  default_billing_name?: string
  fk_id_bundle?: number
  fk_id_default_billing?: number
  fk_id_service?: number
  service_name?: string
}

export default function Invoice() {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(false)
  // Data
  const [data, setData] = useState<ViewInvoice[]>([])
  const [customers, setCustomers] = useState<ViewCustomer[]>([])
  // Filters
  const [filters, setFilters] = useState<Filters>({
    affiliation: null,
    currency: 5,
    endDate: DateTime.utc().endOf('month'),
    payerType: 1,
    startDate: DateTime.utc().startOf('month'),
  })
  // useMemos
  const affiliationOptions = useMemo(() => lookupTables.affiliation.map((_) => ({ id: _.id, label: `${_.tag} - ${_.option}` })), [lookupTables])
  const showHelperText = useMemo(() => {
    if (!filters.startDate || !filters.endDate) return

    const diffInMonths = filters.endDate.diff(filters.startDate, 'months');

    // Show helper text
    if (diffInMonths.months > 1) return true
    // Hide helper text
    return false
  }, [filters.startDate, filters.endDate])
  const isFormSubmittable = useMemo(() => {
    if (!Boolean(filters.affiliation) || !Boolean(filters.startDate) || !Boolean(filters.endDate) || !customers.length) return false

    return true
  }, [filters, customers])

  // Fetch customers for customers' filter
  const getCustomers = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/customers?fk_id_customer_type=1`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setCustomers(data)
    } catch (err) {
      console.log(err)
      toast.error('Could not load customers.')
    }
  }, [setCustomers])

  // Fetch customers on page load
  useEffect(() => {
    getCustomers()
  }, [getCustomers])

  // Fetching billings
  const refreshTable = useCallback(async () => {
    setLoading(true)

    if (!filters.affiliation || !filters.startDate || !filters.endDate) return

    // Set End date as last day of the month in order to get all billings from that month
    const newStartDate = filters.startDate.startOf('day').toISO({ includeOffset: false })!
    const newEndDate = filters.endDate.endOf('day').toISO({ includeOffset: false })!

    const _customerId = customers.find(customer => customer.fk_id_customer_dependence === filters.affiliation?.id)?.id

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/invoice/${filters.affiliation.id}?startDate=${newStartDate}&endDate=${newEndDate}&currency=${filters.currency}&payerType=${filters.payerType}&payer=${_customerId}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setData(data)

      // setSelectedCurrency(data.billings[0].fk_id_currency || 5)
    } catch (err) {
      console.log(err)

      toast.error('No billings found.')
    }

    setLoading(false)
  }, [filters])

  useEffect(() => {
    setData([])
  }, [filters])

  const exportToExcel = useCallback(() => {
    const wb = utils.book_new()

    const _ = data.reduce((prev: ExportToExcel[], curr) => {
      prev.push(
        {
          "Bundle / Service / Student": curr.bundle_name || curr.default_billing_name || curr.service_name || '',
          "Grade": null,
          "Installment": null,
          "Quantity": curr.billings.map((_defaultBilling) => _defaultBilling.number_of_installments).reduce((prev, curr) => prev += curr, 0),
          "Gross Value": formatNumberToMoney(curr.billings.map((_defaultBilling) => _defaultBilling.total_gross_value).reduce((prev, curr) => prev += curr, 0)),
          "Due Value": formatNumberToMoney(curr.billings.map((_defaultBilling) => _defaultBilling.total_due_value).reduce((prev, curr) => prev += curr, 0))
        },
        ...curr.billings.reduce((_prev: ExportToExcel[], _curr) => {
          const _defaultBillingRow = {
            "Bundle / Service / Student": curr.fk_id_bundle ? `${_curr.default_billing_name} (${_curr.grade_name})` : _curr.grade_name,
            "Grade": null,
            "Installment": null,
            "Quantity": _curr.number_of_installments,
            "Gross Value": formatNumberToMoney(_curr.total_gross_value),
            "Due Value": formatNumberToMoney(_curr.total_due_value),
          }

          const _customerRows = _curr.customers.map((_customer) => ({
            "Bundle / Service / Student": _customer.beneficiary_customer_name,
            "Grade": _customer.beneficiary_grade_name,
            "Installment": _customer.cashflow.map((_installment) => getOrdinalFrom(_installment.installment_number)).join(', ') + ' of ' + _customer.total_number_of_installments,
            "Quantity": _customer.cashflow.length > 1 ? _customer.cashflow.length : null,
            "Gross Value": formatNumberToMoney(_customer.cashflow.map((_installment) => _installment.gross_value).reduce((__prev, __curr) => __prev += __curr, 0)),
            "Due Value": formatNumberToMoney(_customer.cashflow.map((_installment) => _installment.due_value).reduce((__prev, __curr) => __prev += __curr, 0)),
          }))

          _prev.push(
            _defaultBillingRow,
            ..._customerRows
          )

          return _prev
        }, [])
      )

      return prev
    }, [])

    const ws = utils.json_to_sheet(_)
    utils.book_append_sheet(wb, ws, filters.startDate?.toFormat('LLL-yy'))

    writeFile(wb, `Invoice - ${filters.affiliation?.label} (${filters.startDate?.toFormat('LLL-yy')}).xlsx`)
  }, [data, filters.startDate])

  return <>
    {/* Download modal */}
    <Dialog open={modal} maxWidth='xl' fullWidth>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h4'>
            Export invoice to PDF
          </Typography>
        </Grid>
        <Grid item xs={12} height={'80vh'}>
          <InvoicePDFModal
            affiliation={filters.affiliation!}
            currency={lookupTables.currency.find((_) => _.id === filters.currency)!}
            data={data}
            filters={filters}
          />
        </Grid>
        {/* Spacer */}
        <Grid item xs={9} />
        <Grid item xs={3}>
          <Button
            color='error'
            startIcon={<Cancel />}
            onClick={() => setModal(false)}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Dialog>
    {/* Filters */}
    <Grid item xs={2.25}>
      <Autocomplete
        id='filter-affiliation'
        options={affiliationOptions}
        noOptionsText='No affiliations available'
        onChange={(event, newValue) => setFilters({ ...filters, affiliation: newValue })}
        renderInput={(params) => <TextField {...params} label='Affiliation' />}
        onKeyUp={(e) => (e.ctrlKey && e.code === 'Enter') ? refreshTable() : null}
      />
    </Grid>
    <Grid item xs={1.25}>
      <TextField
        id='filters-payer-type'
        label='Payer type'
        value={filters.payerType}
        onChange={(e) => setFilters(prev => ({ ...prev, payerType: Number(e.target.value) }))}
        select
      >
        <MenuItem id='payer-type-affiliation' value='1'>Affiliation</MenuItem>
        <MenuItem id='payer-type-student' value='2'>Student</MenuItem>
      </TextField>
    </Grid>
    <Grid item xs={1}>
      <TextField
        id='filters-currency'
        label='Currency'
        value={filters.currency}
        onChange={(e) => setFilters(prev => ({ ...prev, currency: Number(e.target.value) }))}
        select
      >
        {
          lookupTables.currency
            .map((currency, index) => <MenuItem key={index} value={currency.id}>{currency.option}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={1.25}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          label='Start Date'
          format="dd-LLL-yy"
          value={filters.startDate}
          onChange={(newValue) => setFilters(prev => ({
            ...prev,
            startDate: newValue,
            endDate: newValue?.plus({ months: 1 }).minus({ days: 1 }) || null
          }))}
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={1.25}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          label='End Date'
          format="dd-LLL-yy"
          value={filters.endDate}
          onChange={(newValue) => setFilters(prev => ({ ...prev, endDate: newValue }))}
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
      {
        showHelperText ?
          <Paper sx={{ padding: 0, height: '100%' }}>
            <Grid container>
              <Grid item xs={2} display='flex' justifyContent='center' alignItems='center'>
                <WarningRoundedIcon color='warning' />
              </Grid>
              <Grid item xs={10}>
                <Typography>Selected date range is greater than 1 month</Typography>
              </Grid>
            </Grid>
          </Paper>
          :
          null
      }
    </Grid>
    {/* Spacer */}
    <Grid item xs={.25} />
    <Grid item xs={.75} >
      <Button
        id='button-export-pdf'
        startIcon={<DownloadIcon />}
        color='info'
        sx={{ color: 'white' }}
        disabled={!data.length || !filters.affiliation}
        onClick={() => setModal(true)}
      >
        PDF
      </Button>
    </Grid>
    <Grid item xs={.75} >
      <Button
        id='button-export-excel'
        startIcon={<DownloadIcon />}
        color='success'
        sx={{ color: 'white', backgroundColor: 'var(--color-darkGreen)' }}
        disabled={!data.length}
        onClick={exportToExcel}
      >
        XLSX
      </Button>
    </Grid>
    <Grid item xs={1.25} >
      <Button
        id='button-fetch'
        startIcon={<SearchIcon />}
        disabled={!isFormSubmittable || loading}
        onClick={() => refreshTable()}
      >
        Search
      </Button>
    </Grid>
    {
      loading ?
        <PreLoader height={'70vh'} />
        :
        <Grid item xs={12} height={'75vh'}>
          <InvoiceTable
            data={data}
            currencySign={lookupTables.currency.find((_currency) => _currency.id === filters.currency)?.sign || null}
          />
        </Grid>
    }
  </>
}
