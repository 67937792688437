import { ArrowBack, ArrowForward, MoreHoriz, Search, Sync } from '@mui/icons-material'
import { Autocomplete, Button, Dialog, Grid, MenuItem, TextField, Tooltip } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import axios from 'axios'
import { DateTime } from 'luxon'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { NewEditButtons } from '../../assets/NewEditButtons'
import { RightSidePanel } from 'wed-components'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import BillingsTable from './billingsTable/BillingsTable'
import CashFlow from './CashFlow'
import PreLoader from '../../PreLoader'
import BillingsOptions from './BillingsOptions'
import { ViewBilling } from './types'

type Panel = {
  show: boolean
}

export type Modal = {
  show: boolean
  billings?: number[]
}

export type Filters = {
  affiliation: { id: number, label: string } | null
  currency: 'all' | number
  defaultBillings: { id: number, label: string }[]
  endDate: DateTime | null
  grade: 'all' | number
  referenceYear: number
  startDate: DateTime | null
  status: 'all' | number
  student: { id: number, label: string } | null
  value: 'gross' | 'due' | 'paid'
}

export default function Billings() {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const [searchMethod, setSearchMethod] = useState<'referenceYear' | 'startEndDate'>('startEndDate')
  const [showModal, setShowModal] = useState<Modal>({
    show: false
  })
  const [panel, setPanel] = useState<Panel>({
    show: false
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [defaultBillings, setDefaultBillings] = useState<{ id: number, label: string }[]>([])
  // Data
  const [billings, setBillings] = useState<ViewBilling[]>([])
  const [customers, setCustomers] = useState<{ id: number, label: string }[]>([])
  const [selectedBilling, setSelectedBilling] = useState<number | null>(null)
  // Filters
  const [filters, setFilters] = useState<Filters>({
    affiliation: null,
    currency: 5,
    defaultBillings: [],
    endDate: DateTime.utc().endOf('year'),
    grade: 'all',
    referenceYear: DateTime.utc().get('year'),
    startDate: DateTime.utc().startOf('year'),
    status: 'all',
    student: null,
    value: 'due'
  })
  // useMemos
  const isFormSubmittable = useMemo(() => {
    if (!filters.affiliation) return false

    if (searchMethod === 'startEndDate' && (!filters.startDate || !filters.endDate)) return false

    return true
  }, [searchMethod, filters])
  const affiliationOptions = useMemo(() => lookupTables.affiliation.map((_) => ({ id: _.id, label: `${_.tag} - ${_.option}` })), [lookupTables])

  const monthsToRender = useMemo(() => {
    const months: DateTime[] = []

    var startDate: DateTime | null = null
    var endDate: DateTime | null = null

    if (searchMethod === 'referenceYear') {
      // Find the start and end date
      const allDueDates = billings.flatMap((_billing) => _billing.cashflow.map((_installment) => _installment.due_date)).sort()

      if (!allDueDates.length) return months

      startDate = DateTime.fromISO(allDueDates[0], { zone: 'UTC' })
      endDate = DateTime.fromISO(allDueDates[allDueDates.length - 1], { zone: 'UTC' })
    }
    // Start and End date
    else {
      if (!filters.startDate || !filters.endDate) return months

      startDate = filters.startDate
      endDate = filters.endDate
    }

    var currentMonth = startDate.startOf('month')

    // Max of 24 months to render
    while (currentMonth.diff(endDate).milliseconds < 0 && months.length <= 24) {
      months.push(currentMonth)

      currentMonth = currentMonth.plus({ month: 1 })
    }

    return months
  }, [billings, searchMethod, filters.startDate, filters.endDate])

  const refreshTable = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billings?fk_id_affiliation=${filters.affiliation!.id}` + (searchMethod === 'referenceYear' ? `&reference_year=${filters.referenceYear}` : `&startDate=${filters.startDate!.toISO()}&endDate=${filters.endDate!.toISO()}`),
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setBillings(data.billings)
      setCustomers(data.customers)
      setDefaultBillings(data.defaultBillings)
    } catch (err) {
      console.log(err)
      toast.error('Could not load billings.')
    }

    setLoading(false)
  }, [searchMethod, filters, setLoading])

  const handleDelete = useCallback(async () => {
    const _toast = toast('Deleting billing', { toastId: 'toast-billings-delete', isLoading: true })

    try {
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billings/${selectedBilling}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      refreshTable()

      setSelectedBilling(null)

      toast.update(_toast, { render: 'Billings deleted.', type: 'success', isLoading: false, autoClose: 2500 })
    } catch (err) {
      console.log(err)
      toast.update(_toast, { render: 'Could not delete billing.', type: 'error', isLoading: false })
    }
  }, [selectedBilling, refreshTable])

  useEffect(() => {
    setBillings([])
    setCustomers([])
    setDefaultBillings([])
  }, [filters.affiliation, filters.startDate, filters.endDate])

  // Filters
  const filterByCurrency = useCallback((row: ViewBilling) => {
    switch (filters.currency) {
      case 'all':
        return true
      default:
        return row.fk_id_currency === filters.currency
    }
  }, [filters.currency])

  const filterByCustomer = useCallback((row: ViewBilling) => {
    switch (filters.student) {
      case null:
        return true
      default:
        return row.fk_id_customer_beneficiary === filters.student.id
    }
  }, [filters.student])

  const filterByDefaultBilling = useCallback((row: ViewBilling) => {
    if (!filters.defaultBillings.length) return true

    if (!row.fk_id_default_billing) return false

    else if (filters.defaultBillings.map((_) => _.id).includes(row.fk_id_default_billing)) return true

    return false
  }, [filters.defaultBillings])

  const filterByGrade = useCallback((row: ViewBilling) => {
    switch (filters.grade) {
      case 'all':
        return true
      default:
        return row.beneficiary_fk_id_grade === filters.grade
    }
  }, [filters.grade])

  const filterByStudentStatusType = useCallback((row: ViewBilling) => {
    switch (filters.status) {
      case 'all':
        return true
      default:
        return row.beneficiary_fk_id_student_status_type === filters.status
    }
  }, [filters.status])

  return <>
    {/* Cashflow Modal */}
    <Dialog id='cash-flow' open={showModal.show} onClose={() => setShowModal({ show: false })} maxWidth='xl'>
      <Grid container maxWidth='xl'>
        <CashFlow
          billingIds={showModal.billings}
          onClose={() => setShowModal({ show: false })}
          refreshTable={refreshTable}
        />
      </Grid>
    </Dialog>
    {/* Filters Panel */}
    <RightSidePanel state={panel.show} close={() => setPanel({ show: false })} title='Advanced filters' maxWidth='sm'>
      <BillingsOptions
        billings={billings}
        defaultBillings={defaultBillings}
        filters={filters}
        monthsToRender={monthsToRender}
        setFilters={setFilters}
      />
    </RightSidePanel>
    {/* Filters */}
    <Grid item xs={.75}>
      <Tooltip title='Change search method'>
        <Button
          id='button-change-search-method'
          onClick={() => setSearchMethod((prev) => prev === 'referenceYear' ? 'startEndDate' : 'referenceYear')}
        >
          <Sync />
        </Button>
      </Tooltip>
    </Grid>
    {
      searchMethod === 'referenceYear' ?
        <>
          <Grid item xs={1.5}>
            <TextField
              id='filter-reference-year'
              label='Reference year'
              value={filters.referenceYear}
              onChange={(e) => setFilters((_filters) => ({ ..._filters, referenceYear: Number(e.target.value) }))}
              select
            >
              <MenuItem value='2023'>2023</MenuItem>
              <MenuItem value='2024'>2024</MenuItem>
              <MenuItem value='2025'>2025</MenuItem>
            </TextField>
          </Grid>
        </>
        :
        <>
          <Grid item xs={.5}>
            <DateButtons
              id='start-date'
              onClickPrev={() => setFilters((_filters) => ({ ..._filters, startDate: _filters.startDate?.minus({ month: 1 }).endOf('month') || null }))}
              onClickNext={() => setFilters((_filters) => ({ ..._filters, startDate: _filters.startDate?.plus({ month: 1 }).endOf('month') || null }))}
            />
          </Grid >
          <Grid item xs={1.25}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label='Start Date'
                format="dd-MMM-yy"
                value={filters.startDate}
                onChange={(newValue) => setFilters({ ...filters, startDate: newValue })}
                maxDate={filters.endDate}
                slotProps={{ textField: { id: 'filter-start-date' } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={.5}>
            <DateButtons
              id='end-date'
              onClickPrev={() => setFilters((_filters) => ({ ..._filters, endDate: _filters.endDate?.minus({ month: 1 }).endOf('month') || null }))}
              onClickNext={() => setFilters((_filters) => ({ ..._filters, endDate: _filters.endDate?.plus({ month: 1 }).endOf('month') || null }))}
            />
          </Grid>
          <Grid item xs={1.25}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label='End Date'
                format="dd-MMM-yy"
                value={filters.endDate}
                onChange={(newValue) => setFilters({ ...filters, endDate: newValue })}
                minDate={filters.startDate}
                slotProps={{ textField: { id: 'filter-end-date' } }}
              />
            </LocalizationProvider>
          </Grid>
        </>
    }
    {/* Filters */}
    <Grid item xs={1.75}>
      <Autocomplete
        id='filter-affiliation'
        options={affiliationOptions}
        value={filters.affiliation}
        onChange={(e, newValue) => setFilters((_filters) => ({ ..._filters, affiliation: newValue }))}
        renderInput={(params) => <TextField {...params} label="Affiliation" />}
      />
    </Grid>
    <Grid item xs={1.75}>
      <Autocomplete
        id='filter-student'
        options={customers}
        value={filters.student}
        onChange={(e, newValue) => setFilters((_filters) => ({ ..._filters, student: newValue }))}
        renderInput={(params) => <TextField {...params} label="Student" />}
        disabled={customers.length === 0}
      />
    </Grid>
    <Grid item xs={.75}>
      <Button
        id='button-search'
        onClick={refreshTable}
        disabled={loading || !isFormSubmittable}
      >
        <Search />
      </Button>
    </Grid>
    {/* Spacer */}
    <Grid item xs={searchMethod === 'referenceYear' ? 2.25 : .25} />
    {/* Buttons */}
    <NewEditButtons
      _new={{ label: 'New Billing', onClick: () => setShowModal({ show: true }), width: 1.5, height: 48 }}
      _delete={{ disabled: !selectedBilling, onClick: handleDelete, height: 48 }}
    />
    <Grid item xs={.75}>
      <Button
        id='button-more-options'
        onClick={() => setPanel({ show: true })}
      >
        <MoreHoriz />
      </Button>
    </Grid>
    {/* Table */}
    <Grid item xs={12}>
      {
        loading ?
          <PreLoader height={'70vh'} />
          :
          <BillingsTable
            billings={billings.filter(filterByCurrency).filter(filterByCustomer).filter(filterByDefaultBilling).filter(filterByGrade).filter(filterByStudentStatusType)}
            currencySign={lookupTables.currency.find((_) => _.id === filters.currency)?.sign || '?'}
            filters={filters}
            monthsToRender={monthsToRender}
            setShowModal={setShowModal}
            selectedBilling={selectedBilling}
            setSelectedBilling={setSelectedBilling}
          />
      }
    </Grid>
  </>
}

type DateButtonsProps = {
  id: string,
  onClickPrev: () => void
  onClickNext: () => void
}

function DateButtons({ id, onClickPrev, onClickNext }: DateButtonsProps) {
  return <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
    <button
      id={`button-prev-${id}`}
      style={{ width: '100%', height: '47.5%', backgroundColor: 'white', borderRadius: 4, display: 'grid', placeItems: 'center' }}
      onClick={onClickPrev}
    >
      <ArrowBack sx={{ height: 14, width: 14 }} />
    </button>
    <button
      id={`button-next-${id}`}
      style={{ width: '100%', height: '47.5%', backgroundColor: 'white', borderRadius: 4, display: 'grid', placeItems: 'center' }}
      onClick={onClickNext}
    >
      <ArrowForward sx={{ height: 14, width: 14 }} />
    </button>
  </div>
}