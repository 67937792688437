import { useCallback, useContext, useEffect, useState } from "react"
import { Button, Container, Dialog, DialogActions, DialogContent, Grid, MenuItem, TextField } from "@mui/material"
import { RightSidePanel } from "wed-components"
import PreLoader from "../PreLoader"
import Header from "../header/Header"
import AffiliationForm from "./AffiliationForm"
import AffiliationTable from "./AffiliationTable"

import { useNavigate } from "react-router-dom"
import { LookupTablesContext } from "../../context/LookupTablesContext"
import { useSessionStorageFilters } from "../../hooks/useLocalStorageFilters"

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import axios from "axios"
import { toast } from "react-toastify"
import { ViewAffiliationProgramMap } from "./AffiliationProgramForm"

export type Affiliation = {
  id?: number
  name: string
  tag: string
  address: string
  fk_id_state: number | null
  fk_id_country: number | null
  phone: string
  email: string
  fk_id_affiliation_type: number | null
  is_active: boolean
  billing_email?: string
  tax_payer_number?: string
  affiliation_programs?: ViewAffiliationProgramMap[]
}

export type ViewAffiliation = {
  id: number
  name: string
  affiliation_name: string
  affiliation_adress: string | null
  affiliation_phone: string | null
  affiliation_email: string
  affiliation_billing_email: string
  tax_payer_number: string
  is_active: boolean
  affiliation_tag: string
  affiliation_type_tag: string | null
  affiliation_type_name: string
  fk_id_affiliation_type: number | null
  fk_id_country: number
  affiliation_country_name: string
  fk_id_state: number | null
  affiliation_state_name: string | null
}

export type Form = {
  show: boolean
  id?: number
}

type Modal = {
  show: boolean
  id?: number
}

type Filters = {
  id: string
  name: string
  status: string
  type: 'all' | number
}

export default function AffiliationSearch() {
  // General
  const [loading, setLoading] = useState<boolean>(true)
  const [modal, setModal] = useState<boolean>(false)
  const [form, setForm] = useState<Form>({
    show: false
  })
  // Data
  const [data, setData] = useState<ViewAffiliation[]>([])
  // Hooks
  const navigate = useNavigate()
  const { lookupTables, refreshLookupTables } = useContext(LookupTablesContext)
  const [filters, setFilters] = useSessionStorageFilters<Filters>({
    id: '',
    name: '',
    status: 'active',
    type: 'all'
  }, '_affiliationFilters')

  const refreshTable = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliations`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setData(data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      toast.error('Could not fetch affiliations.')
    }
  }, [setData, setLoading])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  // Filters
  const idFilter = useCallback((row: ViewAffiliation) => {
    switch (filters.id) {
      case '':
        return true
      default:
        return row.id === Number(filters.id)
    }
  }, [filters.id])

  const nameFilter = useCallback((row: ViewAffiliation) => {
    const re = new RegExp(filters.name.toLowerCase(), 'i')

    switch (filters.name) {
      case '':
        return true
      default:
        return re.test(row.affiliation_name.toLowerCase())
    }
  }, [filters.name])

  const statusFilter = useCallback((row: ViewAffiliation) => {
    switch (filters.status) {
      case 'all':
        return true
      case 'active':
        return row.is_active
      case 'inactive':
        return !row.is_active
      default:
        return true
    }
  }, [filters.status])

  const typeFilter = useCallback((row: ViewAffiliation) => {
    switch (filters.type) {
      case 'all':
        return true
      default:
        return row.fk_id_affiliation_type === filters.type
    }
  }, [filters.type])

  const onDelete = useCallback(async () => {
    const _toast = toast('Deleting affiliation', { toastId: 'toast-affiliations-delete', isLoading: true })

    try {
      const { data } = await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliations/${form.id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      toast.update(_toast, { render: 'Affiliation deleted.', type: 'success', isLoading: false, autoClose: 2500 })

      refreshLookupTables(['affiliation'])

      refreshTable()
    } catch (err) {
      console.log(err)
      toast.update(_toast, { render: 'Could not delete affiliation.', type: 'error', isLoading: false })
    }
  }, [form, refreshTable, refreshLookupTables])

  useEffect(() => {
    console.log(filters)
  }, [filters])

  return <Container>
    <br />
    {
      <RightSidePanel state={form.show} close={() => setForm({ show: false })} title={'Creating a new affiliation'} theme={'dark'} maxWidth={'lg'}>
        {
          form.show ?
            <Grid container spacing={1}>
              <AffiliationForm
                refreshTable={refreshTable}
                setForm={setForm}
              />
            </Grid>
            :
            <></>
        }
      </RightSidePanel>
    }
    {/* Delete modal */}
    <Dialog
      id='modal'
      open={modal}
      onClose={() => setModal(false)}
    >
      <DialogContent>
        You're about to delete an affiliation: <strong>{data?.find((_affiliation) => _affiliation.id === form.id)?.affiliation_name}</strong>.
        <br />
        Are you sure you want to delete it?
      </DialogContent>
      <DialogActions sx={{ padding: "1rem" }}>
        <Button
          id='modal-button-cancel'
          onClick={() => setModal(false)}
        >
          Cancel
        </Button>
        <Button
          id='modal-button-delete'
          onClick={() => {
            onDelete()
            setModal(false)
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Header title={'Affiliations'} name={''} />
      </Grid>
      {/* Filters */}
      <Grid item xs={1}>
        <TextField id='filter-id' label='ID' value={filters.id} onChange={(e) => setFilters({ ...filters, id: e.target.value })} />
      </Grid>
      <Grid item xs={5}>
        <TextField id='filter-name' label='Name' value={filters.name} onChange={(e) => setFilters({ ...filters, name: e.target.value })} />
      </Grid>
      <Grid item xs={1.5}>
        <TextField id='filter-status' label={'Status'} value={filters.status} select onChange={(e) => setFilters({ ...filters, status: e.target.value })}>
          <MenuItem id={'status-option-all'} value={'all'}>All</MenuItem>
          <MenuItem id={'status-option-active'} value={'active'}>Active</MenuItem>
          <MenuItem id={'status-option-inactive'} value={'inactive'}>Inactive</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={1.5}>
        <TextField id='filter-affiliation-type' label={'Type'} value={filters.type} onChange={(e) => setFilters({ ...filters, type: e.target.value === 'all' ? 'all' : Number(e.target.value) })} select>
          <MenuItem selected={true} key={-1} value={'all'}>All</MenuItem>
          {
            lookupTables.affiliation_type.map((_, index) => <MenuItem key={index} id={`affiliation-type-option-${_.id}`} value={_.id}>{_.option}</MenuItem>)
          }
        </TextField>
      </Grid>
      {/* Buttons */}
      <Grid item xs={.75}>
        <Button
          id='button-clear-filters'
          onClick={() => setFilters({
            id: '',
            name: '',
            status: 'all',
            type: 'all'
          })}
        >
          <FilterAltOffIcon />
        </Button>
      </Grid>

      <Grid item xs={.75}>
        <Button
          id='button-delete'
          onClick={() => setModal(true)}
          disabled={!form.id}
        >
          <DeleteIcon />
        </Button>
      </Grid>

      <Grid item xs={.75}>
        <Button
          id='button-edit'
          onClick={() => navigate(`/affiliations/${form.id}`)}
          disabled={!form.id}
        >
          <EditIcon />
        </Button>
      </Grid>
      <Grid item xs={.75}>
        <Button
          id='button-new'
          onClick={() => setForm({ ...form, show: true })}
          disabled={false}
        >
          <AddIcon />
        </Button>
      </Grid>
      <Grid item xs={12} height={'80vh'}>
        {
          loading ?
            <PreLoader />
            :
            <AffiliationTable
              setForm={setForm}
              form={form}
              data={data?.filter(nameFilter).filter(idFilter).filter(statusFilter).filter(typeFilter)}
            />
        }
      </Grid>
    </Grid>
  </Container>
}