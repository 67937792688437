import { BodyContainer, BodyRow, Cell, ExpansibleRow, HeaderRow, Table } from "wed-components"
import { GroupBySection, Panel, Student } from "./Submissions"
import { IconButton, Tooltip } from "@mui/material"
import { Error, Refresh, Visibility } from "@mui/icons-material"
import { Fragment, useState } from "react"

type Props = {
  students: Student[],
  panel: Panel,
  setPanel: React.Dispatch<React.SetStateAction<Panel>>
}

export function SubmissionsTablePerStudent({ students, panel, setPanel }: Props) {
  const columnWidth = [3, 3, .75, 3, 1.5, .6]

  return <Table>
    <HeaderRow>
      <Cell flexGrow>
        <p>
          Student
        </p>
      </Cell>
      <Cell width={columnWidth[1]}>
        <p>
          Section
        </p>
      </Cell>
      <Cell width={columnWidth[2]}>
        <p>
          Class
        </p>
      </Cell>
      <Cell width={columnWidth[3]}>
        <p>
          Teacher
        </p>
      </Cell>
      <Cell width={columnWidth[4]}>
        <p>
          Late assignments
        </p>
      </Cell>
      <Cell width={columnWidth[5]}>
        <p>

        </p>
      </Cell>
    </HeaderRow>
    <BodyContainer>
      {
        students.length ?
          students.map((row, index) => <BodyRow key={index} selected={row.id === panel.id}>
            <Cell flexGrow>
              <p>
                {row.student_full_name}
              </p>
            </Cell>
            <Cell width={columnWidth[1]}>
              <p>
                {row.section_name}
              </p>
            </Cell>
            <Cell width={columnWidth[2]}>
              <p>
                {row.class_tag}
              </p>
            </Cell>
            <Cell width={columnWidth[3]}>
              {row.faculty.map((_faculty, index) => <p key={index}>{_faculty.faculty_full_name}</p>)}
            </Cell>
            <Cell width={columnWidth[4]} style={{ flexDirection: 'row' }}>
              <p>
                {row.submissions.length}
              </p>
              {
                row.submissions.find((_) => _.has_seen_attempt) ? <Tooltip title='Some submissions have no grade, but the teacher has already seen it.'><Error sx={{ color: '#aaa' }} /></Tooltip> : null
              }
              {
                row.submissions.find((_) => _.days_late <= 5 && !_.has_seen_attempt) ? <Tooltip title='Some submissions will exceed the deadline for grading'><Error sx={{ color: 'var(--color-yellow)' }} /></Tooltip> : null
              }
              {
                row.submissions.find((_) => _.days_late > 5 && !_.has_seen_attempt) ? <Tooltip title='Some submissions have exceeded the 5 days deadline'><Error sx={{ color: 'var(--color-strongRed)' }} /></Tooltip> : null
              }
            </Cell>
            <Cell width={columnWidth[5]}>
              <IconButton
                id={`button-details-${row.id}`}
                onClick={() => setPanel({ show: true, id: row.id })}
              >
                <Visibility />
              </IconButton>
            </Cell>
          </BodyRow>)
          :
          <BodyRow>
            <Cell flexGrow>
              <p>
                No students found.
              </p>
            </Cell>
          </BodyRow>
      }
    </BodyContainer>
  </Table>
}

type PerSectionProps = {
  sections: GroupBySection[]
  setPanel: React.Dispatch<React.SetStateAction<Panel>>
  updateSubmissions: (fk_id_section: number, fk_id_student: number) => Promise<void>
}

export function SubmissionsTablePerSection({ sections, setPanel, updateSubmissions }: PerSectionProps) {
  const columnWidth = [4, 3, 3, 1.5, .6, .6]
  // General
  const [selectedId, setSelectedId] = useState<number | null>(null)

  return <Table>
    <HeaderRow>
      <Cell width={columnWidth[0]}>
        <p>
          Affiliation
        </p>
      </Cell>
      <Cell width={columnWidth[1]}>
        <p>
          Section
        </p>
      </Cell>
      <Cell width={columnWidth[2]}>
        <p>
          Teacher
        </p>
      </Cell>
      <Cell width={columnWidth[3]}>
        <p>
          Late assignments
        </p>
      </Cell>
      <Cell width={columnWidth[4]}>
        <p>

        </p>
      </Cell>
    </HeaderRow>
    <BodyContainer>
      {
        sections.length ?
          sections.map((row, index) => <Fragment key={index} >
            <BodyRow key={index} onClick={() => setSelectedId((_selectedId) => selectedId === row.fk_id_section ? null : row.fk_id_section)}>
              <Cell width={columnWidth[0]}>
                <p>
                  {row.affiliation_name}
                </p>
              </Cell>
              <Cell width={columnWidth[1]}>
                <p>
                  {row.section_name}
                </p>
              </Cell>
              <Cell width={columnWidth[2]}>
                {row.faculty.map((_faculty, index) => <p key={index}>{_faculty.faculty_full_name}</p>)}
              </Cell>
              <Cell width={columnWidth[3]} style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
                {
                  <p>
                    {row.students.reduce((prev, curr) => prev += curr.submissions.filter((_) => _.has_seen_attempt).length, 0)}
                    <Error sx={{ color: '#aaa' }} />
                  </p>
                }
                {
                  <p>
                    {row.students.reduce((prev, curr) => prev += curr.submissions.filter((_) => _.days_late <= 5 && !_.has_seen_attempt).length, 0)}
                    <Error sx={{ color: 'var(--color-yellow)' }} />
                  </p>
                }
                {
                  <p>
                    {row.students.reduce((prev, curr) => prev += curr.submissions.filter((_) => _.days_late > 5 && !_.has_seen_attempt).length, 0)}
                    <Error sx={{ color: 'var(--color-strongRed)' }} />
                  </p>
                }
              </Cell>
            </BodyRow>
            {
              selectedId === row.fk_id_section ?
                row.students.map((_student, index) => <ExpansibleRow state={true} key={index}>
                  <Cell width={columnWidth[0]}>
                    <p>
                      {_student.student_full_name}
                    </p>
                  </Cell>
                  <Cell flexGrow>
                    <p>
                      {_student.class_tag}
                    </p>
                  </Cell>
                  <Cell width={columnWidth[4]} style={{ flexDirection: 'row' }}>
                    <p>
                      {_student.submissions.length}
                    </p>
                    {
                      _student.submissions.find((_) => _.has_seen_attempt) ? <Tooltip title='Some submissions have no grade, but the teacher has already seen it.'><Error sx={{ color: '#aaa' }} /></Tooltip> : null
                    }
                    {
                      _student.submissions.find((_) => _.days_late <= 5 && !_.has_seen_attempt) ? <Tooltip title='Some submissions will exceed the deadline for grading'><Error sx={{ color: 'var(--color-yellow)' }} /></Tooltip> : null
                    }
                    {
                      _student.submissions.find((_) => _.days_late > 5 && !_.has_seen_attempt) ? <Tooltip title='Some submissions have exceeded the 5 days deadline'><Error sx={{ color: 'var(--color-strongRed)' }} /></Tooltip> : null
                    }
                  </Cell>
                  <Cell width={columnWidth[5]}>
                    <IconButton
                      id={`button-details-${_student.id}`}
                      onClick={() => setPanel({ show: true, id: _student.id })}
                      sx={{ width: 32, height: 32 }}
                    >
                      <Visibility />
                    </IconButton>
                  </Cell>
                  <Cell width={columnWidth[5]}>
                    <Tooltip title='Update submissions'>
                      <IconButton
                        id={`button-update-${_student.id}`}
                        onClick={() => updateSubmissions(row.fk_id_section, _student.fk_id_student)}
                        sx={{ width: 32, height: 32 }}
                      >
                        <Refresh />
                      </IconButton>
                    </Tooltip>
                  </Cell>
                </ExpansibleRow>
                )
                :
                null
            }
          </Fragment>)
          :
          <BodyRow>
            <Cell flexGrow>
              <p>
                No students found.
              </p>
            </Cell>
          </BodyRow>
      }
    </BodyContainer>
    <HeaderRow>
      <Cell width={columnWidth[0]}>
        <p>
          <Error sx={{ color: '#aaa' }} /> Submissions already seen by the teacher
        </p>
      </Cell>
      <Cell width={columnWidth[1]}>
        <p>
          <Error sx={{ color: 'var(--color-yellow)' }} /> Submitted less than 5 days ago
        </p>
      </Cell>
      <Cell width={columnWidth[2]}>
        <p>
          <Error sx={{ color: 'var(--color-strongRed)' }} /> Submitted more than 5 days ago
        </p>
      </Cell>
      <Cell width={columnWidth[3]} style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
        {
          <p style={{ border: 0 }}>
            {sections.reduce((prev, curr) => prev += curr.students.reduce((prev, curr) => prev += curr.submissions.filter((_) => _.has_seen_attempt).length, 0), 0)}
            <Error sx={{ color: '#aaa' }} />
          </p>
        }
        {
          <p style={{ border: 0 }}>
            {sections.reduce((prev, curr) => prev += curr.students.reduce((prev, curr) => prev += curr.submissions.filter((_) => _.days_late <= 5 && !_.has_seen_attempt).length, 0), 0)}
            <Error sx={{ color: 'var(--color-yellow)' }} />
          </p>
        }
        {
          <p style={{ border: 0 }}>
            {sections.reduce((prev, curr) => prev += curr.students.reduce((prev, curr) => prev += curr.submissions.filter((_) => _.days_late > 5 && !_.has_seen_attempt).length, 0), 0)}
            <Error sx={{ color: 'var(--color-strongRed)' }} />
          </p>
        }
      </Cell>
    </HeaderRow>
  </Table>
}