import { convertISOtoDateFormat } from "../../Utils"
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from "wed-components"
import { voucherType } from "./Vouchers"
import { Chip } from "@mui/material"
import { DateTime } from 'luxon'
import styles from './Voucher.module.css'

type VouchersTableProps = {
  data: voucherType[]
}

export default function VouchersBalanceTable({ data }: VouchersTableProps) {
  const columns = [
    { field: 'chip', headerName: '+ / -', width: .65 },
    { field: 'id_voucher', headerName: 'ID', width: .5 },
    { field: 'fk_id_voucher_type', headerName: 'Voucher Type', width: 1.75 },
    { field: 'issue_date', headerName: 'Issued', width: 1.25 },
    { field: 'expiration_date', headerName: 'Expiration', width: 1.25 },
    { field: 'consumed_date', headerName: 'Consumed', width: 1.25 },
    { field: 'description', headerName: 'Description', width: 2 },
  ] as const

  const today = DateTime.now().setZone('America/New_York')
  const endOfToday = today.endOf('day')

  const renderChip = (row: voucherType, expired: boolean) => {
    let label = 'error'
    let bgColor = '#03a9f4'

    if (row.consumed) {
      label = 'cons'
      bgColor = '#ef5350'
    } else if (expired) {
      label = 'exp'
      bgColor = 'hsl(198, 0%, 48%)'
    } else {
      label = 'avail'
      bgColor = '#42a5f5'
    }
    return <Chip label={label} sx={{ color: 'white', backgroundColor: bgColor, width: '100%' }} size="small" />
  }

  const expirationAlert = (consumedOrExpired: boolean, expirationDate: string) => {
    if (consumedOrExpired) return ''
    const daysToExpire = DateTime.fromISO(expirationDate).diff(today, 'days').days
    if (daysToExpire < 90) return <span className={styles.alertExpiration} >!</span>
    return ''
  }

  return (
    <Table>
      <HeaderRow>
        {
          columns.map((column, counter) => {
            return <Cell key={counter} width={column.width} flexGrow={column.field === 'description'} >
              <p style={{ color: column.field === 'chip' ? 'rgba(0, 0, 0, 0)' : '' }} >
                {column.headerName}
              </p>
            </Cell>
          })
        }
      </HeaderRow>
      <BodyContainer>
        {
          data.length ?
            data.map((row, counter) => {
              const expiredAndNotConsumed = !row.consumed && endOfToday.toISO()!.localeCompare(row.expiration_date) > 0
              const textStyle = {
                color: expiredAndNotConsumed ? '#fff6' : '',
                textDecoration: expiredAndNotConsumed ? 'line-through' : ''
              }

              return <BodyRow key={counter}>
                <Cell width={columns[0].width}>
                  {renderChip(row, expiredAndNotConsumed)}
                </Cell>
                <Cell width={columns[1].width}>
                  <p style={textStyle} >
                    {row.id}
                  </p>
                </Cell>
                <Cell width={columns[2].width}>
                  <p style={textStyle}>
                    {row.voucher_type_name}
                  </p>
                </Cell>
                <Cell width={columns[3].width}>
                  <p style={textStyle}>
                    {convertISOtoDateFormat(row.issue_date)}
                  </p>
                </Cell>
                <Cell width={columns[4].width}>
                  <p style={{ ...textStyle, position: 'relative' }}>
                    {convertISOtoDateFormat(row.expiration_date)} {
                      expirationAlert(row.consumed || expiredAndNotConsumed, row.expiration_date)
                    }
                  </p>
                </Cell>
                <Cell width={columns[5].width}>
                  <p style={textStyle}>
                    <strong>
                      {row.consumed_date ?
                        convertISOtoDateFormat(row.consumed_date)
                        :
                        '-'
                      }
                    </strong>
                  </p>
                </Cell>
                <Cell width={columns[6].width} flexGrow >
                  <p style={textStyle}>
                    {row.description}
                  </p>
                </Cell>
              </BodyRow>
            })
            :
            <BodyRow>
              <Cell width={4}>
                <p>
                  No vouchers to show.
                </p>
              </Cell>
            </BodyRow>
        }
      </BodyContainer>
    </Table>
  )
}
