import { useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { Grid, TextField, Autocomplete, Button, MenuItem } from '@mui/material'
import { Add, Remove, Search } from '@mui/icons-material'
import VouchersBalanceTable from './VouchersBalanceTable'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import axios from 'axios';
import { FeedbackContext } from '../../../context/FeedbackContext';
import VouchersBeneficiaryTable from './VouchersBeneficiaryTable';
import PreLoader from '../../PreLoader';
import { RightSidePanel } from 'wed-components';
import VouchersIssueForm from './VouchersIssueForm';
import VouchersConsumeForm from './VouchersConsumeForm';
import { DateTime } from 'luxon';
import { useSessionStorageFilters } from '../../../hooks/useLocalStorageFilters';
import { AutocompleteOption } from '../../../utilities/generalPurposeTypes';

export type filtersType = {
  student: string | null | undefined,
  availability: 'all' | 'available' | 'consumed' | 'expired',
  voucherType: number | null | undefined,
}

type beneficiaryVoucherMapType = {
  fk_id_voucher_type: number,
  voucher_type_name: string,
  voucher_type_tag: string,
  balance: number
}

export type beneficiaryType = {
  fk_id_customer_beneficiary: number,
  beneficiary_fk_id_customer_type: number,
  fk_id_customer_beneficiary_dependence_id: number,
  beneficiary_name: string,
  beneficiary_fk_id_grade: number | null,
  beneficiary_grade_name: string | null,
  beneficiary_fk_id_program: number | null,
  beneficiary_program_name: string | null,
  beneficiary_fk_id_affiliation: number,
  beneficiary_affiliation_name: string,
  voucher_types: beneficiaryVoucherMapType[]
}

export type voucherType = {
  id: number,
  fk_id_customer_beneficiary: number,
  beneficiary_name: string,
  fk_id_customer_beneficiary_dependence_id: number,
  beneficiary_fk_id_customer_type: number,
  beneficiary_customer_type_name: string,
  beneficiary_fk_id_affiliation: number,
  beneficiary_affiliation_name: string,
  issue_date: string,
  expiration_date: string,
  description: string,
  consumed: boolean,
  consumed_date: string | null,
  updated_by: string,
  fk_id_voucher_type: number,
  voucher_type_name: string,
  voucher_type_tag: string,
  is_active: number,
  default_duration_days: number,
  fk_id_request_voucher: number | null,
  fk_id_customer_payer: number | null,
  payer_name: string,
}

export type CustomerView = {
  id: number
  fk_id_customer_type: number
  fk_id_customer_dependence: number
  customer_type_name: string
  customer_name: string
  customer_affiliation_name: string
  customer_affiliation_id: number
  customer_grade_id: number
  customer_grade_name: number
  customer_program_id: number
  customer_program_name: number,
}

export type formTypeType = 'issue' | 'consume'

export default function Vouchers() {
  const { lookupTables } = useContext(LookupTablesContext)
  const { feedbackDispatch } = useContext(FeedbackContext)
  // Filters
  const [affiliation, setAffiliation] = useSessionStorageFilters<AutocompleteOption | null>(null, '_vouchers')
  const [filters, setFilters] = useState<filtersType>({
    student: '',
    availability: 'available',
    voucherType: null,
  })
  // Tables
  const [loading, setLoading] = useState(false)
  const [beneficiaryList, setBeneficiaryList] = useState<beneficiaryType[]>([])
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<beneficiaryType | null>(null)
  const [vouchers, setVouchers] = useState<voucherType[]>([])
  // Form
  const [showForm, setShowForm] = useState(false)
  const [formType, setFormType] = useState<formTypeType>('consume')
  const [customers, setCustomers] = useState<CustomerView[]>([])

  const handleSelectBeneficiary = (beneficiary: beneficiaryType) => {
    setFilters({ student: '', availability: 'available', voucherType: null })
    setSelectedBeneficiary(prev => {
      if (prev && prev.fk_id_customer_beneficiary === beneficiary.fk_id_customer_beneficiary) return null
      return beneficiary
    })
  }

  const refreshTable = useCallback(async () => {
    if (!affiliation) return
    setLoading(true)

    await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SIS_BACKEND_URL}/vouchers?fk_id_affiliation=${affiliation.id}`,
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
      }
    })
      .then(({ data }) => {
        console.log(data)
        setBeneficiaryList(data.beneficiaries)
        setVouchers(data.vouchers)
      })
      .catch(err => {
        feedbackDispatch({ mode: 'failed', message: 'Unable to fetch vouchers.' })
        console.log(err)
      })

    setLoading(false)
  }, [feedbackDispatch, setLoading, setBeneficiaryList, setVouchers, affiliation])

  const getCustomers = useCallback(async () => {
    await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SIS_BACKEND_URL}/customers`,
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
      }
    })
      .then(({ data }) => {
        setCustomers(data)
      })
      .catch(err => {
        console.log(err)
        feedbackDispatch({ mode: 'failed', message: 'Unable to fetch customers' })
      })
  }, [setCustomers, feedbackDispatch])

  useEffect(() => {
    // getCustomers() // Uncomment after defining best way to fetch customers
  }, [getCustomers])

  // Filtering functions
  // const filterByStudent = useCallback((row: any) => {
  //   if (filters.student === '' || !filters.student) return row
  //   return row.student_name.includes(filters.student)
  // }, [filters.student])

  // const filterByAffiliation = useCallback((row: any) => {
  //   if (!filters.affiliation) return row
  //   return row.fk_id_affiliation === Number(filters.affiliation)
  // }, [filters.affiliation])

  // const filterByVoucherType = useCallback((row: any) => {
  //   if (!filters.voucherType) return row
  //   return row.fk_id_voucher_type === Number(filters.voucherType)
  // }, [filters.voucherType])

  // const filteredData = useMemo(() => {
  //   const filteredData = vouchers.filter(filterByStudent).filter(filterByAffiliation).filter(filterByVoucherType)

  //   return filteredData
  // }, [vouchers, filterByStudent, filterByAffiliation, filterByVoucherType])

  const today = DateTime.now().setZone('America/New_York')
  const endOfToday = today.endOf('day')

  const filterByAvailability = useCallback((voucher: voucherType) => {
    const expiredAndNotConsumed = !voucher.consumed && endOfToday.toISO()!.localeCompare(voucher.expiration_date) > 0
    switch (filters.availability) {
      case 'all':
        return true
      case 'consumed':
        return voucher.consumed
      case 'expired':
        return expiredAndNotConsumed
      case 'available':
        return !voucher.consumed && !expiredAndNotConsumed
      default:
        return true
    }
  }, [filters.availability, endOfToday])

  const filteredData = useMemo(() => {
    return vouchers
      .filter(voucher => voucher.fk_id_customer_beneficiary === selectedBeneficiary?.fk_id_customer_beneficiary)
      .filter(filterByAvailability)
      .filter(voucher => !filters.voucherType ? true : voucher.fk_id_voucher_type === filters.voucherType)
  }, [vouchers, selectedBeneficiary, filterByAvailability, filters.voucherType])

  return <>
    <RightSidePanel
      title={formType === 'issue' ? 'Issue voucher(s)' : 'Consume voucher(s)'}
      close={() => setShowForm(false)}
      state={showForm}
    >
      {
        !showForm ?
          undefined
          :
          <Grid container spacing={1}>
            {
              formType === 'issue' ?
                <VouchersIssueForm
                  selectedBeneficiary={selectedBeneficiary}
                  customers={customers}
                  refreshTable={refreshTable}
                  closeForm={() => setShowForm(false)}
                />
                :
                <VouchersConsumeForm
                  id={{ type: 'fk_id_customer', fk_id: selectedBeneficiary?.fk_id_customer_beneficiary || null }}
                  refreshTable={refreshTable}
                  closeForm={() => setShowForm(false)}
                  allowVouchersConsumption
                />
            }
          </Grid>
      }
    </RightSidePanel>
    {/* Fetch Filters */}
    <Grid item xs={2.5}>
      <Autocomplete
        id='filter-affiliation'
        options={lookupTables.affiliation.map((affiliation) => ({ id: affiliation.id, label: `${affiliation.tag} - ${affiliation.option}` }))}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label='Affiliation' />}
        value={!affiliation ? null : affiliation}
        onChange={(event: any, newValue) => setAffiliation(newValue)}
      />
    </Grid>
    <Grid item xs={2}>
      <Button
        id='btn-fetch'
        startIcon={<Search />}
        onClick={refreshTable}
        disabled={!affiliation || loading}
        fullWidth
        sx={{ height: '100%' }}
      >
        Fetch
      </Button>
    </Grid>
    {/* Spacer */}
    <Grid item xs={2} />
    <Grid item xs={1.5} >
      <TextField
        id='filter-availability'
        label='Availability'
        value={filters.availability}
        onChange={(e) => setFilters(prev => ({ ...prev, availability: e.target.value as ('all' | 'available' | 'consumed' | 'expired') }))}
        select
      >
        {[
          <MenuItem key={0} value={'all' as const} >All</MenuItem>,
          <MenuItem key={1} value={'available' as const} >Available</MenuItem>,
          <MenuItem key={2} value={'consumed' as const} >Consumed</MenuItem>,
          <MenuItem key={3} value={'expired' as const} >Expired</MenuItem>,
        ]}
      </TextField>
    </Grid>
    <Grid item xs={2}>
      <Autocomplete
        id='filter-voucher-type'
        options={[...lookupTables.voucherType.map((_voucherType) => {
          return { id: _voucherType.id, name: _voucherType.option }
        })]}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label='Voucher Type' />}
        value={!filters.voucherType ?
          null
          :
          { id: filters.voucherType, name: lookupTables.voucherType.find(vt => vt.id === filters.voucherType)?.option || 'Undefined' }
        }
        onChange={(event: any, newValue: any | null) => setFilters({ ...filters, voucherType: newValue ? newValue.id : null })}
      />
    </Grid>
    <Grid item xs={1}>
      <Button
        id='btn-issue'
        startIcon={<Add />}
        fullWidth
        color='info'
        sx={{ height: '100%', color: 'white' }}
        onClick={() => { setFormType('issue'); setShowForm(true) }}
      >
        Issue
      </Button>
    </Grid>
    <Grid item xs={1}>
      <Button
        id='btn-consume'
        startIcon={<Remove />}
        fullWidth
        color='error'
        sx={{ height: '100%', color: 'white' }}
        onClick={() => { setFormType('consume'); setShowForm(true) }}
      >
        Consume
      </Button>
    </Grid>
    {
      loading ?
        <PreLoader height='calc(100vh - 200px)' />
        :
        <>
          <Grid item xs={4} height='calc(100vh - 200px)' >
            <VouchersBeneficiaryTable
              beneficiaryList={beneficiaryList}
              handleSelectBeneficiary={handleSelectBeneficiary}
              selectedBeneficiary={selectedBeneficiary}
              setFilters={setFilters}
            />
          </Grid>
          <Grid item xs={8} height='calc(100vh - 200px)' >
            <VouchersBalanceTable data={filteredData} />
          </Grid>
        </>
    }
  </>
}
