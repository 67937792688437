import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material"
import BundlesTable from "./BundlesTable"
import BundlesForm from "./BundlesForm"
import { NewEditButtons } from "../../assets/NewEditButtons"
import { LookupTablesContext } from "../../../context/LookupTablesContext"
import axios from 'axios'
// import AssociatedBillingsTable from "./AssociatedBillingsTable"
import PreLoader from "../../PreLoader"
// import { quickSort } from "../../Utils"
import { useSessionStorageFilters } from "../../../hooks/useLocalStorageFilters"
import { AutocompleteOption } from "../../../utilities/generalPurposeTypes"
import { toast } from "react-toastify"
import { toastError, toastSuccess } from "../../assets/customToasts"
import { RightSidePanel } from "wed-components"
import { DefaultBillingsTableShort } from "./DefaultBillingsTable"
import { ViewBundle } from "./types"

export type associatedDefaultBillingsType = {
  fk_id_affiliation: number,
  affiliation_name: string,
  fk_id_default_billing: number,
  default_billing_name: string,
}

type Filters = {
  affiliation: AutocompleteOption | null,
  voucherType: 'all' | number
}

// export type SortBundles = {
//   column: keyof ViweBundle,
//   direction: 'asc' | 'desc'
// }

export type Panel = {
  show: boolean
  id?: number
}

export default function Bundles() {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // Table
  const [data, setData] = useState<ViewBundle[]>([])
  // Form
  const [loading, setLoading] = useState(true)
  const [panel, setPanel] = useState<Panel>({
    show: false
  })
  // Filters
  const [filters, setFilters] = useSessionStorageFilters<Filters>({
    affiliation: null,
    voucherType: 'all'
  }, '_bundles')
  // const [sort, setSort] = useState<SortBundles>({
  //   column: 'id',
  //   direction: 'desc'
  // })
  // Memos
  const affiliationOptions = useMemo<AutocompleteOption[]>(() => {
    return lookupTables.affiliation.map(aff => ({ label: `${aff.tag} - ${aff.option}`, id: aff.id }))
  }, [lookupTables])
  const selectedBundle = useMemo<ViewBundle | null>(() => data.find((_bundle) => _bundle.id === panel.id) || null, [data, panel])

  const refreshTable = useCallback(async () => {
    setLoading(true)

    const _toast = toast('Loading bundles', { toastId: 'toast-bundles-refresh-table', isLoading: true })

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/bundles`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setData(data)

      setLoading(false)

      toastSuccess(_toast, 'Bundles loaded.')
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not load bundles.')
    }
  }, [])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  const filterByAffiliation = useCallback((row: ViewBundle) => {
    if (!filters.affiliation) return true
    return row.default_billings.some(defBill => defBill.fk_id_affiliation === filters.affiliation?.id)
  }, [filters.affiliation])

  const filterByVoucherType = useCallback((row: ViewBundle) => {
    if (filters.voucherType === 'all') return true
    return row.fk_id_voucher_type === filters.voucherType
  }, [filters.voucherType])

  const filteredAndSortedData = useMemo(() => {
    return data.filter(filterByAffiliation).filter(filterByVoucherType)
    // quickSort(data.filter(filterByAffiliation).filter(filterByVoucherType), sort.column, sort.direction)
  }, [data, filterByAffiliation, filterByVoucherType
    //  sort
  ])

  const handleDelete = useCallback(async () => {
    const _toast = toast('Deleting bundle...', { toastId: 'toast-bundle-hadnle-delete' })

    try {
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/bundles/${panel.id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      setPanel({ show: false })

      toastSuccess(_toast, 'Bundle deleted.')

      refreshTable()
    } catch (err) {
      console.log(err)
      toastError(_toast, 'Could not delete bundle.')
    }
  }, [panel, refreshTable])

  return <>
    {
      loading ?
        <PreLoader />
        :
        <>
          {/* Panel */}
          {
            <RightSidePanel state={panel.show} close={() => setPanel({ show: false })} title={selectedBundle ? 'Edit a bundle' : 'New bundle'} maxWidth="lg">
              {
                panel.show ?
                  <BundlesForm
                    selectedBundle={selectedBundle}
                    onClose={() => setPanel({ show: false })}
                    refreshTable={refreshTable}
                  />
                  :
                  <></>
              }
            </RightSidePanel>
          }
          {/* Buttons */}
          <NewEditButtons
            _new={{ label: 'New Bundle', onClick: () => setPanel({ show: true }) }}
            _edit={{ disabled: !panel.id, onClick: () => setPanel((_panel) => ({ ..._panel, show: true })) }}
            _delete={{ disabled: !panel.id, onClick: handleDelete }}
          />
          {/* Spacer */}
          <Grid item xs={4.5} />
          {/* Filters */}
          <Grid item xs={2}>
            <Autocomplete
              id='search-filter-affiliation'
              renderInput={props => <TextField {...props} label='Affiliation' />}
              options={affiliationOptions}
              value={filters.affiliation}
              onChange={(e, newValue) => setFilters(prev => ({ ...prev, affiliation: newValue }))}
            />
          </Grid>
          <Grid item xs={1.5}>
            <TextField id='search-filter-voucher-type' label='Voucher Type' value={filters.voucherType} onChange={(e) => setFilters({ ...filters, voucherType: e.target.value === 'all' ? 'all' : Number(e.target.value) })} select>
              {
                [
                  <MenuItem key={-1} value={'all'}>All</MenuItem>,
                  ...lookupTables.voucherType.map((option, index) => (
                    <MenuItem key={index} value={option.id}>{option.option}</MenuItem>
                  ))
                ]
              }
            </TextField>
          </Grid>
          <Grid item xs={8.5} maxHeight={'75vh'}>
            <BundlesTable
              data={filteredAndSortedData}
              // sort={sort}
              // setSort={setSort}
              panel={panel}
              setPanel={setPanel}
            />
          </Grid>
          <Grid item xs={3.5} maxHeight={'60vh'}>
            <DefaultBillingsTableShort
              data={selectedBundle?.default_billings || null}
            />
          </Grid>
        </>
    }
  </>
}
