import { toast } from 'react-toastify'
import { Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography } from '@mui/material'
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import { ContentCopy, Download, FileUpload } from '@mui/icons-material'
import { EmailVariables, Recipient } from './types'
import { ViewFaculty } from '../faculty/FacultySearch'
import { ViewStudent } from '../students/StudentsSearch'
import { utils, read, writeFile } from 'xlsx'
import { Info } from '@mui/icons-material'
import { useState } from 'react'
import { ExtraVariablesMap } from './SendEmail'

type VariableTableProps = {
  variables: EmailVariables[]
  data: ViewFaculty[] | ViewStudent[]
  recipientsType: Recipient
  extraVariables: object[]
  setExtraVariables: React.Dispatch<React.SetStateAction<object[]>>
  extraVariablesMap: ExtraVariablesMap[]
  receivers: number[]
}

export default function VariablesTable({ setExtraVariables, recipientsType, data, receivers, variables, extraVariablesMap }: VariableTableProps) {
  const columnWidth = [0.75, 2, 3, 4.3]
  const [openPopup, setOpenPopup] = useState<boolean>(false)

  const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)

    toast('Variable copied to clipboard')
  }

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (!file) return

    const reader = new FileReader()

    reader.onload = (event) => {
      const binaryStr = event.target?.result
      const workbook = read(binaryStr, { type: 'binary' })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]

      const jsonData = utils.sheet_to_json(worksheet, { defval: '' })

      setExtraVariables(jsonData as any)
    }

    reader.readAsArrayBuffer(file)
  }

  const handleDownload = () => {
    let _data: ViewStudent[] | ViewFaculty[]
    let sheet_data: object[]

    if (recipientsType !== 'faculty') {
      _data = (data as ViewStudent[]).filter((item) => receivers.includes(item.id))

      sheet_data = _data.map((item) => ({ id: item.id, student_full_name: item.student_full_name }))
    }
    else {
      _data = (data as ViewFaculty[]).filter((item) => receivers.includes(item.id))

      sheet_data = _data.map((item) => ({ id: item.id, faculty_full_name: item.full_name }))
    }

    const worksheet = utils.json_to_sheet(sheet_data)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, 'additional_variables')

    writeFile(workbook, `additional_data_for_${recipientsType}.xlsx`)
  }

  return <>
    <Dialog open={openPopup}>
      <DialogContent sx={{ padding: 2 }}>
        <Typography sx={{ textAlign: 'center' }} variant='h6'>Set up customized variables.</Typography>
        <Typography variant='body1'>Once you defined your receivers list on Filters tab, the button "Download Model" will be available.</Typography>
        <Typography variant='body1'>You can download a excel example with id and name of your receivers.</Typography>
        <Typography variant='body1'>Add extra columns on that sheet, fill and upload it again, then, you can use them in your email.</Typography>
        <Typography variant='body1'>Do not let any column or cell empty.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenPopup(false)}>OK</Button>
      </DialogActions>
    </Dialog>
    <Grid item xs={2}>
      <IconButton
        onClick={() => setOpenPopup(true)}
        sx={{ background: 'none' }}
      >
        <Info fontSize='medium' />
      </IconButton>
    </Grid>
    {/* Spacer */}
    <Grid item xs={2} />
    <Grid item xs={4}>
      <Button
        sx={{ background: '#386B45', color: '#fff', height: '40px' }}
        variant="contained" component="span"
        startIcon={<Download />}
        onClick={() => handleDownload()}
        disabled={!receivers.length}
      >
        Download model
      </Button>
    </Grid>
    <Grid item xs={4}>
      <Button
        id='button-file-upload'
        startIcon={<FileUpload />}
        component="label"
        sx={{ background: '#386B45', color: '#fff' }}
      >
        Upload file
        <input
          hidden
          type="file"
          accept='.xlsx, .xls'
          onChange={handleUpload}
        />
      </Button>
    </Grid>
    <Grid item xs={12} maxHeight={'73vh'}>
      <Table>
        <HeaderRow>
          <Cell width={columnWidth[1]}>
            <p>Name</p>
          </Cell>
          <Cell width={columnWidth[3]}>
            <p>Variable</p>
          </Cell>
        </HeaderRow>
        <BodyContainer>
          {
            variables?.map((_: EmailVariables, index) => (
              <BodyRow key={index}>
                <Cell width={columnWidth[1]}>
                  <p>{_.name}</p>
                </Cell>
                <Cell width={columnWidth[3]}>
                  <p>{_.variable}</p>
                </Cell>
                <Cell>
                  <IconButton onClick={() => copyToClipboard(_.variable)}>
                    <ContentCopy />
                  </IconButton>
                </Cell>
              </BodyRow>
            ))
          }
          {
            extraVariablesMap?.map((_, index) => (
              <BodyRow key={index}>
                <Cell width={columnWidth[1]}>
                  <p>{_.name}</p>
                </Cell>
                <Cell width={columnWidth[3]}>
                  <p>{_.placeholder}</p>
                </Cell>
                <Cell>
                  <IconButton onClick={() => copyToClipboard(_.placeholder)}>
                    <ContentCopy />
                  </IconButton>
                </Cell>
              </BodyRow>
            ))
          }
        </BodyContainer>
      </Table>

    </Grid>
  </>
}
