import { Container, Grid, Tab, Tabs } from '@mui/material'
import Header from '../components/header/Header'
import { useState } from 'react'
import SendEmail from '../components/emails/SendEmail'
import EmailSchedule from '../components/emails/EmailSchedule'

export default function EmailsView() {
  const [tab, setTab] = useState('send-email')

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue)
  }

  const renderComponent = () => {
    switch (tab) {
      case 'send-email':
        return <SendEmail />
      case 'email-schedule':
        return <EmailSchedule />
      default:
        return <>Component Not Found</>
    }
  }

  return (
    <Container maxWidth='xl'>
      <br />
      <Grid container spacing={1}>
        <Header title={'Emails'} name={''} />
        <Grid item xs={12}>
          <Tabs value={tab} onChange={handleTabChange}>
            <Tab id='email-tab-send-email' label='Send Email' value='send-email' />
            <Tab id='email-tab-email-schedule' label='Email Schedule' value='email-schedule' />
          </Tabs>
        </Grid>
        {renderComponent()}
      </Grid>
    </Container>
  )
}


