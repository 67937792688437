import axios from "axios"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { LookupTablesContext } from "../../../context/LookupTablesContext"
import { useSessionStorageFilters } from "../../../hooks/useLocalStorageFilters"
import { toastError, toastSuccess } from "../../assets/customToasts"
import PreLoader from "../../PreLoader"
import { RightSidePanel } from "wed-components"
import DefaultBillingsForm, { DefaultBillingForm, formInitialState } from "./DefaultBillingsForm"
import { AutocompleteOption } from "../../../utilities/generalPurposeTypes"
import { Autocomplete, Button, Grid, MenuItem, TextField } from "@mui/material"
import BundleExpectedStudentsModal from "../billings/BundleExpectedStudentsModal"
import { ControlPointDuplicate } from "@mui/icons-material"
import { NewEditButtons } from "../../assets/NewEditButtons"
import SuggestedDefaultBillingsTable from "../billings/SuggestedDefaultBillingsTable"
import { DefaultBillingsTable } from "./DefaultBillingsTable"
import { ExpectedVouchersPerStudent, SuggestedBundles } from "./BundlesForm"
import { quickSort } from "../../Utils"

export type ViewDefaultBilling = {
  affiliation_name: string
  commercial_conditions: string | null
  currency_name: string
  customer_type_name: string
  default_billing_name: string
  default_value: number
  fk_id_affiliation: number
  fk_id_currency: number
  fk_id_customer_type: number
  fk_id_payee: number
  fk_id_payment_method: number
  fk_id_program: number
  fk_id_service: number
  id: number
  max_installments: number
  payee_name: string
  payment_method_name: string
  program_name: string
  program_tag: string
  service_name: string
  sign: string
}

export type Form = {
  show: boolean,
  id?: number,
  duplicating?: boolean
}

type SuggestionTableFilters = {
  affiliation: 'all' | number,
  year: 'all' | number,
}

export type SortDefaultBillings = {
  column: keyof ViewDefaultBilling,
  direction: 'asc' | 'desc'
}

export default function DefaultBillings() {
  // General
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingSuggestedBundles, setLoadingSuggestedBundles] = useState(false)
  const [form, setForm] = useState<Form>({
    show: false
  })
  const [showExpectedStudentsModal, setShowExpectedStudentsModal] = useState(false)
  const [disableDelete, setDisableDelete] = useState<boolean>(false)
  // Data
  const [data, setData] = useState<ViewDefaultBilling[]>([])
  const [suggestedBundles, setSuggestedBundles] = useState<SuggestedBundles[]>([])
  const [studentsExpectedVouchers, setStudentExpectedVouchers] = useState<ExpectedVouchersPerStudent[]>([])
  const [formTemplate, setFormTemplate] = useState<DefaultBillingForm>(formInitialState)
  // Data selection
  const [selectedBundleRows, setSelectedBundleRows] = useState<number[]>([])
  const [selectedBundleDetails, setSelectedBundleDetails] = useState<SuggestedBundles | null>(null)
  // Hooks
  const { lookupTables, refreshLookupTables } = useContext(LookupTablesContext)
  // Filters and Sort
  const [filters, setFilters] = useSessionStorageFilters<SuggestionTableFilters>({
    affiliation: 'all',
    year: 'all'
  }, '_suggestedDefaultBillings')
  const [sort, setSort] = useState<SortDefaultBillings>({
    column: 'id',
    direction: 'desc'
  })
  // Memos
  const affiliationOptions = useMemo<AutocompleteOption[]>(() => {
    return lookupTables.affiliation.map(aff => ({ label: `${aff.tag} - ${aff.option}`, id: aff.id }))
  }, [lookupTables])
  const selectedAffiliationOption = useMemo(() => {
    if (filters.affiliation === 'all') return null
    return affiliationOptions.find(affOpt => affOpt.id === filters.affiliation) || null
  }, [affiliationOptions, filters.affiliation])

  const refreshTable = useCallback(async () => {
    setLoading(true)

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/default-billings`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setData(data)
    } catch (err) {
      console.log(err)

      toast.error('Could not load Default Billings.')
    }

    setForm({ show: false })
    setLoading(false)
  }, [setData, setLoading, setForm])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  const getSuggestedBundles = useCallback(async () => {
    if (filters.affiliation === 'all') return setSuggestedBundles([])

    setLoadingSuggestedBundles(true)

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/suggested-bundles?fk_id_affiliation=${filters.affiliation}&year=${filters.year}`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data.suggested_bundles)

      setSuggestedBundles(data.suggested_bundles)
      setStudentExpectedVouchers(data.vouchers_per_student)
    } catch (err) {
      console.log(err)
      setSuggestedBundles([])
    }

    setLoadingSuggestedBundles(false)
  }, [filters, setSuggestedBundles, setStudentExpectedVouchers, setLoadingSuggestedBundles])

  useEffect(() => {
    getSuggestedBundles()
  }, [getSuggestedBundles])

  const handleSelectBundleRow = (counter: number) => {
    setSelectedBundleRows(prev => {
      if (prev.includes(counter)) return prev.filter(n => n !== counter)
      return [...prev, counter]
    })
  }

  const createFromSuggestedBundle = (type: 'enrollment' | 'tuition' | 'courseware', programID: number) => {
    let serviceID = 0
    let maxInstallments = 0
    if (type === 'enrollment') {
      serviceID = 6
      maxInstallments = 1
    } else if (type === 'tuition') {
      serviceID = 1
      maxInstallments = 12
    } else if (type === 'courseware') {
      serviceID = 2
      maxInstallments = 3
    }
    setForm({ show: true, duplicating: true, id: 0 }) // ID will be nullified by DefaultBillingsForm's useEffect
    setFormTemplate({
      ...formInitialState,
      max_installments: maxInstallments,
      fk_id_affiliation: filters.affiliation === 'all' ? 0 : filters.affiliation,
      fk_id_program: programID,
      fk_id_service: serviceID,
    })
  }

  const showBundleExpectedStudents = useCallback((suggestedBundle: SuggestedBundles) => {
    setSelectedBundleDetails(suggestedBundle)
    setShowExpectedStudentsModal(true)
  }, [setSelectedBundleDetails, setShowExpectedStudentsModal])

  const filteredStudents = useMemo(() => {
    if (!selectedBundleDetails) return []

    return studentsExpectedVouchers.filter(student => {
      return student.required_vouchers.some(voucher => voucher.fk_id_program === selectedBundleDetails.fk_id_program && voucher.quantity === selectedBundleDetails.quantity)
    })

  }, [studentsExpectedVouchers, selectedBundleDetails])

  const handleDelete = useCallback(async (id: number) => {
    setDisableDelete(true)

    const _toast = toast('Deleting default billing...', { toastId: 'toast-default-billings-handle-delete', isLoading: true })

    try {
      // SIS
      await axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/default-billings/${id}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      setForm({ show: false })

      refreshTable()

      refreshLookupTables(['defaultBilling'])

      toastSuccess(_toast, 'Default billing deleted.')
    } catch (err) {
      console.log(err)

      toastError(_toast, 'Could not delete default billing.')
    }

    setDisableDelete(false)
  }, [refreshTable, refreshLookupTables, setDisableDelete])

  // Filters
  const filterByAffiliation = useCallback((row: ViewDefaultBilling) => {
    if (filters.affiliation === 'all') return true
    return row.fk_id_affiliation === Number(filters.affiliation)
  }, [filters.affiliation])

  const filterByYear = useCallback((row: ViewDefaultBilling) => {
    if (filters.year === 'all') return true
    return row.default_billing_name.includes(`${filters.year}`)
  }, [filters.year])

  const filteredAndSortedData = useMemo(() => {
    return quickSort(data.filter(filterByAffiliation).filter(filterByYear), sort.column, sort.direction)
  }, [data, filterByAffiliation, filterByYear, sort])

  const closePanel = () => {
    setForm({ show: false });
    setFormTemplate(formInitialState)
  }

  return <>
    {
      loading ?
        <PreLoader />
        :
        <>
          <RightSidePanel
            state={form.show}
            close={closePanel}
            title={!form.id || form.duplicating ? 'New default billing' : `Updating default billing: ${form.id}`}
            maxWidth='lg'
          >
            {
              form.show ?
                <DefaultBillingsForm
                  defaultBilling={data.find((_) => _.id === form.id) || formTemplate || null}
                  refreshTable={refreshTable}
                  closePanel={closePanel}
                  duplicating={form.duplicating}
                  selectedYear={filters.year}
                />
                :
                <></>
            }
          </RightSidePanel>
          <BundleExpectedStudentsModal
            open={showExpectedStudentsModal}
            closeModal={() => setShowExpectedStudentsModal(false)}
            text={{
              affiliationAndYear: `${lookupTables.affiliation.find(aff => aff.id === filters.affiliation)?.option} - ${filters.year}`,
              programAndVoucher: `${selectedBundleDetails?.program_name} - ${selectedBundleDetails?.quantity} voucher(s)`,
              students: filteredStudents
            }}
          />
          {/* <Grid container spacing={1} marginTop='.5rem' paddingLeft='20px' paddingRight='30px' maxWidth='xl' marginX={'auto'} > */}
          {/* Filters */}
          <Grid item xs={2.5}>
            <Autocomplete
              id='filter-affiliation'
              renderInput={props => <TextField {...props} label='Affiliation' />}
              options={affiliationOptions}
              value={selectedAffiliationOption}
              onChange={(e, newValue) => setFilters(prev => ({ ...prev, affiliation: newValue ? newValue.id : 'all' }))}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
            />
          </Grid>
          <Grid item xs={1.5}>
            <TextField
              id='filter-year'
              label='Year'
              select
              value={filters.year}
              onChange={(e) => setFilters(prev => ({ ...prev, year: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
            >
              <MenuItem value={'all'} >All</MenuItem>
              <MenuItem value={2021} >2021</MenuItem>
              <MenuItem value={2022} >2022</MenuItem>
              <MenuItem value={2023} >2023</MenuItem>
              <MenuItem value={2024} >2024</MenuItem>
              <MenuItem value={2025} >2025</MenuItem>
            </TextField>
          </Grid>
          {/* Spacer */}
          <Grid item xs={2.75} />
          <NewEditButtons
            _new={{ label: 'New Default Billing', onClick: () => setForm({ show: true, duplicating: false }) }}
            _edit={{ disabled: !form.id, onClick: () => setForm(prev => ({ ...prev, show: true, duplicating: false })) }}
            _delete={{ disabled: !form.id || disableDelete, onClick: () => handleDelete(form.id!) }}
          />
          <Grid item xs={1.25}>
            <Button
              id='button-duplicate'
              startIcon={<ControlPointDuplicate />}
              color='info'
              sx={{ height: '100%', width: '100%' }}
              disabled={!form.id}
              onClick={() => setForm({ ...form, show: true, duplicating: true })}
            >
              Duplicate
            </Button>
          </Grid>

          <Grid item xs={5} maxHeight={'calc(100vh - 240px - 26px)'} >
            <p
              style={{ color: 'white', fontSize: '1.2rem', marginTop: '0px', marginBottom: '10px' }}
            >
              {
                filters.affiliation === 'all' || filters.year === 'all' ?
                  'Select an affiliation and a year'
                  :
                  `Suggested default billings for ${lookupTables.affiliation.find(aff => aff.id === filters.affiliation)?.option} - ${filters.year}`
              }
            </p>
            <SuggestedDefaultBillingsTable
              suggestedBundles={suggestedBundles}
              loadingSuggestedBundles={loadingSuggestedBundles}
              createFromSuggestedBundle={createFromSuggestedBundle}
              defaultBillings={filteredAndSortedData}
              selectedBundleRows={selectedBundleRows}
              handleSelectBundleRow={handleSelectBundleRow}
              showBundleExpectedStudents={showBundleExpectedStudents}
            />
          </Grid>
          <Grid item xs={7} maxHeight={'calc(100vh - 240px)'} >
            <DefaultBillingsTable
              data={filteredAndSortedData}
              form={form}
              setForm={setForm}
              sort={sort}
              setSort={setSort}
            />
          </Grid>
          {/* </Grid> */}
        </>
    }
  </>
}
