import styles from './BillingsTable.module.scss'
import Cell from './Cell'
import HeaderCell from './HeaderCell'
import HeaderLeft from './HeaderLeft'
import { Modal, Filters } from '../Billings'
import { DateTime } from 'luxon'
import { Fragment, useCallback, useMemo, useRef, useState } from 'react'
import { formatNumberToMoney } from '../../../Utils'
import { Button, Dialog, DialogActions, DialogContent, Tooltip, Typography } from '@mui/material'
import { Comment, ExpandLess, ExpandMore, FilterNone } from '@mui/icons-material'
import axios from 'axios'
import { toast } from 'react-toastify'
import { toastSuccess } from '../../../assets/customToasts'
import { ViewBilling, ViewCashflow } from '../types'

type Props = {
  billings: ViewBilling[]
  currencySign: string
  filters: Filters
  monthsToRender: DateTime[]
  selectedBilling: number | null
  setShowModal: React.Dispatch<React.SetStateAction<Modal>>
  setSelectedBilling: React.Dispatch<React.SetStateAction<number | null>>
}

type GroupedBillings = {
  beneficiary_affiliation_name: string
  beneficiary_affiliation_tag: string
  beneficiary_customer_dependence_id: number
  beneficiary_customer_name: string
  beneficiary_fk_id_affiliation: number
  beneficiary_fk_id_grade: number
  beneficiary_fk_id_student_status_type: number
  beneficiary_grade_name: string
  beneficiary_student_status_type_name: string
  billings: ViewBilling[]
  fk_id_customer_beneficiary: number
}

type ModalConfirmPayment = {
  show: boolean
  month?: DateTime
}

export default function BillingsTable({ billings, currencySign, filters, monthsToRender, selectedBilling, setShowModal, setSelectedBilling }: Props) {
  // General
  const columnWidth = [.5, .6, 3.5, 1, 2.5, 1.1]
  const scrollableWidth = 1.25
  const [expandedRows, setExpandedRows] = useState<number[]>([])
  const [confirmPaymentsModal, setConfirmPaymentsModal] = useState<ModalConfirmPayment>({
    show: false
  })
  // useRefs
  const headerRightRef = useRef<HTMLDivElement | null>(null)
  const fixedContainerRef = useRef<HTMLDivElement | null>(null)
  const scrollableContainerRef = useRef<HTMLDivElement | null>(null)
  // useMemos
  const customers = useMemo(() => {
    const groupeBillings = billings.reduce((prev: GroupedBillings[], curr) => {
      const { fk_id_customer_beneficiary, beneficiary_customer_dependence_id, beneficiary_customer_name, beneficiary_fk_id_affiliation, beneficiary_affiliation_name, beneficiary_affiliation_tag, beneficiary_fk_id_grade, beneficiary_grade_name, beneficiary_fk_id_student_status_type, beneficiary_student_status_type_name } = curr

      // Trying to find if an object for that student already exists
      const index = prev.findIndex((_) => _.fk_id_customer_beneficiary === curr.fk_id_customer_beneficiary)

      if (index === -1) {
        prev.push({
          fk_id_customer_beneficiary,
          beneficiary_fk_id_affiliation,
          beneficiary_affiliation_name,
          beneficiary_affiliation_tag,
          beneficiary_customer_dependence_id,
          beneficiary_customer_name,
          beneficiary_fk_id_grade,
          beneficiary_grade_name,
          beneficiary_fk_id_student_status_type,
          beneficiary_student_status_type_name,
          billings: [curr]
        })
      }
      else {
        prev[index].billings.push(curr)
      }

      return prev
    }, [])

    return groupeBillings
  }, [billings])

  const handleHeaderRightScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (scrollableContainerRef.current) scrollableContainerRef.current.scrollTo({ left: e.currentTarget.scrollLeft })
  }

  const handleScrollableContainerScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (headerRightRef.current) headerRightRef.current.scrollTo({ left: e.currentTarget.scrollLeft })
    if (fixedContainerRef.current) fixedContainerRef.current.scrollTo({ top: e.currentTarget.scrollTop })
  }

  const handleFixedContainerScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (scrollableContainerRef.current) scrollableContainerRef.current.scrollTo({ top: e.currentTarget.scrollTop })
  }

  function defineStatusColor(studentStatusTypeID: number) {
    switch (studentStatusTypeID) {
      case 1:
        return styles.bgGreen
      case 2:
        return styles.bgRed
      default:
        return styles.bgBlue
    }
  }

  const handleExpandRow = (id: number) => {
    setExpandedRows((rows) => {
      const _rows = [...rows]

      if (_rows.includes(id)) {
        return _rows.filter((_id) => _id !== id)
      }

      _rows.push(id)

      return _rows
    })
  }

  // Render cashflow
  const getMonthCashFlow = (cashFlow: ViewCashflow[], month: DateTime) => {
    // Using filter because we can have more than one installment in a month
    return cashFlow.filter((_) => {
      const dueDate = DateTime.fromISO(_.due_date, { zone: 'UTC' })

      if (dueDate.month === month.month && dueDate.year === month.year) return true

      return false
    })
  }

  const getCashflowTotalBundle = (billings: ViewBilling[], month: DateTime) => {
    // Using filter because we can have more than one installment in a month
    return billings
      .map((_billing) => _billing.cashflow
        .filter((_) => DateTime.fromISO(_.due_date, { zone: 'UTC' }).month === month.month && DateTime.fromISO(_.due_date, { zone: 'UTC' }).year === month.year)
        .reduce((prev, curr) => prev += curr.due_value, 0))
      .reduce((prev, curr) => prev += curr)
  }

  const monthlyTotal = useMemo(() => {
    const totalPerMonth: number[] = []

    for (const _month of monthsToRender) {
      const total = customers.map((_customer) => {
        return _customer.billings.map((_billing) => {
          return getMonthCashFlow(_billing.cashflow, _month).reduce((prev, curr) => prev += curr.due_value, 0)
        })
          .reduce((prev, curr) => prev += curr, 0)
      })
        .reduce((prev, curr) => prev += curr, 0)

      totalPerMonth.push(total)
    }

    return totalPerMonth
  }, [customers, monthsToRender])

  // Confirm Payments
  const confirmPayments = useCallback(async () => {
    const _toast = toast(`Confirming payments for ${confirmPaymentsModal.month?.toFormat('MMM yy')}`, { toastId: 'toast-confirm-payments', isLoading: true })

    try {
      const cashFlow = billings
        .map((_billing) => _billing.cashflow.filter((_cashFlow) => DateTime.fromISO(_cashFlow.due_date).month === confirmPaymentsModal.month!.month && DateTime.fromISO(_cashFlow.due_date).year === confirmPaymentsModal.month!.year))
        .reduce((prev: ViewCashflow[], curr) => {
          prev.push(...curr)

          return prev
        }, [])
        .map((_cashFlow) => ({ ..._cashFlow, paid_value: _cashFlow.due_value }))

      await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/billings/0`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: cashFlow
      })

      toastSuccess(_toast, 'Payments confirmed.')
    } catch (err) {
      console.log(err)
      toastSuccess(_toast, 'Could not save paid values.')
    }
  }, [billings, confirmPaymentsModal])

  return <div id='billings-table' className={styles.tableContainer}>
    {/* Confirm payments modal */}
    <Dialog id='confirmation-modal' open={confirmPaymentsModal.show} onClose={() => setConfirmPaymentsModal({ show: false })}>
      <DialogContent>
        <Typography>
          This will update all paid values for {confirmPaymentsModal.month?.toFormat('MMM yy')}. Are you sure you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button id='button-cancel' onClick={() => setConfirmPaymentsModal({ show: false })}>
          Cancel
        </Button>
        <Button id='button-confirm' onClick={confirmPayments}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
    {/* Fixed columns */}
    <div id='fixed-container' ref={fixedContainerRef} className={styles.fixedContainer} onScroll={handleFixedContainerScroll}>
      {/* Header */}
      <HeaderLeft>
        <HeaderCell width={columnWidth[1]}>
          ID
        </HeaderCell>
        <HeaderCell width={columnWidth[2]}>
          Beneficiary/Payer
        </HeaderCell>
        <HeaderCell width={columnWidth[3]}>
          Grade
        </HeaderCell>
        <HeaderCell width={columnWidth[4]}>
          Bundle/Def. Bill.
        </HeaderCell>
        <HeaderCell width={columnWidth[5]}>
          Status
        </HeaderCell>
      </HeaderLeft>
      {/* Rows */}
      {
        customers.map((_customer, index) => {
          const isBundle = _customer.billings.length > 1
          const isEven = isBundle ? false : index % 2 === 0

          return <Fragment key={index}>
            <div
              className={`${styles.row} ${!isBundle && selectedBilling === _customer.billings[0].id ? styles.selectedRow : ''}`}
              onClick={isBundle ? () => handleExpandRow(_customer.fk_id_customer_beneficiary) : () => setSelectedBilling(_customer.billings[0].id)}
              onDoubleClick={() => setShowModal({ show: true, billings: _customer.billings.map((_billing) => _billing.id) })}
            >
              <Cell isEven={isEven} isBundle={isBundle} isSelected={false} width={columnWidth[1]}>
                {
                  isBundle ?
                    expandedRows.includes(_customer.fk_id_customer_beneficiary) ? <ExpandLess sx={{ color: 'white', '&:hover': { cursor: 'pointer' } }} /> : <ExpandMore sx={{ color: 'white', '&:hover': { cursor: 'pointer' } }} />
                    :
                    _customer.billings[0].id
                }
              </Cell>
              <Cell isEven={isEven} isBundle={isBundle} isSelected={false} width={columnWidth[2]}>
                {_customer.beneficiary_fk_id_affiliation !== filters.affiliation?.id ? <span className={`${styles.statusCell} ${styles.bgYellow}`}>{_customer.beneficiary_affiliation_tag}</span> : null}{_customer.beneficiary_customer_name}
              </Cell>
              <Cell isEven={isEven} isBundle={isBundle} isSelected={false} width={columnWidth[3]}>
                {_customer.beneficiary_grade_name}
              </Cell>
              <Cell isEven={isEven} isBundle={isBundle} isSelected={false} width={columnWidth[4]}>
                {
                  isBundle ?
                    null
                    :
                    (_customer.billings[0].default_billing_name || _customer.billings[0].service_name)
                }
              </Cell>
              <Cell isEven={isEven} isBundle={isBundle} isSelected={false} width={columnWidth[5]}>
                <span className={`${styles.statusCell} ${defineStatusColor(_customer.beneficiary_fk_id_student_status_type)}`}>
                  {_customer.beneficiary_student_status_type_name}
                </span>
              </Cell>
            </div>
            {/* Expansible rows */}
            {
              expandedRows.includes(_customer.fk_id_customer_beneficiary) ?
                _customer.billings.map((_billing, index) => <div
                  key={index}
                  className={`${styles.row} ${selectedBilling === _billing.id ? styles.selectedRow : ''}`}
                  onClick={() => setSelectedBilling(_billing.id)}
                  onDoubleClick={() => setShowModal({ show: true, billings: _customer.billings.map((_billing) => _billing.id) })}
                >
                  <Cell isEven={true} isBundle={true} isSelected={false} width={columnWidth[1]}>
                    {_billing.id}
                  </Cell>
                  <Cell isEven={true} isBundle={true} isSelected={false} width={columnWidth[2]}>
                    {null}
                  </Cell>
                  <Cell isEven={true} isBundle={true} isSelected={false} width={columnWidth[3]}>
                    {null}
                  </Cell>
                  <Cell isEven={true} isBundle={true} isSelected={false} width={columnWidth[4]}>
                    {_billing.default_billing_name || _billing.service_name}
                  </Cell>
                  <Cell isEven={true} isBundle={true} isSelected={false} width={columnWidth[5]}>
                    {null}
                  </Cell>
                </div>)
                :
                null
            }
          </Fragment>
        })
      }
      {/* Total */}
      <div id='fixed-row-total' className={`${styles.row} ${styles.total}`}>
        <div className={`${styles.cell} ${styles.headerCell}`} style={{ width: '100%', justifyContent: 'space-between' }}>
          <HeaderCell isNumber={true} width={2}>
            Total: {customers.length} students
          </HeaderCell>
        </div>
      </div>
    </div>
    {/* Scrollable columns */}
    <div id='scrollable-container' className={styles.scrollContainer} ref={scrollableContainerRef} onScroll={handleScrollableContainerScroll}>
      {/* Header */}
      <div
        id='fixed-header-right'
        className={styles.headerRow}
        ref={headerRightRef}
        onScroll={handleHeaderRightScroll}
      >
        <HeaderCell key={-2} width={scrollableWidth}>
          Line Total
        </HeaderCell>
        <HeaderCell key={-1} width={scrollableWidth}>
          # Payments
        </HeaderCell>
        {
          monthsToRender.map((_month, index) => <HeaderCell key={index} width={scrollableWidth} isMonthHeader={true} onClick={() => setConfirmPaymentsModal({ show: true, month: _month })}>
            {_month.toFormat('MMM yy')}
          </HeaderCell>
          )
        }
      </div>
      {/* Rows */}
      {
        customers.map((_customer, index) => {
          const isBundle = _customer.billings.length > 1
          const isEven = isBundle ? false : index % 2 === 0

          return <Fragment key={index}>
            <div
              className={`${styles.row} ${!isBundle && selectedBilling === _customer.billings[0].id ? styles.selectedRow : ''}`}
              onClick={isBundle ? () => handleExpandRow(_customer.fk_id_customer_beneficiary) : () => setSelectedBilling(_customer.billings[0].id)}
              onDoubleClick={() => setShowModal({ show: true, billings: _customer.billings.map((_billing) => _billing.id) })}
            >
              <Cell key={-2} isEven={isEven} isNumber={true} isBundle={isBundle} isSelected={false} width={scrollableWidth}>
                {
                  isBundle ?
                    `${currencySign} ${formatNumberToMoney(_customer.billings.map((_billing) => _billing.cashflow.reduce((prev, curr) => prev += curr.due_value, 0)).reduce((prev, curr) => prev += curr, 0))}`
                    :
                    `${currencySign} ${formatNumberToMoney(_customer.billings[0].cashflow.reduce((prev, curr) => prev += curr.due_value, 0))}`
                }
              </Cell>
              <Cell key={-1} isEven={isEven} isNumber={true} isBundle={isBundle} isSelected={false} width={scrollableWidth}>
                {
                  isBundle ?
                    _customer.billings[0].cashflow.length
                    :
                    _customer.billings.map((_billing) => _billing.cashflow.length).reduce((prev, curr) => prev += curr, 0)
                }
              </Cell>
              {
                isBundle ?
                  monthsToRender.map((_month, index) => {
                    const total = getCashflowTotalBundle(_customer.billings, _month)

                    return <Cell key={index} isBundle={isBundle} isEven={isEven} isNumber={Boolean(total)} isSelected={false} width={scrollableWidth}>
                      {
                        total ?
                          `${currencySign} ${formatNumberToMoney(total)}`
                          :
                          '-'
                      }
                    </Cell>
                  })
                  :
                  monthsToRender.map((_month, index) => {
                    const cashFlow = getMonthCashFlow(_customer.billings[0].cashflow, _month)

                    return <Cell key={index} isEven={index % 2 === 0} isNumber={true} isSelected={false} width={scrollableWidth}>
                      {
                        cashFlow.length > 1 ?
                          <Tooltip title={`${cashFlow.length} installments`}>
                            <FilterNone sx={{ width: 18, height: 18 }} />
                          </Tooltip>
                          :
                          null
                      }
                      {
                        cashFlow.filter((_) => _.comment).map((_, index) => <Tooltip key={index} title={_.comment}>
                          <Comment sx={{ width: 18, height: 18 }} />
                        </Tooltip>)
                      }
                      {
                        cashFlow.length ?
                          `${currencySign} ${formatNumberToMoney(cashFlow.reduce((prev, curr) => prev += curr.due_value, 0))}`
                          :
                          '-'
                      }
                    </Cell>
                  })
              }
            </div>
            {/* Expansible Rows */}
            {
              expandedRows.includes(_customer.fk_id_customer_beneficiary) ?
                _customer.billings.map((_billing, index) => <div
                  key={index}
                  className={`${styles.row} ${selectedBilling === _billing.id ? styles.selectedRow : ''}`}
                  onClick={() => setSelectedBilling(_billing.id)}
                  onDoubleClick={() => setShowModal({ show: true, billings: _customer.billings.map((_billing) => _billing.id) })}
                >
                  <Cell key={-2} isEven={true} isNumber={true} isBundle={true} isSelected={false} width={scrollableWidth}>
                    {_billing.sign} {formatNumberToMoney(_billing.cashflow.reduce((prev, curr) => prev += curr.due_value, 0))}
                  </Cell>
                  <Cell key={-1} isEven={true} isNumber={true} isBundle={true} isSelected={false} width={scrollableWidth}>
                    {_billing.cashflow.length}
                  </Cell>
                  {
                    monthsToRender.map((_month, index) => {
                      const cashFlow = getMonthCashFlow(_billing.cashflow, _month)

                      return <Cell key={index} isBundle={true} isEven={true} isNumber={true} isSelected={false} width={scrollableWidth}>
                        {
                          cashFlow.length > 1 ?
                            <Tooltip title={`${cashFlow.length} installments`}>
                              <FilterNone sx={{ width: 18, height: 18 }} />
                            </Tooltip>
                            :
                            null
                        }
                        {
                          cashFlow.filter((_) => _.comment).map((_, index) => <Tooltip key={index} title={_.comment}>
                            <Comment sx={{ width: 18, height: 18 }} />
                          </Tooltip>)
                        }
                        {
                          cashFlow.length ?
                            `${currencySign} ${formatNumberToMoney(cashFlow.reduce((prev, curr) => prev += curr.due_value, 0))}`
                            :
                            '-'
                        }
                      </Cell>
                    })
                  }
                </div>)
                :
                null
            }
          </Fragment>
        })
      }
      {/* Total */}
      <div id='scroll-row-total' className={`${styles.row}`}>
        <HeaderCell key={-2} isNumber={true} width={scrollableWidth}>
          {currencySign} {formatNumberToMoney(monthlyTotal.reduce((prev, curr) => prev += curr, 0))}
        </HeaderCell>
        <HeaderCell key={-1} isNumber={true} width={scrollableWidth}>
          {customers.map((_customer) => _customer.billings.map((_billing) => _billing.cashflow.length).reduce((prev, curr) => prev += curr)).reduce((prev, curr) => prev += curr, 0)}
        </HeaderCell>
        {
          monthlyTotal.map((_total, index) => <HeaderCell key={index} isNumber={true} width={scrollableWidth}>
            {currencySign} {formatNumberToMoney(_total)}
          </HeaderCell>
          )
        }
      </div>
    </div>
  </div>
}