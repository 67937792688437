import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList, TextField } from '@mui/material'
import { CustomerFilters } from './AssignBundles'
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material'
import { useContext } from 'react'
import { LookupTablesContext } from '../../../context/LookupTablesContext'

type Props = {
  customerFilters: CustomerFilters
  menuAnchor: HTMLElement | null
  setCustomerFilters: React.Dispatch<React.SetStateAction<CustomerFilters>>
  setMenuAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

export default function AssignBundlesFilters({ customerFilters, menuAnchor, setCustomerFilters, setMenuAnchor }: Props) {
  const { lookupTables } = useContext(LookupTablesContext)

  return <Menu
    open={Boolean(menuAnchor)}
    anchorEl={menuAnchor}
    onClose={() => setMenuAnchor(null)}
  >
    <MenuList>
      <MenuItem>
        <TextField
          id='bundles-filter-status'
          label='Status'
          value={customerFilters.status}
          onChange={(e) => setCustomerFilters((filters) => ({ ...filters, status: e.target.value }))}
          select
        >
          {
            [
              <MenuItem key={-2} value={'all'}>All</MenuItem>,
              <MenuItem key={-1} value={'active'}>Active</MenuItem>,
              ...lookupTables.studentStatusType.map((_status, index) => <MenuItem key={index} id={`status-option-${_status.id}`} value={_status.id}>{_status.option}</MenuItem>)
            ]
          }
        </TextField>
      </MenuItem>

      <MenuItem onClick={() => setCustomerFilters((filters) => ({ ...filters, hideWithBundle: !filters.hideWithBundle }))}>
        <ListItemIcon>
          {customerFilters.hideWithBundle ? <CheckBox /> : <CheckBoxOutlineBlank />}
        </ListItemIcon>
        <ListItemText>
          Hide students with selected bundle
        </ListItemText>
      </MenuItem>

      <MenuItem onClick={() => setCustomerFilters((filters) => ({ ...filters, hideAssignedEqualExpected: !filters.hideAssignedEqualExpected }))}>
        <ListItemIcon>
          {customerFilters.hideAssignedEqualExpected ? <CheckBox /> : <CheckBoxOutlineBlank />}
        </ListItemIcon>
        <ListItemText>
          Hide assigned = expected
        </ListItemText>
      </MenuItem>
    </MenuList>
  </Menu>
}
