import { useMemo, useState, useContext, useEffect, useCallback, Dispatch, SetStateAction } from 'react'
import { Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { Save } from '@mui/icons-material'
import { DateTime } from 'luxon'

import { customerWithServices, paymentRequest } from './Payments'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import styles from './Payments.module.css'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import ServiceInfoGrid from './ServiceInfoGrid'
import axios from 'axios'
import { FeedbackContext } from '../../../context/FeedbackContext'
import { useMsal } from '@azure/msal-react'

type ProvidedServicesFormProps = {
  selectedRequest: null | paymentRequest,
  setShowForm: Dispatch<SetStateAction<boolean>>,
  refreshTable: () => Promise<void>,
  setSelectedRequest: Dispatch<SetStateAction<null | paymentRequest>>,
  customer: customerWithServices | undefined
}

export type exportData = {
  fk_id_request: number,
  fk_id_request_payment: number,
  fk_id_currency: number,
  fk_id_faculty_as_customer: number,
  fk_id_customer_payer: number,
  fk_id_service_provided_type: number,
  fk_id_payment_method: number,
  amount: number,
  paid_date: string,
  comment: string | null,
  updated_by: string
}

export default function ProvidedServicesForm({ selectedRequest, setShowForm, refreshTable, setSelectedRequest, customer }: ProvidedServicesFormProps) {
  const { lookupTables } = useContext(LookupTablesContext)
  const { feedbackDispatch } = useContext(FeedbackContext)
  const { accounts } = useMsal()

  const [requestStatus, setRequestStatus] = useState<number>(1)
  const [paymentMethod, setPaymentMethod] = useState<'' | number>('')
  const [requestComments, setRequestComments] = useState('')
  const [paidDate, setPaidDate] = useState(DateTime.now().toISO()!)
  const [disableSave, setDisableSave] = useState(true)

  const closedRequest = useMemo(() => {
    if (selectedRequest && selectedRequest.fk_id_billing_cashflow) return true
    if (selectedRequest?.fk_id_request_status_type === 4 && Boolean(selectedRequest.request_comment)) return true
    if (selectedRequest?.fk_id_request_status_type === 2) return true
    return false
  }, [selectedRequest])

  // Verify if this customer already has a billing created for the selected request's service provided type
  const respectiveBillingCreated = useMemo(() => {
    if (!customer || !selectedRequest) return undefined

    const respectiveBilling = customer.billings.find(billing => billing.fk_id_service_provided_type === selectedRequest.fk_id_service_provided_type)

    return respectiveBilling
  }, [customer, selectedRequest])

  // Update requestStatus and comments dynamically
  useEffect(() => {
    // Set request status on request change
    if (!selectedRequest) return
    setRequestStatus(selectedRequest.fk_id_request_status_type)
    setDisableSave(true)
    if (selectedRequest.fk_id_request_status_type === 3) {
      // Pre-fill 'Approved' requests
      setPaidDate(selectedRequest.paid_date!)
      setPaymentMethod(selectedRequest.fk_id_payment_method!)
      selectedRequest.request_comment ? setRequestComments(selectedRequest.request_comment) : setRequestComments('')
    } else if (closedRequest) {
      setPaidDate(selectedRequest.paid_date!)
      setPaymentMethod('')
      selectedRequest.request_comment ? setRequestComments(selectedRequest.request_comment) : setRequestComments('')
    } else {
      setPaidDate(DateTime.now().toISO()!)
      setPaymentMethod('')
      setRequestComments('')
    }
  }, [selectedRequest, setRequestStatus, setDisableSave, setPaidDate, setPaymentMethod, setRequestComments, respectiveBillingCreated, closedRequest])

  // Handle 'disableSave' state
  useEffect(() => {
    // If cashflow already created, disable save
    if (selectedRequest && selectedRequest.fk_id_billing_cashflow) return setDisableSave(true)

    if (requestStatus === 3) { // For 'Approving' requests
      if (!paidDate || !paymentMethod) return setDisableSave(true)
      return setDisableSave(false)
    }
    if (requestStatus === 4) { // For 'Rejecting' requests
      if (requestComments === '') return setDisableSave(true)
      return setDisableSave(false)
    }
    return setDisableSave(true)
  }, [selectedRequest, requestStatus, paidDate, paymentMethod, requestComments, setDisableSave])

  const renderStatusColor = (requestStatus: number) => {
    if (!selectedRequest || !requestStatus) return styles.underRevision

    switch (requestStatus) {
      case 1:
        return styles.underRevision
      case 2:
        return styles.canceled
      case 3:
        return styles.approved
      case 4:
        return styles.rejected
      default:
        return styles.underRevision
    }
  }

  const handlePaidDate = useCallback((newValue: string) => {
    setPaidDate(newValue)
    setDisableSave(false)
  }, [setPaidDate, setDisableSave])

  const saveChanges = useCallback(async () => {
    setDisableSave(true)
    feedbackDispatch({ mode: 'waiting', message: 'Saving changes...' })

    if (!selectedRequest) {
      setDisableSave(false)
      return feedbackDispatch({ mode: 'failed', message: 'No request selected' })
    }

    if (requestStatus === 3) { // 'Approving' request
      if (!paymentMethod || !paidDate) {
        setDisableSave(false)
        return feedbackDispatch({ mode: 'failed', message: 'Necessary info missing' })
      }

      const exportData: exportData = {
        fk_id_request: selectedRequest.fk_id_request,
        fk_id_request_payment: selectedRequest.fk_id_request_payment,
        fk_id_currency: selectedRequest.fk_id_currency,
        fk_id_faculty_as_customer: selectedRequest.fk_id_customer_beneficiary,
        fk_id_customer_payer: selectedRequest.fk_id_default_billing_payer_customer,
        fk_id_service_provided_type: selectedRequest.fk_id_service_provided_type,
        fk_id_payment_method: paymentMethod,
        amount: selectedRequest.amount,
        paid_date: paidDate,
        comment: requestComments === '' ? null : requestComments,
        updated_by: accounts[0].username || 'Cypress testing'
      }

      console.log(exportData)
      await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/payments`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: [{ ...exportData }] // Sending as array to be able to use same route for 'bulk POST'
      })
        .then(({ data }) => {
          console.log(data)
          feedbackDispatch({ mode: 'done', message: 'Request approved and cashflow created' })
          refreshTable()
          setShowForm(false)
          setSelectedRequest(null)
        })
        .catch(err => {
          console.log(err)
          feedbackDispatch({ mode: 'failed', message: 'Unable to save changes' })
        })

    } else if (requestStatus === 4) { // 'Rejecting' requests
      if (!requestComments) {
        setDisableSave(false)
        return feedbackDispatch({ mode: 'failed', message: 'Necessary info missing' })
      }

      await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/payments/1`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        },
        data: [{
          fk_id_request: selectedRequest.fk_id_request,
          comment: requestComments === '' ? null : requestComments
        }] // Sending as array to be able to use same route for 'bulk PUT'
      })
        .then(({ data }) => {
          console.log(data)
          feedbackDispatch({ mode: 'done', message: 'Request rejected' })
          refreshTable()
          setShowForm(false)
          setSelectedRequest(null)
        })
        .catch(err => {
          console.log(err)
          feedbackDispatch({ mode: 'failed', message: 'Unable to reject request' })
        })
    }

    setDisableSave(false)
  }, [selectedRequest, paymentMethod, paidDate, requestStatus, setDisableSave, refreshTable, setShowForm, feedbackDispatch, accounts, requestComments, setSelectedRequest])

  return <Grid container spacing={1} marginX='auto' >

    <ServiceInfoGrid selectedRequest={selectedRequest} />

    <Grid item xs={12} sx={{ paddingLeft: '0px !important' }} >
      <Typography variant='h5' >Request: <span style={{ color: '#FFF6' }} >{selectedRequest?.fk_id_request || ''}</span></Typography>
    </Grid>
    <Grid item xs={4} display='flex' alignItems='center' >
      {
        lookupTables.requestStatus[0].option === 'Loading...' ?
          'Loading...'
          :
          <select
            id="select-request-status"
            value={requestStatus}
            onChange={(e) => { setDisableSave(false); setRequestStatus(Number(e.target.value)) }}
            className={`${styles.selectStatus} ${renderStatusColor(requestStatus)}`}
            disabled={[2].includes(requestStatus)} // Disable if customer cancelled their request
          >
            {
              lookupTables.requestStatus.map((status, counter) => {
                return <option
                  key={counter}
                  value={status.id}
                  className={renderStatusColor(status.id)}
                  disabled={status.id === 2} // Only the customer who made the request can cancel a request
                >
                  {status.option}
                </option>
              })
            }
          </select>
      }
    </Grid>

    <Grid item xs={8} paddingRight='10px' >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          // views={['year', 'month']}
          label='Paid Date'
          format="dd-MMM-yy"
          value={DateTime.fromISO(paidDate)}
          onChange={(newValue) => newValue ?
            handlePaidDate(newValue!.toISO({ includeOffset: false })!)
            :
            ''
          }
          disabled={requestStatus !== 3 || closedRequest} // Enable only for 'Approved' status
        />
      </LocalizationProvider>
    </Grid>

    <Grid item xs={4} display='flex' alignItems='center'>
      <p style={{ fontSize: '1.2rem' }} >
        {
          [1, 3].includes(requestStatus) ?
            <>
              <strong>Payer:</strong> {selectedRequest?.default_billing_payer_name}<br />
              <small style={{ fontSize: '0.8rem' }} >*Set by default payment</small>
            </>
            :
            <span style={{ color: '#FFF7' }}>
              Payer:
            </span>
        }
      </p>
    </Grid>

    <Grid item xs={8} paddingRight='10px' >
      <TextField
        id='form-payment-method'
        label='Payment method'
        select
        value={[2, 4].includes(requestStatus) ? '' : paymentMethod}
        onChange={(e) => setPaymentMethod(Number(e.target.value))}
        disabled={requestStatus !== 3 || closedRequest} // Enable only for 'Approved' status
      >
        {
          lookupTables.paymentMethod.map((_method, counter) => {
            return <MenuItem key={counter} value={_method.id} >
              {_method.option}
            </MenuItem>
          })
        }
      </TextField>
    </Grid>

    <Grid item xs={12} paddingRight='10px' >
      <TextField
        id='form-comment'
        label='Comments'
        multiline
        rows={4}
        value={requestComments}
        onChange={(e) => { setDisableSave(false); setRequestComments(e.target.value) }}
        disabled={![3, 4].includes(requestStatus) || closedRequest} // Enable only for 'Approved' and 'Rejected' status
      />
    </Grid>

    <Grid item xs={3}>
      <Typography variant='h6' >Cashflow ID:</Typography>
    </Grid>
    <Grid item xs={9} paddingTop='16px !important' >
      {selectedRequest?.fk_id_billing_cashflow || '-'}
    </Grid>

    <Grid item xs={8} />
    <Grid item xs={4} height={56} paddingRight='10px' >
      <Button
        fullWidth
        sx={{ height: '100%' }}
        startIcon={<Save />}
        disabled={disableSave || closedRequest}
        onClick={saveChanges}
      >
        Save
      </Button>
    </Grid>
  </Grid>
}
