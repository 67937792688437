import axios from 'axios'
import { useEffect, useMemo, useState } from 'react'
import { Container, Grid, Tab, Tabs } from "@mui/material"
import Header from "../components/header/Header"
import AffiliationForm from "../components/affiliation/AffiliationForm"
import AffiliationProgramForm from '../components/affiliation/AffiliationProgramForm'
import AffiliationCoursewareForm from '../components/affiliation/AffiliationCoursewareForm'
import { useParams } from 'react-router-dom'
import { Affiliation } from '../components/affiliation/AffilliationSearch'
import FindersStudentsAssociation from '../components/affiliation/FindersStudentsAssociation'

export default function AffiliationView() {
  const { id } = useParams()
  const [tab, setTab] = useState<string>('form')
  const [affiliation, setAffiliation] = useState<Affiliation | null>(null)

  const disabledTabs = useMemo(() => {
    return {
      students: affiliation?.fk_id_affiliation_type === 7 ? true : false,
      courseware: affiliation?.affiliation_programs?.find((program) => program.fk_id_program === 11) || affiliation?.fk_id_affiliation_type === 6 ? true : false,
    }
  }, [affiliation])

  const affiliationDetails = async () => {
    const { data } = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SIS_BACKEND_URL}/affiliations/${id}`,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
      }
    })

    setAffiliation(data)
  }

  useEffect(() => {
    affiliationDetails()
  }, [])

  const renderComponent = () => {
    switch (tab) {
      case 'form':
        return <AffiliationForm />
      case 'program-form':
        return <AffiliationProgramForm />
      case 'courseware-form':
        return <AffiliationCoursewareForm />
      case 'students-finders-form':
        return <FindersStudentsAssociation />
      default:
        return <AffiliationForm />
    }
  }

  return <Container>
    <br />
    <Grid container spacing={1}>
      <Header title={`Affiliation`} name={affiliation?.name} />
      <Grid item xs={12}>
        <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
          <Tab id='affiliation-tab-form' label='Form' value='form' />
          <Tab id='affiliation-tab-programs' label='Programs' value='program-form' />
          {disabledTabs.courseware && <Tab id='affiliation-tab-courseware' label='Coursewares' value='courseware-form' />}
          {disabledTabs.students && <Tab id='affiliation-tab-students' label='Students' value='students-finders-form' />}
        </Tabs>
      </Grid>
      {renderComponent()}
    </Grid>
  </Container>
}