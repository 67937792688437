import { Download } from '@mui/icons-material'
import { Autocomplete, Button, FormControlLabel, Grid, MenuItem, Paper, Radio, RadioGroup, TextField } from '@mui/material'
import { useContext, useMemo } from 'react'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import { Filters } from './Billings'
import { utils, writeFile } from 'xlsx'
import { DateTime } from 'luxon'
import { ViewBilling } from './types'

type Props = {
  billings: ViewBilling[]
  defaultBillings: { id: number, label: string }[]
  filters: Filters
  monthsToRender: DateTime[]
  setFilters: React.Dispatch<React.SetStateAction<Filters>>
}

export default function BillingsOptions({ billings, defaultBillings, filters, monthsToRender, setFilters }: Props) {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // useMemos
  const gradesOptions = useMemo(() => {
    return lookupTables.grade
      .filter((_grade) => billings.find((_billing) => _billing.beneficiary_fk_id_grade === _grade.id))
      .map((_grade, index) => <MenuItem key={index} id={`grade-option-${_grade.id}`} value={_grade.id}>{_grade.option}</MenuItem>)
  }, [lookupTables, billings])
  const studentStatusTypeOptions = useMemo(() => {
    return lookupTables.studentStatusType
      .filter((_status) => billings.find((_billing) => _billing.beneficiary_fk_id_student_status_type === _status.id))
      .map((_status, index) => <MenuItem key={index} id={`status-option-${_status.id}`} value={_status.id}>{_status.option}</MenuItem>)
  }, [lookupTables, billings])

  const exportToExcel = () => {
    const wb = utils.book_new()

    const sheets = [
      { value: 'due_value', name: 'Due Values' },
      { value: 'gross_value', name: 'Gross Values' },
      { value: 'paid_value', name: 'Paid Values' },
    ] as const

    for (const _sheet of sheets) {
      const data = billings.map((_billing) => ({
        'ID': _billing.id,
        'Beneficiary/Payer': _billing.beneficiary_customer_name,
        'Grade': _billing.beneficiary_grade_name,
        'Bundle/Def. Bill.': _billing.default_billing_name || _billing.service_name,
        'Status': _billing.beneficiary_student_status_type_name,
        'Line Total': _billing.cashflow.reduce((prev, curr) => prev += curr[_sheet.value] as number, 0),
        'Number of Payments': _billing.cashflow.length,
        ...monthsToRender.reduce((prev: Record<string, number>, curr) => {
          // Filter all cash flow for current month
          const cashFlow = _billing.cashflow.filter((_) => DateTime.fromISO(_.due_date).month === curr.month && DateTime.fromISO(_.due_date).year === curr.year)

          prev[curr.toFormat('MMM yy')] = cashFlow.reduce((_prev, _curr) => _prev += _curr[_sheet.value] as number, 0)

          return prev
        }, {})
      }))

      const ws = utils.json_to_sheet(data)
      utils.book_append_sheet(wb, ws, _sheet.name)
    }

    writeFile(wb, `Billings - ${filters.affiliation?.label} - from ${filters.startDate?.toISODate()} to ${filters.endDate?.toISODate()}.xlsx`)
  }

  return <Grid container>
    <Grid item xs={12}>
      <Paper>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={filters.value}
          onChange={(e) => setFilters((_filters) => ({ ..._filters, value: e.target.value as 'gross' | 'due' | 'paid' }))}
          row
        >
          <FormControlLabel value="gross" control={<Radio sx={{ padding: .25 }} />} label="Gross" />
          <FormControlLabel value="due" control={<Radio sx={{ padding: .25 }} />} label="Due" />
          <FormControlLabel value="paid" control={<Radio sx={{ padding: .25 }} />} label="Paid" />
        </RadioGroup>
      </Paper>
    </Grid>
    <Grid item xs={12}>
      <TextField
        id='filter-grade'
        label='Grade'
        value={filters.grade}
        onChange={(e) => setFilters((_filters) => ({ ..._filters, grade: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        select
      >
        {
          [
            <MenuItem key={-1} id={`grade-option-all`} value='all'>All</MenuItem>,
            ...gradesOptions
          ]
        }
      </TextField>
    </Grid>
    <Grid item xs={12}>
      <TextField
        id='filter-status'
        label='Status'
        value={filters.status}
        onChange={(e) => setFilters((_filters) => ({ ..._filters, status: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        select
      >
        {
          [
            <MenuItem key={-1} id={`status-option-all`} value='all'>All</MenuItem>,
            ...studentStatusTypeOptions
          ]
        }
      </TextField>
    </Grid>
    <Grid item xs={12}>
      <Autocomplete
        id='filter-default-billings'
        options={defaultBillings}
        value={filters.defaultBillings}
        onChange={(e, newValue) => setFilters((_filters) => ({ ..._filters, defaultBillings: newValue }))}
        renderInput={(params) => <TextField {...params} label="Default Billings" />}
        multiple
        disableCloseOnSelect
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        id='filter-currency'
        label='Currency'
        value={filters.currency}
        onChange={(e) => setFilters((_filters) => ({ ..._filters, currency: e.target.value === 'all' ? 'all' : Number(e.target.value) }))}
        select
      >
        {
          lookupTables.currency.map((_currency, index) => <MenuItem key={index} id={`currency-option-${_currency.id}`} value={_currency.id}>{_currency.option}</MenuItem>)
        }
      </TextField>
    </Grid>
    <Grid item xs={4} height={50}>
      <Button
        id='button-export-excel'
        startIcon={<Download />}
        color='success'
        sx={{ color: 'white', backgroundColor: 'hsl(148.2, 56.5%, 35%)' }}
        disabled={!billings.length}
        onClick={exportToExcel}
      >
        XLSX
      </Button>
    </Grid>
  </Grid>
}
