import { Button, Grid, IconButton, Paper, Typography } from '@mui/material'
import { DatePicker, LocalizationProvider, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { BodyContainer, BodyRow, Cell, HeaderRow, Table } from 'wed-components'
import { Delete } from '@mui/icons-material'

type Props = {
  schedule: string[]
  setSchedule: React.Dispatch<React.SetStateAction<string[]>>
}

export default function Schedule({ schedule, setSchedule }: Props) {
  const [date, setDate] = useState<DateTime | null>(DateTime.fromObject({ hour: 12, minute: 0, second: 0, millisecond: 0 }))

  const warning = useMemo<string | null>(() => {
    if (!date) return null

    if (!date.isValid) return 'Invalid date and time.'

    if (date.minute !== 0) return 'E-mails can only be scheduled on the hour, e.g., 12:00, 1:00, 2:00 and so on.'

    if (schedule.includes(date.toISO()!)) return `This date and time is already scheduled.`

    return null
  }, [date, schedule])

  const addSchedule = () => {
    if (!date) return

    setSchedule(prev => prev.concat(date.toISO({ includeOffset: false })!))

    setDate(DateTime.fromObject({ hour: 12, minute: 0, second: 0, millisecond: 0 }))
  }

  return <>
    <Grid item xs={4}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          slotProps={{ textField: { id: 'schedule-date' } }}
          disabled={false}
          label='Date (EST)'
          format="dd-MMM-yy"
          value={date}
          minDate={DateTime.now().setZone('America/New_York')}
          onChange={(newValue) => setDate(newValue)}
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={4}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <TimePicker
          slotProps={{ textField: { id: 'schedule-time' } }}
          disabled={false}
          label='Time (EST)'
          onChange={(newValue) => setDate(newValue)}
          value={date}
          shouldDisableTime={(time, view) => view === 'minutes' && time.minute !== 0}
          viewRenderers={{
            hours: renderTimeViewClock
          }}
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={4}>
      <Button
        id='button-schedule-add'
        onClick={() => addSchedule()}
        disabled={Boolean(warning)}
      >
        ADD
      </Button>
    </Grid>
    {
      warning ?
        <Grid item xs={12}>
          <Paper sx={{ backgroundColor: 'var(--color-red)' }}>
            <Typography>
              {warning}
            </Typography>
          </Paper>
        </Grid>
        :
        null
    }
    <Grid item xs={12}>
      <Table>
        <HeaderRow>
          <Cell flexGrow><p>Date</p></Cell>
        </HeaderRow>
        <BodyContainer>
          {
            schedule.length ?
              schedule.map((row, index) => (
                <BodyRow key={index}>
                  <Cell flexGrow><p>{DateTime.fromISO(row).toFormat('dd MMM \'at\' HH:mm')}</p></Cell>
                  <Cell width={.6}>
                    <IconButton onClick={() => setSchedule(prev => prev.filter((_, i) => i !== index))}>
                      <Delete />
                    </IconButton>
                  </Cell>
                </BodyRow>
              ))
              :
              <BodyRow>
                <Cell flexGrow>
                  <p>No scheduling yet.</p>
                </Cell>
              </BodyRow>
          }
        </BodyContainer>
      </Table>
    </Grid>
  </>
}
