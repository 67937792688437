import { useState, useContext, useCallback, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField } from '@mui/material'
import { DoneAll } from '@mui/icons-material'
import { useMsal } from '@azure/msal-react'

import { customerWithServices, paymentRequest } from './Payments'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { LookupTablesContext } from '../../../context/LookupTablesContext'
import axios from 'axios'
import { exportData } from './ProvidedServicesForm'
import { FeedbackContext } from '../../../context/FeedbackContext'

type ModalApproveAllProps = {
  open: boolean,
  closeModal: () => void,
  selectedCustomer: customerWithServices | null
  requestsToApprove: paymentRequest[],
  refreshTable: () => void
}

export default function ModalApproveAll({ open, closeModal, selectedCustomer, requestsToApprove, refreshTable }: ModalApproveAllProps) {
  const { lookupTables } = useContext(LookupTablesContext)
  const { feedbackDispatch } = useContext(FeedbackContext)
  const { accounts } = useMsal()

  const [paidDate, setPaidDate] = useState(DateTime.now().toISO()!)
  const [paymentMethod, setPaymentMethod] = useState<'' | number>('')
  // const [payer, setPayer] = useState<'' | number>('')
  const [requestsComment, setRequestsComment] = useState('')
  const [disableSave, setDisableSave] = useState(true)

  // Enable or disable bulk approve dynamically
  useEffect(() => {
    if (!paymentMethod || !paidDate) return setDisableSave(true)
    setDisableSave(false)
  }, [paymentMethod, paidDate, setDisableSave])

  // Reset form input values if requestsToApprove change
  useEffect(() => {
    setPaidDate(DateTime.now().toISO()!)
    setPaymentMethod('')
    setRequestsComment('')
  }, [requestsToApprove, setPaidDate, setPaymentMethod, setRequestsComment])

  const bulkApprove = useCallback(async () => {
    setDisableSave(true)
    feedbackDispatch({ mode: 'waiting', message: 'Approving multiple requests' })

    if (!paymentMethod || !paidDate) {
      setDisableSave(false)
      return feedbackDispatch({ mode: 'failed', message: 'Necessary info missing' })
    }

    const exportData: exportData[] = requestsToApprove.map((request) => ({
      fk_id_request: request.fk_id_request,
      fk_id_request_payment: request.fk_id_request_payment,
      fk_id_currency: request.fk_id_currency,
      fk_id_faculty_as_customer: request.fk_id_customer_beneficiary,
      fk_id_customer_payer: request.fk_id_default_billing_payer_customer,
      fk_id_service_provided_type: request.fk_id_service_provided_type,
      fk_id_payment_method: paymentMethod,
      amount: request.amount,
      paid_date: paidDate,
      comment: requestsComment === '' ? null : requestsComment,
      updated_by: accounts[0].username || 'Cypress testing'
    })
    )

    console.log({ exportData })
    await axios({
      method: 'POST',
      url: `${process.env.REACT_APP_SIS_BACKEND_URL}/payments`,
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
      },
      data: [...exportData]
    })
      .then(({ data }) => {
        console.log(data)
        feedbackDispatch({ mode: 'done', message: 'Requests approved successfully' })
        closeModal()
        refreshTable()
      })
      .catch(err => {
        console.log(err)
        feedbackDispatch({ mode: 'failed', message: 'Unable to approve requests' })
      })

    setDisableSave(false)
  }, [requestsToApprove, setDisableSave, feedbackDispatch, paymentMethod, paidDate, requestsComment])

  if (!selectedCustomer || !requestsToApprove.length) return <span></span>
  return <Dialog
    id={`modal-approve-all`}
    open={open}
    onClose={closeModal}
  // onKeyUp={(e) => {
  //   e.stopPropagation()
  //   if (e.key === 'Enter') proceedButtonAction()
  // }}
  >
    <DialogTitle>Approve all pending requests</DialogTitle>
    <DialogContent>
      <p>
        You're about to approve all pending requests for faculty: <strong>{selectedCustomer.faculty_full_name}</strong> <br />
        Pending requests include:
      </p>
      <ul>
        {
          selectedCustomer.sectionClasses.map((sectionClass, counter) => {
            const associatedRequests = requestsToApprove.filter(request => request.fk_id_section === sectionClass.fk_id_section && request.fk_id_class === sectionClass.fk_id_class)

            if (!associatedRequests.length) return ''
            return <li key={counter}>
              <strong>{sectionClass.section_name}{sectionClass.class_tag ? ` - ${sectionClass.class_tag}` : ''}</strong>:<br />
              ({associatedRequests.length} lesson{associatedRequests.length > 1 ? 's' : ''})
            </li>
          })
        }
        {
          selectedCustomer.defaultPayments.map((defPay, counter) => {
            const associatedRequests = requestsToApprove.filter(request => request.fk_id_default_payment === defPay.fk_id_default_payment && !request.lesson_number) // Disconsider 'Hour activities' with associated lessons

            if (!associatedRequests.length) return ''
            return <li key={counter}>
              <strong>{defPay.default_payment_name}</strong>:<br />
              ({associatedRequests.length} payment request{associatedRequests.length > 1 ? 's' : ''})
            </li>
          })
        }
      </ul>

      <Grid container spacing={1} marginTop='1rem' >
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DatePicker
              // views={['year', 'month']}
              label='Paid Date'
              format="dd-MMM-yy"
              value={DateTime.fromISO(paidDate)}
              onChange={(newValue) => newValue ?
                setPaidDate(newValue.toISO({ includeOffset: true })!)
                :
                ''
              }
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <TextField
            id='form-payment-method'
            label='Payment method'
            select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(Number(e.target.value))}
          >
            {
              lookupTables.paymentMethod.map((_method, counter) => {
                return <MenuItem key={counter} value={_method.id} >
                  {_method.option}
                </MenuItem>
              })
            }
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='form-comment'
            label='Comments'
            multiline
            rows={4}
            value={requestsComment}
            onChange={(e) => setRequestsComment(e.target.value)}
          />
        </Grid>
      </Grid>

    </DialogContent>
    <DialogActions sx={{ paddingRight: '24px', paddingBottom: '24px' }} >
      <Button
        id={`button-modal-cancel`}
        onClick={closeModal}
      >
        Go back
      </Button>
      <Button
        id={`button-modal-proceed`}
        startIcon={<DoneAll />}
        onClick={bulkApprove}
        disabled={disableSave}
        color='success'
        sx={{ color: 'white' }}
      >
        Approve all
      </Button>
    </DialogActions>
  </Dialog>
}
