import { Autocomplete, Button, Grid, MenuItem, TextField } from "@mui/material"
import { Dispatch, SetStateAction, useCallback, useContext, useMemo } from "react"
import { LookupTablesContext } from "../../context/LookupTablesContext"
import { DateTime } from "luxon"
import { Filters, Recipient } from "./types"
import { buildFilters, recipientTypeSelect } from "./utils"
import { EmailTabs } from "./SendEmail"
import { toastError, toastSuccess, toastWarning } from "../assets/customToasts"
import { toast } from "react-toastify"
import axios from "axios"
import { ViewFaculty } from "../faculty/FacultySearch"
import { ViewStudent } from "../students/StudentsSearch"

type EmailFiltersProps = {
  filters: Filters
  recipientsType: Recipient
  setFilters: Dispatch<SetStateAction<Filters>>
  setRecipients: React.Dispatch<React.SetStateAction<ViewFaculty[] | ViewStudent[]>>
  setRecipientIds: React.Dispatch<React.SetStateAction<number[]>>
  setRecipientsType: React.Dispatch<React.SetStateAction<Recipient>>
  tab: EmailTabs
}

export default function EmailFilters({ filters, recipientsType, setFilters, setRecipients, setRecipientIds, setRecipientsType, tab }: EmailFiltersProps) {
  const { lookupTables } = useContext(LookupTablesContext)

  const queryString = useMemo(() => {
    let query: string[] = [`?receiverType=${recipientsType}`]

    const params = Object.keys(filters) as (keyof Filters)[]

    params.forEach((key: keyof Filters) => {
      //if "all" option is selected do not include the key on query
      if (filters[key].length === 1 && filters[key][0].id === 0) return
      filters[key].forEach(value => {
        query.push(`${key}[]=${value.id}`)
      })
    })

    return query.join('&')

  }, [filters, recipientsType])

  const filtersOptions: Filters = useMemo(() => {
    return {
      fk_id_affiliation: lookupTables.affiliation.map(item => ({ label: `${item.tag} - ${item.option}`, id: item.id })),
      fk_id_program: lookupTables.program.map(item => ({ label: item.option, id: item.id })),
      fk_id_grade: lookupTables.grade.map(item => ({ label: item.option, id: item.id })),
      fk_id_student_status_type: lookupTables.studentStatusType.map(item => ({ label: item.option, id: item.id })),
      admissionYear: Array.from({ length: DateTime.now().year - 2017 + 1 }, (_, counter) => 2017 + counter).map((year) => ({ label: year, id: year })),
      fk_id_faculty_type: lookupTables.facultyType.map(item => ({ label: item.option, id: item.id })),
    }
  }, [lookupTables])

  const handleFilters = (newValue: any, key: keyof Filters) => {
    //unchecking any other option when the 'all' option is selected
    if (newValue.some((option: any) => option.id === 0)) {
      setFilters({ ...filters, [key]: [{ id: 0, label: 'All' }] })
      return
    }
    setFilters({ ...filters, [key]: newValue })
  }

  const fetchRecipients = useCallback(async () => {
    const _toast = toast('Loading recipients', { toastId: 'toast-load-recipients', isLoading: true })

    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SIS_BACKEND_URL}/email-receivers${queryString}`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
        }
      })

      console.log(data)

      setRecipients(data)
      setRecipientIds(data.map((_: any) => _.id))

      if (data.length > 0) toastSuccess(_toast, 'Manage list.')

      if (!data.length) toastWarning(_toast, 'No result for filter combination.')
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not load recipients')
    }

  }, [queryString])

  return <>
    {
      tab === 'filters' ?
        <Grid item xs={12}>
          <TextField
            id='search-filter-recipient-type'
            label='Type'
            value={recipientsType}
            onChange={(e) => setRecipientsType(e.target.value as Recipient)}
            select
          >
            {
              recipientTypeSelect.map((item, index) => <MenuItem key={index} id={`recipient-option-${index}`} value={item.value}>{item.label}</MenuItem>)
            }
          </TextField>
        </Grid>
        :
        null
    }
    {
      buildFilters
        .filter((item) => item.type === recipientsType || item.type === 'all')
        .map((item, index) => (
          <Grid item xs={item.width} key={index}>
            <Autocomplete
              multiple
              id={item.id}
              value={filters[item.key]}
              onChange={(e, newValue) => handleFilters(newValue, item.key)}
              renderInput={params => <TextField {...params} label={item.label} />}
              options={[{ id: 0, label: 'All' }, ...filtersOptions[item.key]]}
            />
          </Grid>
        ))
    }
    {
      tab === 'filters' ?
        <Grid item xs={4}>
          <Button
            id={`button-load-recipients`}
            sx={{ height: '50px' }}
            onClick={() => fetchRecipients()}
          >
            Load
          </Button>
        </Grid>
        :
        null
    }
  </>
}
