import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useIsAuthenticated } from "@azure/msal-react"
import { theme } from './theme'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'

import { LookupTablesContextProvider } from './context/LookupTablesContext'

// General
import DrawerMenu from './components/DrawerMenu'
import Home from './components/Home'

// Views
import StudentsView from './views/StudentsView'
import StudentsSearch from './components/students/StudentsSearch'
import AcademicView from './views/AcademicView'
import ReportsView from './views/ReportsView'
import { FeedbackContextProvider } from './context/FeedbackContext'
import Feedback from './components/Feedback'
import NestView from './views/NestView'
import CourseProgressionToolsView from './views/CourseProgressionToolsView'
import FacultySearch from './components/faculty/FacultySearch'
import FacultyView from './views/FacultyView'
import EnrollmentToolsView from './views/EnrollmentToolsView'
import CreditToolsView from './views/CreditToolsView'
import BillingsView from './views/BillingsView'
import TestManagement from './components/testManagement/TestManagement'
import PaymentsView from './views/PaymentsView'
import AffiliationView from './views/AffiliationsView'
import AffiliationSearch from './components/affiliation/AffilliationSearch'
import Material from './components/testManagement/Material'
import VouchersView from './views/VouchersView'
import NotificationsView from './views/NotificationsView'
import { ToastContainer } from 'react-toastify'
import CanvasView from './views/CanvasView'
import DevelopersView from './views/DevelopersView'
import EmailsView from './views/EmailsView'

function App() {
  const isAuthenticated = useIsAuthenticated()

  // This function creates a bypass for the tests 
  const defineRoute = (loggedRoute: JSX.Element, unauthorizedRoute: JSX.Element) => {
    if (process.env.REACT_APP_TESTING) {
      return loggedRoute
    }
    else {
      return isAuthenticated ? loggedRoute : unauthorizedRoute
    }
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <LookupTablesContextProvider>
            <FeedbackContextProvider>
              <DrawerMenu />
              <div className="view">
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/notifications' element={defineRoute(<NotificationsView />, <Navigate to='/' />)} />
                  <Route path='/students' element={defineRoute(<StudentsSearch />, <Navigate to='/' />)} />
                  <Route path='/affiliations' element={defineRoute(<AffiliationSearch />, <Navigate to='/' />)} />
                  <Route path='/affiliations/:id' element={defineRoute(<AffiliationView />, <Navigate to='/' />)} />
                  <Route path='/students/:id' element={defineRoute(<StudentsView />, <Navigate to='/' />)} />
                  <Route path='/faculty' element={defineRoute(<FacultySearch />, <Navigate to='/' />)} />
                  <Route path='/faculty/:id' element={defineRoute(<FacultyView />, <Navigate to='/' />)} />
                  <Route path='/academic' element={defineRoute(<AcademicView />, <Navigate to='/' />)} />
                  <Route path='/reports' element={defineRoute(<ReportsView />, <Navigate to='/' />)} />
                  <Route path='/billings' element={defineRoute(<BillingsView />, <Navigate to='/' />)} />
                  <Route path='/payments' element={defineRoute(<PaymentsView />, <Navigate to='/' />)} />
                  <Route path='/vouchers' element={defineRoute(<VouchersView />, <Navigate to='/' />)} />
                  <Route path='/nest' element={defineRoute(<NestView />, <Navigate to='/' />)} />
                  <Route path='/enrollment-tools' element={defineRoute(<EnrollmentToolsView />, <Navigate to='/' />)} />
                  <Route path='/course-progression-tools' element={defineRoute(<CourseProgressionToolsView />, <Navigate to='/' />)} />
                  <Route path='/credit-tools' element={defineRoute(<CreditToolsView />, <Navigate to='/' />)} />
                  <Route path='/test-management' element={defineRoute(<TestManagement />, <Navigate to='/' />)} />
                  <Route path='/material' element={defineRoute(<Material />, <Navigate to='/' />)} />
                  <Route path='/canvas' element={defineRoute(<CanvasView />, <Navigate to='/' />)} />
                  <Route path='/developers' element={defineRoute(<DevelopersView />, <Navigate to='/' />)} />
                  <Route path='/notifications' element={defineRoute(<NotificationsView />, <Navigate to='/' />)} />
                  <Route path='/email-service' element={defineRoute(<EmailsView />, <Navigate to='/' />)} />
                </Routes>
              </div>
              <ToastContainer position='bottom-right' closeOnClick theme='light' autoClose={2500} />
              <Feedback />
            </FeedbackContextProvider>
          </LookupTablesContextProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  )
}

export default App;