import { useState, useCallback, useMemo, useEffect, useContext } from 'react'
import { Button, Grid, MenuItem, TextField } from '@mui/material'
import { Download } from '@mui/icons-material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { Search, DoneAll } from '@mui/icons-material'
import { DateTime } from 'luxon'
import { utils, writeFile } from 'xlsx'
import axios from 'axios'
import { RightSidePanel } from 'wed-components'

import CustomersTable from './CustomersTable'
import ProvidedServicesTable from './ProvidedServicesTable'
import PreLoader from '../../PreLoader'
import ProvidedServicesForm from './ProvidedServicesForm'
import { FeedbackContext } from '../../../context/FeedbackContext'
import ModalApproveAll from './ModalApproveAll'
import styles from './Payments.module.css'

export type TableDisplayType = {
  startDate: string,
  endDate: string,
  value: 'gross' | 'due' | 'paid',
}

export type dateColumn = {
  date: DateTime,
  header: string
}

export type customerSectionClass = {
  fk_id_faculty: number,
  fk_id_section: number,
  section_name: string,
  fk_id_class: number,
  class_tag: string,
  fk_id_currency: number
}

export type customerDefaultPayment = {
  fk_id_customer_beneficiary: number,
  fk_id_faculty: number,
  faculty_full_name: string,
  fk_id_default_payment: number,
  default_payment_name: string,
  fk_id_customer_default_payment_map: number,
  fk_id_currency: number
}

export type customerBillings = {
  fk_id_customer_beneficiary: number,
  customer_beneficiary_name: string,
  fk_id_customer_payer: number,
  payer_name: string,
  fk_id_service_provided_type: number,
  service_provided_type_name: string,
  fk_id_billing: number,
}

export type customerWithServices = {
  fk_id_customer_beneficiary: number,
  fk_id_faculty: number,
  faculty_full_name: string,
  sectionClasses: customerSectionClass[],
  defaultPayments: customerDefaultPayment[],
  billings: customerBillings[]
}

export type paymentRequest = {
  fk_id_request: number,
  fk_id_request_payment: number,
  fk_id_customer_beneficiary: number,
  fk_id_faculty: number,
  fk_id_section: number | null,
  fk_id_class: number | null,
  fk_id_default_payment: number,
  fk_id_customer_default_payment_map: number,
  fk_id_currency: number,
  fk_id_request_status_type: number,
  request_status_type_name: string
  paid_date: string | null,
  service_provided_date: string,
  service_provided_type_name: string,
  amount: number,
  fk_id_default_billing_payer_customer: number
  default_billing_payer_name: string,

  service_provided_comment: string | null,
  lesson_number: number | null,
  lesson_content: string
  lesson_comment: string | null
  section_name: string | null,
  class_tag: string | null,
  fk_id_payment_type: number,
  fk_id_service_provided_type: number,
  dependence_multiplier: number,
  default_payment_description: string,
  currency_per_multiplier: number,
  customer_beneficiary_name: string,
  fk_id_billing_cashflow: number | null,
  fk_id_customer_payer: number | null
  fk_id_payment_method: number | null,
  request_comment: string | null,
  currency_name: string
}

export const renderQuantity = (request: paymentRequest) => {
  // const quantity = request.fk_id_payment_type === 1 ? (request.dependence_multiplier / 60).toFixed(1) : request.dependence_multiplier
  const multiplier = request.fk_id_service_provided_type === 1 ? 'activities' : 'minutes'

  return `${request.dependence_multiplier} ${multiplier}`
}

export default function Payments() {
  const [loading, setLoading] = useState(false)
  const [tableDisplay, setTableDisplay] = useState<TableDisplayType>({
    startDate: DateTime.now().startOf('month').toISO({ includeOffset: false })!,
    endDate: DateTime.now().endOf('month').toISO({ includeOffset: false })!,
    value: 'due'
  })
  const [customerRows, setCustomerRows] = useState<customerWithServices[]>([])
  const [requests, setRequests] = useState<paymentRequest[]>([])
  const [selectedCustomer, setSelectedCustomer] = useState<null | customerWithServices>(null)
  const [defaultPayment, setDefaultPayment] = useState<'all' | number>('all')
  const [sectionClass, setSectionClass] = useState<'all' | number>('all')
  const [showForm, setShowForm] = useState(false)
  const [selectedRequest, setSelectedRequest] = useState<null | paymentRequest>(null)
  const [showModalApproveAll, setShowModalApproveAll] = useState(false)

  const { feedbackDispatch } = useContext(FeedbackContext)

  function handleQuickDate(direction: 'prev' | 'next') {
    const startOfCurrentMonth = DateTime.fromISO(tableDisplay.startDate).startOf('month')

    if (direction === 'prev') {
      return setTableDisplay(prev => ({
        ...prev,
        startDate: startOfCurrentMonth.minus({ months: 1 }).toISO({ includeOffset: false })!,
        endDate: startOfCurrentMonth.minus({ days: 1 }).toISO({ includeOffset: false })!
      }))
    }

    setTableDisplay(prev => ({
      ...prev,
      startDate: startOfCurrentMonth.plus({ months: 1 }).toISO({ includeOffset: false })!,
      endDate: startOfCurrentMonth.minus({ days: 1 }).plus({ months: 2 }).toISO({ includeOffset: false })!
    }))
  }

  const resetFilters = useCallback(() => {
    setDefaultPayment('all')
    setSectionClass('all')
  }, [setDefaultPayment, setSectionClass])

  const handleSelectCustomer = useCallback((newCustomer: customerWithServices) => {
    setSelectedCustomer(prev => {
      resetFilters()
      if (!prev || prev.fk_id_customer_beneficiary !== newCustomer.fk_id_customer_beneficiary) return newCustomer
      return null
    })
  }, [resetFilters, setSelectedCustomer])

  const handleSelectRequest = useCallback((newRequest: paymentRequest) => {
    setSelectedRequest(prev => {
      if (!prev || prev.fk_id_request !== newRequest.fk_id_request) return newRequest
      return null
    })
  }, [setSelectedRequest])

  // Toggle form when clicking on requests
  useEffect(() => {
    if (!selectedRequest) return setShowForm(false)
    setShowForm(true)
  }, [selectedRequest, setShowForm])

  const filterByDefaultPayment = useCallback((request: paymentRequest) => {
    if (defaultPayment === 'all') return true
    return request.fk_id_default_payment === defaultPayment && !request.lesson_content
  }, [defaultPayment])

  const filterBySectionClass = useCallback((request: paymentRequest) => {
    if (sectionClass === 'all') return true
    return request.fk_id_class === sectionClass || request.fk_id_section === sectionClass
  }, [sectionClass])

  const handleFilter = useCallback((filterType: 'sectionClass' | 'defaultPayment', value: 'all' | number) => {
    if (filterType === 'sectionClass') {
      setSectionClass(value === 'all' ? 'all' : value);
      setDefaultPayment('all')
    } else {
      setDefaultPayment(value === 'all' ? 'all' : value);
      setSectionClass('all')
    }
  }, [setSectionClass, setDefaultPayment])

  const filteredRequests = useMemo(() => {
    if (!requests.length || !selectedCustomer) return []

    return requests
      .filter(req => req.fk_id_customer_beneficiary === selectedCustomer.fk_id_customer_beneficiary)
      .filter(filterByDefaultPayment)
      .filter(filterBySectionClass)
  }, [requests, selectedCustomer, filterByDefaultPayment, filterBySectionClass])

  const refreshTable = useCallback(async ({ newFetch }: { newFetch: boolean }) => {
    setLoading(true)
    if (newFetch) {
      setSelectedCustomer(null)
      setSectionClass('all')
      setDefaultPayment('all')
    }

    const newStartDate = DateTime.fromISO(tableDisplay.startDate).startOf('day').toISO({ includeOffset: false })!
    const newEndDate = DateTime.fromISO(tableDisplay.endDate).endOf('day').toISO({ includeOffset: false })!

    await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SIS_BACKEND_URL}/payments?start_date=${newStartDate}&end_date=${newEndDate}`,
      headers: {
        authorization: `Bearer ${process.env.REACT_APP_SIS_BACKEND_TOKEN}`
      }
    })
      .then(({ data }) => {
        console.log(data)
        setCustomerRows(data.customers)
        setRequests(data.requests)
      })
      .catch(err => {
        console.log(err)
        feedbackDispatch({ mode: 'failed', message: 'Unable to fetch payment requests' })
        setCustomerRows([])
        setRequests([])
      })

    setLoading(false)
  }, [tableDisplay.startDate, tableDisplay.endDate, feedbackDispatch])

  // Fetch table's data on Enter key press
  const fetchOnKeyPress = useCallback((e: any) => {
    if (e.code === 'Enter') refreshTable({ newFetch: true });
  }, [refreshTable])

  const exportToExcel = () => {
    const wb = utils.book_new();
    const excelData = requests.filter(req => req.fk_id_customer_beneficiary !== 1858).map(request => {

      return {
        'beneficiary': request.customer_beneficiary_name,
        'quantity multiplier': renderQuantity(request).split(' ')[0],
        'multiplier type': renderQuantity(request).split(' ')[1],
        'service provided type': request.service_provided_type_name,
        'date': DateTime.fromISO(request.service_provided_date).toFormat('dd/LL/yyyy'),
        'currency': request.currency_name,
        'amount': request.amount,
        'service provided comment': request.service_provided_comment,
        'request status': request.request_status_type_name,
        'section': request.section_name,
        'class': request.class_tag,
        'lesson number': request.lesson_number,
        'lesson content': request.lesson_content,
        'lesson comment': request.lesson_comment,
      }
    })
    const referenceMonth = DateTime.fromISO(tableDisplay.startDate).toFormat('LLL-yy')

    const ws = utils.json_to_sheet(excelData);
    utils.book_append_sheet(wb, ws, referenceMonth);

    writeFile(wb, `payments-${referenceMonth}.xlsx`);
  }

  return <>
    <RightSidePanel
      title='Service Provided'
      state={showForm}
      close={() => { setShowForm(false); setSelectedRequest(null) }}
    >
      <ProvidedServicesForm
        selectedRequest={selectedRequest}
        setShowForm={setShowForm}
        refreshTable={() => refreshTable({ newFetch: false })}
        setSelectedRequest={setSelectedRequest}
        customer={customerRows.find(_customer => _customer.fk_id_customer_beneficiary === selectedRequest?.fk_id_customer_beneficiary)}
      />
    </RightSidePanel>
    <ModalApproveAll
      open={showModalApproveAll}
      closeModal={() => setShowModalApproveAll(false)}
      selectedCustomer={selectedCustomer}
      requestsToApprove={filteredRequests.filter(request => request.fk_id_request_status_type === 1)}
      refreshTable={() => refreshTable({ newFetch: false })}
    />
    <Grid id='start-date-grid' item xs={1.5} >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          // views={['year', 'month']}
          label='Start Date'
          format="dd-MMM-yy"
          value={DateTime.fromISO(tableDisplay.startDate)}
          onChange={(newValue) => newValue ?
            setTableDisplay({
              ...tableDisplay,
              startDate: newValue!.toISO({ includeOffset: false })!,
              endDate: newValue!.plus({ months: 1 }).minus({ days: 1 }).toISO({ includeOffset: false })!
            })
            :
            setTableDisplay({ ...tableDisplay, startDate: '' })
          }
        />
      </LocalizationProvider>
    </Grid>
    <Grid id='end-date-grid' item xs={1.5} >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          // views={['year', 'month']}
          label='End Date'
          format="dd-MMM-yy"
          value={DateTime.fromISO(tableDisplay.endDate)}
          onChange={(newValue) => newValue ?
            setTableDisplay({ ...tableDisplay, endDate: newValue!.toISO({ includeOffset: false })! })
            :
            setTableDisplay({ ...tableDisplay, endDate: '' })
          }
        />
      </LocalizationProvider>
    </Grid>
    <Grid item xs={0.75} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <button id='btn-prev-month' onClick={() => handleQuickDate('prev')} className={styles.quickDateBtn} >
        {'<< Prev'}
      </button>
      <button id='btn-next-month' onClick={() => handleQuickDate('next')} className={styles.quickDateBtn} >
        {'Next >>'}
      </button>
    </Grid>
    <Grid item xs={1.25}>
      <Button
        id='button-search'
        fullWidth
        startIcon={<Search />}
        sx={{ height: '100%' }}
        onClick={() => refreshTable({ newFetch: true })}
        disabled={loading}
      >
        Search
      </Button>
    </Grid>
    <Grid item xs={3} >
      <TextField
        id='filter-section-class'
        label='Section / Class'
        select
        value={sectionClass}
        onChange={(e) => handleFilter('sectionClass', e.target.value === 'all' ? 'all' : Number(e.target.value))}
        disabled={!selectedCustomer}
      >
        {[
          <MenuItem key={-1} value='all' >All</MenuItem>,
          selectedCustomer && selectedCustomer.sectionClasses.map((sectionClass, counter) => {
            return <MenuItem key={counter} value={sectionClass.fk_id_class || sectionClass.fk_id_section} >
              {sectionClass.section_name}{sectionClass.class_tag && ` - ${sectionClass.class_tag}`}
            </MenuItem>
          })
        ]}
      </TextField>
    </Grid>
    <Grid item xs={1.5} >
      <TextField
        id='filter-default-payment'
        label='Default Payment'
        select
        value={defaultPayment}
        onChange={(e) => handleFilter('defaultPayment', e.target.value === 'all' ? 'all' : Number(e.target.value))}

        disabled={!selectedCustomer}
      >
        {[
          <MenuItem key={-1} value='all' >All</MenuItem>,
          selectedCustomer && selectedCustomer.defaultPayments.map((defPay, counter) => {
            return <MenuItem key={counter} value={defPay.fk_id_default_payment} >
              {defPay.default_payment_name}
            </MenuItem>
          })
        ]}
      </TextField>
    </Grid>
    <Grid item xs={1}>
      <Button
        id='btn-download'
        onClick={exportToExcel}
        fullWidth
        color='success'
        sx={{ height: '100%', color: 'white', backgroundColor: 'hsl(148.2, 56.5%, 35%)' }}
        startIcon={<Download />}
        disabled={!requests.length || loading}
      >
        XLSX
      </Button>
    </Grid>
    <Grid item xs={1.5} >
      <Button
        id='btn-approve-all'
        color='success'
        startIcon={<DoneAll />}
        fullWidth
        sx={{ height: '100%', color: 'white' }}
        disabled={!filteredRequests.some(request => request.fk_id_request_status_type === 1) || loading}
        onClick={() => setShowModalApproveAll(true)}
      >
        Approve all
      </Button>
    </Grid>
    {
      loading ?
        <PreLoader height='calc(100vh - 220px)' />
        :
        <>
          <Grid item xs={5} height='calc(100vh - 220px)' >
            <CustomersTable
              customerRows={customerRows}
              requests={requests}
              handleSelectCustomer={handleSelectCustomer}
              selectedCustomer={selectedCustomer}
              handleFilter={handleFilter}
            />
          </Grid>
          <Grid item xs={7} height='calc(100vh - 220px)' >
            <ProvidedServicesTable filteredRequests={filteredRequests} handleSelectRequest={handleSelectRequest} />
          </Grid>
          <Grid item xs={10} />
        </>
    }
  </>
}
