import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { LookupTablesContext } from "../../../context/LookupTablesContext"
import { Autocomplete, Button, Grid, MenuItem, TextField } from "@mui/material"
import axios from "axios"
import { toast } from "react-toastify"
import { SubmissionsTablePerSection, SubmissionsTablePerStudent } from "./SubmissionsTables"
import { AutocompleteOption } from "../../../utilities/generalPurposeTypes"
import { Download, FilterAltOff } from "@mui/icons-material"
import { RightSidePanel } from "wed-components"
import SubmissionsForm from "./SubmissionsForm"
import { utils, writeFile } from 'xlsx'
import { DateTime } from "luxon"
import PreLoader from "../../PreLoader"
import { toastError, toastSuccess } from "../../assets/customToasts"

export type Panel = {
  show: boolean,
  id?: number
}

export type Submission = {
  comment_attempt: number | null,
  commented_at: string | null,
  days_late: number,
  fk_id_enrollment: number,
  fk_id_grader: number | null,
  fk_id_lms_assignment: number,
  id: number,
  has_seen_attempt: boolean | null,
  name: string,
  submitted_at: string
}

export type Student = {
  affiliation_name: string,
  class_tag: string,
  faculty: {
    fk_id_faculty: number,
    faculty_full_name: string,
    fk_id_section: number
  }[],
  fk_id_affiliation: number,
  fk_id_class: number,
  fk_id_section: number,
  fk_id_student: number,
  id: number,
  lms_cnv_user_id: number | null,
  section_name: string,
  student_full_name: string,
  submissions: Submission[]
}

export type GroupBySection = {
  fk_id_section: number,
  section_name: string,
  fk_id_affiliation: number,
  affiliation_name: string,
  faculty: {
    fk_id_faculty: number,
    faculty_full_name: string,
    fk_id_section: number
  }[],
  students: {
    class_tag: string,
    fk_id_class: number,
    fk_id_student: number,
    id: number,
    lms_cnv_user_id: number | null,
    student_full_name: string,
    submissions: Submission[]
  }[]
}

type Filters = {
  affiliation: AutocompleteOption | null
  name: string
  section: AutocompleteOption | null
  teacher: AutocompleteOption | null
  viewMode: 'per-student' | 'per-section'
}

export default function Submissions() {
  // Hooks
  const { lookupTables } = useContext(LookupTablesContext)
  // General
  const [loading, setLoading] = useState<boolean>(true)
  const [panel, setPanel] = useState<Panel>({
    show: false
  })
  // Filters
  const [filters, setFilters] = useState<Filters>({
    affiliation: null,
    name: '',
    section: null,
    teacher: null,
    viewMode: 'per-section'
  })
  // Data
  const [sections, setSections] = useState<AutocompleteOption[]>([])
  const [students, setStudents] = useState<Student[]>([])
  const [teachers, setTeachers] = useState<AutocompleteOption[]>([])
  // useMemos
  const affiliationOptions = useMemo(() => lookupTables.affiliation.map((_) => ({ id: _.id, label: _.option })), [lookupTables])
  const nameRegExp = useMemo(() => new RegExp(filters.name, 'i'), [filters.name])
  const selectedStudent = useMemo(() => students.find((_) => _.id === panel.id) || null, [students, panel.id])
  const groupBySection = useMemo(() => {
    return students.reduce((prev: GroupBySection[], curr) => {
      const { fk_id_section, section_name, fk_id_affiliation, affiliation_name, faculty, ..._student } = curr

      // Trying to find the section in the array
      const index = prev.findIndex((_) => _.fk_id_section === curr.fk_id_section)

      if (index !== -1) {
        prev[index].students.push(_student)
      }
      else {
        prev.push({
          fk_id_section,
          section_name,
          fk_id_affiliation,
          affiliation_name,
          faculty,
          students: [_student]
        })
      }

      return prev
    }, [])
  }, [students, sections])

  const refreshTable = useCallback(async () => {
    try {
      const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_DIPLOMAT_URL}/submissions-not-graded`,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_DIPLOMAT_TOKEN}`
        }
      })

      console.log(data)

      setSections(data.sections)
      setStudents(data.students)
      setTeachers(data.teachers)
    } catch (err) {
      console.log(err)
      toast.error('Could not load submissions.')
    }

    setLoading(false)
  }, [])

  useEffect(() => {
    refreshTable()
  }, [refreshTable])

  const updateSubmissions = useCallback(async (fk_id_section: number, fk_id_student: number) => {
    const _toast = toast('Updating submissions', { toastId: 'toast-update-submissions', isLoading: true })

    try {
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_DIPLOMAT_URL}/update-submissions-and-enrollment-grades?fk_id_section=${fk_id_section}&fk_id_student=${fk_id_student}&verbose=true`,
        headers: {
          authorization: `Bearer ${process.env.REACT_APP_DIPLOMAT_TOKEN}`
        }
      })

      await refreshTable()

      toastSuccess(_toast, 'Submissions updated.')
    } catch (error) {
      console.log(error)
      toastError(_toast, 'Could not update submissions.')
    }
  }, [refreshTable])

  // Filters
  const filterByName = (row: Student) => {
    if (nameRegExp.test(row.student_full_name)) return true

    return false
  }

  const filterByTeacher = (row: Student) => {
    switch (filters.teacher) {
      case null:
        return true
      default:
        return row.faculty.find((_) => _.fk_id_faculty === filters.teacher!.id)
    }
  }

  const filterBySection = (row: Student) => {
    switch (filters.section) {
      case null:
        return true
      default:
        return row.fk_id_section === filters.section.id
    }
  }

  const filterByTeacherGroupedPerSection = (row: GroupBySection) => {
    switch (filters.teacher) {
      case null:
        return true
      default:
        return row.faculty.find((_teacher) => _teacher.fk_id_faculty === filters.teacher!.id)
    }
  }

  const exportToExcel = () => {
    const wb = utils.book_new()

    for (const _teacher of teachers) {
      const _sections = groupBySection
        .filter((_section) => _section.faculty.find((_) => _.fk_id_faculty === _teacher.id))
        .map((_section) => ({
          'Affiliation': _section.affiliation_name,
          'Section': _section.section_name,
          'Late Assignments': _section.students.reduce((prev, curr) => prev += curr.submissions.length, 0),
          'Status': _section.students.reduce((prev, curr) => prev += curr.submissions.length, 0) > 0 ? 'Late' : 'Up to date'
        }))

      const ws = utils.json_to_sheet(_sections)
      utils.book_append_sheet(wb, ws, _teacher.label)
    }

    writeFile(wb, `Report - Grading Activities - ${DateTime.utc().toFormat('dd-MMM-yyyy')}.xlsx`)
  }

  return <>
    {/* Right Side Panel */}
    <RightSidePanel state={panel.show} close={() => setPanel({ show: false })} title={students.find((_) => _.id === panel.id)?.student_full_name || ''} maxWidth='lg'>
      {
        panel.show ?
          <SubmissionsForm
            student={selectedStudent}
          />
          :
          <></>
      }
    </RightSidePanel>
    {/* Filters */}
    {
      filters.viewMode === 'per-student' ?
        <>
          <Grid item xs={3}>
            <TextField
              id='form-field-name'
              label='Student name'
              value={filters.name}
              onChange={(e) => setFilters(prev => ({ ...prev, name: e.target.value }))}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              id='form-field-affiliation'
              renderInput={props => <TextField {...props} label='Affiliation' />}
              options={affiliationOptions}
              value={filters.affiliation}
              onChange={(e, newValue) => setFilters(prev => ({ ...prev, affiliation: newValue }))}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              id='form-field-section'
              renderInput={props => <TextField {...props} label='Section' />}
              options={sections}
              value={filters.section}
              onChange={(e, newValue) => setFilters(prev => ({ ...prev, section: newValue }))}
            />
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              id='form-field-teacher'
              renderInput={props => <TextField {...props} label='Teacher' />}
              options={teachers}
              value={filters.teacher}
              onChange={(e, newValue) => setFilters(prev => ({ ...prev, teacher: newValue }))}
            />
          </Grid>
        </>
        :
        <>
          <Grid item xs={7} />
          <Grid item xs={2}>
            <Autocomplete
              id='form-field-teacher'
              renderInput={props => <TextField {...props} label='Teacher' />}
              options={teachers}
              value={filters.teacher}
              onChange={(e, newValue) => setFilters(prev => ({ ...prev, teacher: newValue }))}
            />
          </Grid>
        </>
    }
    <Grid item xs={1.5}>
      <TextField
        id='form-field-view-mode'
        label='View mode'
        value={filters.viewMode}
        onChange={(e) => setFilters(prev => ({ ...prev, viewMode: e.target.value as 'per-student' | 'per-section' }))}
        select
      >
        <MenuItem value='per-section'>Per Section</MenuItem>
        <MenuItem value='per-student'>Per Student</MenuItem>
      </TextField>
    </Grid>
    <Grid item xs={.75}>
      <Button
        id='button-clear-filter'
        onClick={() => setFilters({ affiliation: null, name: '', section: null, teacher: null, viewMode: 'per-student' })}
      >
        <FilterAltOff />
      </Button>
    </Grid>
    <Grid item xs={.75}>
      <Button
        id='button-export-to-excel'
        onClick={exportToExcel}
        color='success'
        disabled={!students.length}
      >
        <Download />
      </Button>
    </Grid>
    {/* Table */}
    <Grid item xs={12} maxHeight={'72.5vh'}>
      {
        loading ?
          <PreLoader height={'50vh'} />
          :
          filters.viewMode === 'per-student' ?
            <SubmissionsTablePerStudent
              students={students.filter(filterByName).filter(filterByTeacher).filter(filterBySection)}
              panel={panel}
              setPanel={setPanel}
            />
            :
            <SubmissionsTablePerSection
              sections={groupBySection.filter(filterByTeacherGroupedPerSection)}
              setPanel={setPanel}
              updateSubmissions={updateSubmissions}
            />
      }
    </Grid>
  </>
}
